export const moneyMask = (value: string, prefix: string = 'R$') => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    var region = 'pt-BR';

    if(prefix === 'USD')
      region = 'en-US';

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat(region, options).format(
      parseFloat(value) / 100
    )
    return `${prefix} ` + result
  }

export const maskCNPJCPF = (v: string) => {
  v = v.replace(/\D/g, "")

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  } else {
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
  }

  return v
}

type Multiselect = {
  name: string,
  id: Number
};

export const statusToFilter = (t: any): Multiselect[] => {
  return [
    {
      name: t('myOrders.intervalAll'),
      id: 0
    },
    {
      name: t('myOrders.uniparAnalysis'),
      id: 1
    },
    {
      name: t('myOrders.customerPendency'),
      id: 2
    },
    {
      name: t('myOrders.orderConfirmed'),
      id: 3
    },
    {
      name: t('myOrders.arrived'),
      id: 4
    },
    {
      name: t('myOrders.entered'),
      id: 5
    },
    {
      name: t('myOrders.loaded'),
      id: 6
    },
    {
      name: t('myOrders.invoiced'),
      id: 7
    },
    {
      name: t('myOrders.refused'),
      id: 8
    },
    {
      name: t('myOrders.changeAnalysis'),
      id: 10
    },
    {
      name: t('myOrders.cancellationAnalysis'),
      id: 11
    },
  ];
}
