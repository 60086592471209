import '../globals.scss';
import '../styles/ForgotPassword.scss';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import logoPreto from '../assets/icons/logo_preto.svg';
import { Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons';
import PublicApp from '../PublicApp';
import { sendNewPass, validateForgotPassCode, validateForgotPassEmail } from '../services/userService';
import { useTranslation } from 'react-i18next';
import { uniparEncrypt } from '../helpers/encrypt';

interface PassRequisites {
    lowerCase: boolean,
    upperCase: boolean,
    minLength: boolean,
    matchConfirm: boolean,
    specialCaracter: boolean,
    number: boolean,
};

const ForgotPasssword = () => {
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [showCode, setShowCode] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');
    const [time, setTime] = useState(120);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [passRequisites, setPassRequisites] = useState<PassRequisites>({
        lowerCase: false,
        matchConfirm: false,
        minLength: false,
        specialCaracter: false,
        upperCase: false,
        number: false
    });

    const {t} = useTranslation();

    const navigate = useNavigate();

    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        if (showCode && time > 0) {
            const interval = setInterval(() => setTime(time - 1), 1000);
        
            return () => clearInterval(interval);
        }
      }, [time, showCode]);

    const doForgotPassword = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (!passRequisites.lowerCase || !passRequisites.upperCase ||
            !passRequisites.matchConfirm || !passRequisites.minLength ||
            !passRequisites.specialCaracter || !passRequisites.number) 
        {
            return;
        }

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setLoading(true);
        const password = uniparEncrypt(newPass);
        const result = await sendNewPass(email, code, password);
        setLoading(false);

        if (result.success) {
            setValidated(false);
            goToLogin();
        } else {
            setShowError(true);
        }
    }

    const resendEmail = async () => {
        setLoading(true);
        const result = await validateForgotPassEmail(email);
        setLoading(false);

        if (result.success) {
            setTime(120);
        } else {
            setShowError(true);
        }
    }

    const validateEmail = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setLoading(true);
        const result = await validateForgotPassEmail(email);
        setLoading(false);

        //if (result.success) {
            setValidated(false);
            setShowError(false);
            setShowEmail(false);
            setShowCode(true);
        //} else {
        //    setShowError(true);
        //}
    }

    const validateCode = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setLoading(true);
        const result = await validateForgotPassCode(email, code);
        setLoading(false);

        if (result.success) {
            setValidated(false);
            setShowError(false);
            setShowCode(false);
            setShowNewPass(true);
        } else {
            setShowError(true);
        }
    }

    const validatePass = (pass: string) => {
        setNewPass(pass);
        let newPassValidate = passRequisites;

        if (pass === confirmNewPass && confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: true}
        } else if (confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: false}
        }

        newPassValidate = {
            ...newPassValidate, 
            minLength: pass.length > 7,
            number: /[1-9]/.test(pass),
            upperCase: /[A-Z]/.test(pass),
            lowerCase: /[a-z]/.test(pass),
            specialCaracter: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)
        }

        if (passRequisites !== newPassValidate) {
            setPassRequisites(newPassValidate);
        }
    }

    const validateConfirmPass = (confirmPass: string) => {
        setConfirmNewPass(confirmPass);
        let newPassValidate = passRequisites;

        if (confirmPass === newPass) {
            newPassValidate = {...newPassValidate, matchConfirm: true}
        } else if (confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: false}
        }

        if (passRequisites !== newPassValidate) {
            setPassRequisites(newPassValidate);
        }
    }

    const tooltip = (
        <Tooltip>
          <strong>{t('forgotPass.tooltipResend')}</strong>
        </Tooltip>
      );

    return (
        <PublicApp>
            <div className='card-forgot'>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                    <img src={logoPreto} alt='Logo Unipar' width={130}/>
                    <p className='mt-4 text-md-center'>
                        {
                            showEmail ? t('forgotPass.msgEmail') :
                            showCode ? t('forgotPass.msgCode') :
                            showNewPass ? t('forgotPass.msgNewPass') : t('forgotPass.msgEmail')
                        }
                    </p>
                </div>

                {
                    showEmail && (
                    <Form noValidate validated={validated} onSubmit={validateEmail}>
                        {showError && <p className='p-error'>{t('forgotPass.erroEmail')}</p>}
                        <FormGroup>
                            <InputGroup hasValidation className="mb-3">
                                <FormControl 
                                    id='forgot'
                                    className='mt-4'
                                    onChange={e => setEmail(e.currentTarget.value)}
                                    type='email'
                                    placeholder='Email'
                                    value={email}
                                    required
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('forgotPass.validationEmail')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </FormGroup>
                        <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                            <Button className='btn-primary mb-3' type="submit" disabled={loading}> {loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyEmail')}</Button>
                            <p className='mb-3 p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.back')}</p>
                        </div>
                    </Form>
                    )
                }

                { showCode && (
                    <Form noValidate validated={validated} onSubmit={validateCode}>
                        {showError && <p className='p-error'>{t('forgotPass.errorCode')}</p>}
                        <FormGroup>
                            <InputGroup hasValidation className="mb-3">
                                <FormControl 
                                    className='mt-4'
                                    onChange={e => setCode(e.currentTarget.value)}
                                    type='number'
                                    placeholder={t('forgotPass.inputCode')}
                                    value={code}
                                    required
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('forgotPass.validationCode')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </FormGroup>
                        <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                            <Button className='btn-primary mb-3' type="submit">{loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyCode')}</Button>
                            <p className='p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.backLogin')}</p>

                            {
                                time === 0 ? (<p className='p-action m-2' onClick={!loading ? () => resendEmail() : () => {}}>{t('forgotPass.resendEmail')}</p>) : 
                                (<p className='m-2 timer'>
                                    <b>
                                        {t('forgotPass.notReceiveCode')}
                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                            <FontAwesomeIcon icon={faCircleQuestion} height={16} style={{marginLeft: 3}}/>
                                        </OverlayTrigger>
                                    </b>
                                    <p>
                                       {t('forgotPass.msgResend')} {time} {t('forgotPass.seconds')}
                                    </p>
                                </p>)
                            }
                            
                        </div>
                    </Form>
                )}

                { showNewPass && (
                    <Form noValidate validated={validated} onSubmit={doForgotPassword}>
                        {showError && <p className='p-error'>{t('forgotPass.errorPass')}</p>}
                        <FormGroup>
                            <InputGroup hasValidation className="mb-3 input-pass">
                                <FormControl 
                                    id='senha'
                                    className='mt-4'
                                    onChange={e => validatePass(e.currentTarget.value)}
                                    type={showPass ? 'text' : 'password'}
                                    placeholder={t('forgotPass.inputNewPass')}
                                    value={newPass}
                                    required
                                    disabled={loading}
                                />
                                <Button variant='outline'>
                                    <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} color='black' onClick={() => setShowPass(!showPass)}/>
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    {t('forgotPass.validationNewPass')}
                                </Form.Control.Feedback>
                            </InputGroup>
                            <InputGroup hasValidation className="mb-3 input-pass">
                                <FormControl 
                                    id='confirm-senha'
                                    className='mt-4'
                                    onChange={e => validateConfirmPass(e.currentTarget.value)}
                                    type={showConfirmPass ? 'text' : 'password'}
                                    placeholder={t('forgotPass.inputConfirmNewPass')}
                                    value={confirmNewPass}
                                    required
                                    disabled={loading}
                                />
                                <Button variant='outline'>
                                    <FontAwesomeIcon icon={showConfirmPass ? faEyeSlash : faEye} color='black' onClick={() => setShowConfirmPass(!showConfirmPass)}/>
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    {t('forgotPass.validationConfirmPass')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </FormGroup>
                        <div className='pass-requisites'>
                            <p>{t('forgotPass.validationMsg')}</p>
                            <p style={{color: passRequisites.minLength ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationLength')}</p>
                            <p style={{color: passRequisites.number ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationNumber')}</p>
                            <p style={{color: passRequisites.upperCase ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationUpper')}</p>
                            <p style={{color: passRequisites.lowerCase ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationLower')}</p>
                            <p style={{color: passRequisites.specialCaracter ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationSpecial')}</p>
                            <p style={{color: passRequisites.matchConfirm ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationMatch')}</p>
                        </div>
                        <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                            <Button className='btn-primary mb-3' type="submit">{loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyNewPass')}</Button>
                            <p className='mb-3 p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.backLogin')}</p>
                        </div>
                    </Form>
                )}
            </div>        
        </PublicApp>
    )
}

export default ForgotPasssword;