import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next"; import { useEffect, useState } from 'react';
import ThreeStarsPointingFingerGreen from '../../assets/icons/3-stars-pointing-finger-green.svg';
import LikeBrightGreen from '../../assets/icons/like-bright-green.svg';
import FaceAngryRed from '../../assets/icons/face-angry-red.svg';
import FaceUpsetOrange from '../../assets/icons/face-upset-orange.svg';
import FaceNeutralYellow from '../../assets/icons/face-neutral-yellow.svg';
import FaceHappyLightgreen from '../../assets/icons/face-happy-light-green.svg';
import FaceMarvelousGreen from '../../assets/icons/face-marvelous-green.svg';
import StarYellow from '../../assets/icons/star-yellow.svg';

import './UniModalCustomerSurvey.scss';
import { useUserStore } from '../../store/user';
import UniWidgetIconValue from './UniWidgetIconValue/UniWidgetIconValue';
import { set } from '../../services/customersSurveysService';

const UniModalCustomerSurvey = () => {
    const { t } = useTranslation();
    const { loginData, changeLoginData, selectedCustomer, darkMode } = useUserStore();
    const [ currentStep, setCurrentStep ]  = useState(1);
    const [currentCsatScore, setCurrentCsatScore] = useState<number>();
    const [currentNpsScore, setCurrentNpsScore] = useState<number>();
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (loginData.showCustomerSurveyLoose) {
            setCurrentNpsScore(undefined);
            setCurrentCsatScore(undefined);
            setComments('');
            setCurrentStep(3);
        }
    }, [loginData.showCustomerSurveyLoose]);

    const isCurrentStepButtonDisabled = () => {
        switch (currentStep) {
            case 1:
                return currentCsatScore === 0;
            case 2:
                return currentNpsScore === 0;
        }
    }

    const onHideLoose = async (dismiss: boolean) => {
        changeLoginData({
            ...loginData,
            showCustomerSurvey: false,
            showCustomerSurveyLoose: false
        });

        if (dismiss || comments?.trim() === '') {
            return;
        }

        const response = await set({
            userId: loginData.id,
            customerId: selectedCustomer.customerId,
            csatScore: undefined,
            npsScore: undefined,
            comments: comments,
            rejectedAnswer: false,
            looseAnswer: true
        });

        if (!response.success) {
            console.error(response.error);
        }
    }

    const onHide = async (rejectedAnswer: boolean) => {
        changeLoginData({
            ...loginData,
            showCustomerSurvey: false,
            showCustomerSurveyLoose: false
        });        

        const response = await set({
            userId: loginData.id,
            customerId: selectedCustomer.customerId,
            csatScore: rejectedAnswer ? undefined : currentCsatScore,
            npsScore: rejectedAnswer ? undefined : currentNpsScore,
            comments: rejectedAnswer ? undefined : comments,
            rejectedAnswer: rejectedAnswer,
            looseAnswer: false
        });

        if (!response.success) {
            console.error(response.error);
        }
    }

    return (
        <Modal
            show={loginData.showCustomerSurvey}
            onHide={() => {
                if (loginData.showCustomerSurveyLoose) {
                    onHideLoose(currentStep < 4 ? true : false);
                    return;
                }
                onHide(currentStep < 4 ? true : false);
            }}
            className='uni-modal-customer-survey'
            contentClassName={darkMode ? 'dark-modal-content' : ''}
        >
            <Modal.Header closeButton={true}>
                <div className='header-body'>
                    <>
                        {
                            currentStep < 4 && (
                                <img src={ThreeStarsPointingFingerGreen} loading='lazy' width={81} height={81} />
                            )
                        }
                        {
                            currentStep === 4 && (
                                <img src={LikeBrightGreen} loading='lazy' width={81} height={81} />
                            )
                        }
                    </>
                    <Modal.Title className='mt-3'>
                        {
                            <>{t('customerSurvey.step' + currentStep + 'Title')}</>
                        }                      
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <>
                    {
                        currentStep === 1 && (
                            <>
                                <p>{t('customerSurvey.step1Description')}</p>
                                <div className='rating-score'>
                                    <UniWidgetIconValue
                                        img={FaceAngryRed}
                                        showDivider={true}
                                        dividerBackgroundColor='#E23E44'
                                        value={'1'}
                                        valueLegend={t('customerSurvey.terrible')}
                                        enabled={currentCsatScore === 1}
                                        onClick={() => setCurrentCsatScore(1)}
                                    />
                                    <UniWidgetIconValue
                                        img={FaceUpsetOrange}
                                        showDivider={true}
                                        dividerBackgroundColor='#ED9A33'
                                        value={'2'}
                                        valueLegend={t('customerSurvey.bad')}
                                        enabled={currentCsatScore === 2}
                                        onClick={() => setCurrentCsatScore(2)}
                                    />
                                    <UniWidgetIconValue
                                        img={FaceNeutralYellow}
                                        showDivider={true}
                                        dividerBackgroundColor='#EEC000'
                                        value={'3'}
                                        valueLegend={t('customerSurvey.regular')}
                                        enabled={currentCsatScore === 3}
                                        onClick={() => setCurrentCsatScore(3)}
                                    />
                                    <UniWidgetIconValue
                                        img={FaceHappyLightgreen}
                                        showDivider={true}
                                        dividerBackgroundColor='#9ED042'
                                        value={'4'}
                                        valueLegend={t('customerSurvey.good')}
                                        enabled={currentCsatScore === 4}
                                        onClick={() => setCurrentCsatScore(4)}
                                    />
                                    <UniWidgetIconValue
                                        img={FaceMarvelousGreen}
                                        showDivider={true}
                                        dividerBackgroundColor='#1BC76D'
                                        value={'5'}
                                        valueLegend={t('customerSurvey.excellent')}
                                        enabled={currentCsatScore === 5}
                                        onClick={() => setCurrentCsatScore(5)}
                                    />
                                </div>
                                <div className='progress-bar'>
                                    <div className='bar' />
                                    <div className='dot' />
                                    <div className='dot' />
                                </div>
                                <div className='progress-bar-count'>
                                    1 / 3
                                </div>
                                <div className='buttons-group'>
                                    <Button variant='outline-primary' onClick={() => onHide(true)}>{t('customerSurvey.evalLater')}</Button>
                                    <Button variant='primary' onClick={() => setCurrentStep(2)} disabled={isCurrentStepButtonDisabled()}>{t('customerSurvey.eval')}</Button>
                                </div>
                            </>
                        )
                    }
                    {
                        currentStep === 2 && (
                            <>
                                <p>{t('customerSurvey.step2Description')}</p>
                                <div className='rating-score'>
                                    <UniWidgetIconValue
                                        img={StarYellow}
                                        value={'1'}
                                        valueLegend={t('customerSurvey.terrible')}
                                        enabled={currentNpsScore === 1}
                                        onClick={() => setCurrentNpsScore(1)}
                                    />
                                    <UniWidgetIconValue
                                        img={StarYellow}
                                        value={'2'}
                                        valueLegend={t('customerSurvey.bad')}
                                        enabled={currentNpsScore === 2}
                                        onClick={() => setCurrentNpsScore(2)}
                                    />
                                    <UniWidgetIconValue
                                        img={StarYellow}
                                        value={'3'}
                                        valueLegend={t('customerSurvey.regular')}
                                        enabled={currentNpsScore === 3}
                                        onClick={() => setCurrentNpsScore(3)}
                                    />
                                    <UniWidgetIconValue
                                        img={StarYellow}
                                        value={'4'}
                                        valueLegend={t('customerSurvey.good')}
                                        enabled={currentNpsScore === 4}
                                        onClick={() => setCurrentNpsScore(4)}
                                    />
                                    <UniWidgetIconValue
                                        img={StarYellow}
                                        value={'5'}
                                        valueLegend={t('customerSurvey.excellent')}
                                        enabled={currentNpsScore === 5}
                                        onClick={() => setCurrentNpsScore(5)}
                                    />
                                </div>
                                <div className='progress-bar'>
                                    <div className='dot' />
                                    <div className='bar' />
                                    <div className='dot' />
                                </div>
                                <div className='progress-bar-count'>
                                    2 / 3
                                </div>
                                <div className='buttons-group'>
                                    <Button variant='outline-primary' onClick={() => onHide(true)}>{t('customerSurvey.evalLater')}</Button>
                                    <Button variant='primary' onClick={() => setCurrentStep(3)} disabled={isCurrentStepButtonDisabled()}>{t('customerSurvey.eval')}</Button>
                                </div>
                            </>
                        )
                    }
                    {
                        currentStep === 3 && (
                            <>  
                                <p>{t('customerSurvey.step3Description')}</p>
                                <Form.Group className='comments'>
                                    <Form.Label>{t('customerSurvey.step3Comments')}</Form.Label>
                                    <Form.Control as="textarea" rows={4} maxLength={1000} value={comments} onChange={(ev) => {
                                        setComments(ev.target?.value ?? '');
                                    }} />
                                </Form.Group>                                
                                {
                                    !loginData.showCustomerSurveyLoose && (
                                        <>
                                            <div className='progress-bar'>
                                                <div className='dot' />
                                                <div className='dot' />
                                                <div className='bar' />
                                            </div>
                                            <div className='progress-bar-count'>
                                                3 / 3
                                            </div>
                                        </>
                                    )
                                }
                                <div className='buttons-group'>
                                    <Button variant='outline-primary' onClick={() => {
                                        if (loginData.showCustomerSurveyLoose) {
                                            onHideLoose(true);
                                            return;
                                        }
                                        onHide(true);
                                    }}>{t('customerSurvey.evalLater')}</Button>
                                    <Button variant='primary' onClick={() => setCurrentStep(4)}>{t('customerSurvey.eval')}</Button>
                                </div>
                            </>
                        )
                    }
                    {
                        currentStep === 4 && (
                            <>
                                <p>{t('customerSurvey.step4Description')}</p>
                                <div className='buttons-group finish'>
                                    <Button variant='outline-primary' onClick={() => {
                                        if (loginData.showCustomerSurveyLoose) {
                                            onHideLoose(false);
                                            return;
                                        }
                                        onHide(false);
                                    }}>{t('customerSurvey.close')}</Button>
                                </div>
                            </>
                        )
                    }
                </>
            </Modal.Body>
        </Modal>    
    );
}

export default UniModalCustomerSurvey;