import { Modal } from 'react-bootstrap';
import { IconDefinition, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import '../../../node_modules/react-quill/dist/quill.snow.css';

interface UniModalProps {
    show: boolean,
    icon: IconDefinition | any,
    message: string,
    title: string,
    onHide: () => void,
    iconHeight?: number,
    iconWidth?: number,
    children?: ReactNode,
    closeButton?: boolean,
    isFontAwsome?: boolean,
    iconColor?: string,
    isHtmlContent?: boolean,
}

const UniModal = (props: UniModalProps) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton={props.closeButton ?? true}>
                <div className='header-body'>
                    {
                        props.icon ?
                            props.isFontAwsome ? 
                                (<FontAwesomeIcon icon={props.icon} width={props.iconHeight ?? 70} height={props.iconWidth ?? 70} color={props.iconColor ?? '#00FF3C'} style={{height: props.iconHeight ?? 70}}/>)
                            :   (<img src={props.icon} loading='lazy' width={props.iconHeight ?? 70} height={props.iconWidth ?? 70}/>)
                        : (<></>)
                    }
                    <Modal.Title className='mt-3' style={props.isHtmlContent ? {fontSize: 32} : {}}>
                        {props.title}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body style={{overflow: 'auto', maxHeight: 450}} className={props.isHtmlContent ? `ql-editor` : ``}>
                {props.message}
                {props.children}
            </Modal.Body>
        </Modal>    
    );
}

export default UniModal;