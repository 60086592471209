import { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import './UniDatePickerRange.scss';
import { es, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import UniInput from '../UniInput/UniInput';
import { faCalendarDays, faClose } from '@fortawesome/free-solid-svg-icons';

registerLocale('pt', ptBR);
registerLocale('es', es);

type UniDatePickerRangeProps = {
    options?: DateOptions[],
    loading: boolean,
    periodValue: any,
    onChange: (dates: any) => void,
    onChangePeriod?: (option: DateOptions) => void,
    onHideChange: () => void
};

export type DateOptions = {
    value: string,
    description: string
}

const UniDatePickerRange = (props: UniDatePickerRangeProps) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const { t, i18n } = useTranslation();
    const [showUniDatePicker, setShowUniDatePicker] = useState(false);

    const onChangeDates = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (end) {
            setShowUniDatePicker(false);
            props.onChange(dates);
        }
    };

    const selectedOption = (option: DateOptions) => {
        setStartDate(new Date());
        setEndDate(null);

        if(props.onChangePeriod) {
            setShowUniDatePicker(false);
            props.onChangePeriod(option);
        }
    }

    window.addEventListener('click', function (e) {
        if (e !== null) {
            const el = e.target;
            if (el instanceof Node && !document.getElementById('datePickerRange')?.contains(el)) {
                setShowUniDatePicker(false);

            }
        }
    }); 

    return (
        <div id="datePickerRange">
          <UniInput
                id='periodId'
                iconRightBtn
                iconRightBtnType={!showUniDatePicker? faCalendarDays: faClose}
                label={t('myOrders.interval')}
                value={props.periodValue}
                disabled={props.loading}
                onClickIconRightBtn={() => {setShowUniDatePicker(!showUniDatePicker)}}
            />
            <div className='date-picker-range-wrapper' style = {showUniDatePicker ? {display:'block'}:{display:'none'}}>
                {
                    props.options && (
                        <div className='date-picker-options-wrapper'>
                            {
                                props.options?.map(option => (
                                    <div
                                        key = {option.value}
                                        className={'date-picker-option'}
                                        onClick={() => selectedOption({value: option.value, description: option.description})}
                                    >
                                        {option.description}
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <ReactDatePicker
                    selected={startDate}
                    onChange={onChangeDates}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    locale={i18n.language.toLowerCase() === 'en' ? undefined : i18n.language.toLowerCase()}
                />
            </div>
        </div>
    )
}

export default UniDatePickerRange;