import { useTranslation } from 'react-i18next';
import './styles/Login.scss';
import pt from './assets/icons/pt-br.svg';
import es from './assets/icons/es.svg';
import en from './assets/icons/en.svg';
import logoPreto from './assets/icons/logo_preto.svg';

const PublicApp = (props: any) => {
    const { i18n, t } = useTranslation();
    const changeLang = (lngCode: string) => {
        i18n.changeLanguage(lngCode);
    }

    return (
        <div className='wrapper-login'>
            <div className='banner'>
                <div>
                    <img src={logoPreto} alt='Logo Unipar' width={100}/>
                    <p className='title-login'>Portal <br/> {t('login.title')}</p>
                    <p className='subtitle-login'>{t('login.subtitle')}</p>
                </div>
            </div>
            <div className='wrapper-card'>
                {props.children}
                <div className='wrapper-lng'>
                    <div className='lng' onClick={() => changeLang('pt')}>
                        <img src={pt} alt='pt-br'/>
                        <span>PT</span>
                    </div>
                    <div className='lng' onClick={() => changeLang('es')}>
                        <img src={es} alt='es'/>
                        <span>ES</span>
                    </div>
                    <div className='lng' onClick={() => changeLang('en')}>
                        <img src={en} alt='en'/>
                        <span>EN</span>
                    </div>
                </div>
            </div>   
        </div>
    )
};

export default PublicApp;