import { useState } from 'react';

import './UniWidgetIconValue.scss';

interface UniWidgetIconValueProps {
    img: string,
    showDivider?: boolean,
    dividerBackgroundColor?: string,
    value: string,
    valueLegend: string,
    enabled: boolean,
    onClick: () => void
}

const UniWidgetIconValue = (props: UniWidgetIconValueProps) => {
    const [iconEnabled, setIconEnabled] = useState(props.enabled);
    
    return (        
        <div
            className='uni-widget-icon-value'
            onMouseEnter={() => { setIconEnabled(true) }}
            onMouseLeave={() => { setIconEnabled(false) }}
            onClick={props.onClick}
        >
            <img src={props.img} loading='lazy' width={49} height={50} style={{ opacity: iconEnabled || props.enabled ? 1 : 0.4 }} />
            {
                props.showDivider && (
                    <div className='divider' style={{ backgroundColor: props.dividerBackgroundColor, opacity: iconEnabled || props.enabled ? 1 : 0.4 }} />
                )
            }
            <span className='score'>{props.value}</span>
            <span className='legend'>{props.valueLegend}</span>
        </div>
                                        
    );
}

export default UniWidgetIconValue;