import { StatusIntent } from "../types/orderIntent";
import callApi, { BaseResponseDto, BaseResponsePagedDto } from "./baseRequest";

const routeHistory = 'order-intent';

export interface OrderIntentResponseDto {
    id: string
    paymentCondition: string
    intentNumber: number
    paymentConditionDescription: string
    customerCode: string
    incoterms: string
    materialCode: string
    materialDescription: string
    isPVC: boolean
    unitMeasurement: string
    receiverCode: string
    receiverName: string
    receiverAddress: string
    shipmentDate: string
    shipmentQuantity: number
    orderMaterial: string
    orderItem: string
    orderNumber: string
    transporterCode: string
    transporterName: any
    veichleType: string
    veichleTypeName: any
    comment: string
    shipmentId: any
    integrated: boolean
    createdAt: string
    uniparAnalisysDate: any
    pendingCustomerDate: any
    status: number
    orderIntentGroupEntityId: string
    isRecused: boolean
    recusedComment: any
    isChecked?: boolean
    customerOrderNumber: string
    orderIntentHistory: OrderIntentHistory,
    salesNumber?: string
}

export interface OrderIntentHistory {
    paymentCondition: string
    intentNumber: number
    paymentConditionDescription: string
    customerCode: string
    incoterms: string
    materialCode: string
    materialDescription: string
    isPVC: boolean
    unitMeasurement: string
    receiverCode: string
    receiverName: string
    receiverAddress: string
    shipmentDate: string
    shipmentQuantity: number
    orderMaterial: string
    orderItem: string
    orderNumber: string
    transporterCode: string
    transporterName: any
    veichleType: string
    veichleTypeName: any
    comment: string
    shipmentId: any
    integrated: boolean
    createdAt: string
    uniparAnalisysDate: any
    pendingCustomerDate: any
    status: number
    orderIntentId: string
    lastUpdatedAt: string
    updatedConfirmed: boolean
    isRecused: boolean
    recusedComment: any
    id: string
}

export interface ApprovalDto {
    id: string
    confirm: boolean
    isRecused: boolean
    veichleType: string
    veichleTypeName: string
    shipmentDate: string
    shipmentQuantity: number
    orderIntentGroupId: string
}

export function getOrderHistoryByGroupId(
    take: number,
    skip: number,
    status: string,
    idOrderGroup: string): Promise<BaseResponsePagedDto<OrderIntentResponseDto[]>> {
    const result: BaseResponsePagedDto<OrderIntentResponseDto[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',           
        data: [],
        success: false,
        currentTake: take,
        totalItems: 0,
        totalPages: 0
    };

    const response = callApi(
        'GET',
        `${routeHistory}?take=${take}&skip=${skip}&status=${status}&idGroup=${idOrderGroup}`
    )
        .then(resp => {
            if (resp?.error == null ?? false) {
                result.success = true;
                result.data = resp?.data;
                result.totalItems = resp?.totalItems;
                result.totalPages = resp?.totalPages;
            }
            else {
                result.error = resp.error;
                result.errorPT = resp.errorPT;
                result.errorEN = resp.errorEN;
                result.errorES = resp.errorES;
            }
            return result;
        })
        .catch(err => {
            result.error = err.error;
            result.errorPT = err.errorPT;
            result.errorEN = err.errorEN;
            result.errorES = err.errorES;

            return result;
        })

    return response;
};

export function putApprove(
    body: ApprovalDto[]): Promise<BaseResponseDto<any[]>> {
    const result: BaseResponseDto<any[]> = {
        error: '',
        errorPT: '',
        errorES: '',
        errorEN: '',            
        data: [],
        success: false,
    };

    const response = callApi(
        'PUT',
        `${routeHistory}/alter`, body
    )
        .then(resp => {
            if (resp?.error == null ?? false) {
                result.success = true;
                result.data = resp?.data;
            }
            else {
                result.error = resp.error;
                result.errorPT = resp.errorPT;
                result.errorEN = resp.errorEN;
                result.errorES = resp.errorES;
            }
            return result;
        })
        .catch(err => {
            result.error = err.error;
            result.errorPT = err.errorPT;
            result.errorEN = err.errorEN;
            result.errorES = err.errorES;

            return result;
        })

    return response;
};