import callApi, { BaseResponseDto } from "./baseRequest";

const route = 'notice';

export interface Notice {
    id: string,
    titlePt: string,
    titleEs: string,
    titleEn: string,
    bodyPt: string,
    bodyEs: string,
    bodyEn: string,
    author?: string,
    modifiedBy?: string,
    initialDate?: Date,
    finalDate?: Date,
    createdAt?: Date,
    modifiedAt?: Date,
    enabled: boolean,
    purchaseGroup: Number,
    country: string
};

export function getEmptyNotice(): Notice {
    return {
        id: ``,
        titlePt: ``,
        titleEs: ``,
        titleEn: ``,
        bodyPt: ``,
        bodyEs: ``,
        bodyEn: ``,
        author: ``,
        modifiedBy: ``,
        initialDate: undefined,
        finalDate: undefined,
        createdAt: undefined,
        modifiedAt: undefined,
        enabled: true,
        purchaseGroup: 0,
        country: ``
    }
}

export function getUnread(userId: string): Promise<BaseResponseDto<Notice[]>> {
    const result: BaseResponseDto<Notice[]> = {
        data: [],
        error: '',
        success: false
    };

    const response = callApi('GET', `${route}/get-unread/${userId}`)
    .then(resp => {
        if (resp) {
            result.data = resp.data;
            result.success = true;
        }
        return result;
    })
    .catch(err => {
        result.error = err;

        return result;
    })

    return response;
};

export function readNotice(noticeId: string, userId: string): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        data: {},
        error: '',
        success: false
    };

    const response = callApi('POST', `${route}/read-notice`, {NoticeId: noticeId, UserId: userId})
    .then(_ => {
        result.success = true;
        return result;
    })
    .catch(err => {
        result.error = err;
        return result;
    })

    return response;
};