import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import './UniModalUser.scss';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useUserStore } from "../../store/user";
import { CustomerResponse, UsersResponse, getCustomersByUser, getProfileToStr, getUsersByCustomer, updateUser } from "../../services/userService";

const UniModalUser = (props: any) => {
    const [selectedUser, setSelecteUser] = useState<any>({});
    const [showForm, setShowForm] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [showError, setShowError] = useState(false);
    const [users, setUsers] = useState<UsersResponse[]>();
    const [customers, setCustomers] = useState<any[]>();
    const [selectedCustomers, setSelectedCustomers] = useState<any>();
    const [selectedProfile, setSelectedProfile] = useState<any>();
    const [selectedActive, setSelectedActive] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const { darkMode, selectedCustomer } = useUserStore();

    const mockDataTable = {
        headers: [
            {
                property: 'name',
                name: 'Nome',
            },
            {
                property: 'email',
                name: 'Email',
            },
            {
                property: 'active',
                name: 'Ativo'
            },
            {
                property: 'createdAt',
                name: 'Criado EM',
                date: true
            },
            {
                property: 'profile',
                name: 'Perfil',
                enum: true
            },
            {
                property: 'actions',
                name: 'AÇÕES'
            },
        ]
    };

    const hide = () => {
        setShowForm(false);
        setReloadData(false);
        props.onHide();
    }

    useEffect(() => {
        if (props.show || reloadData) {
            setLoading(true);

            getUsersByCustomer(selectedCustomer.issuer)
                .then(data => {
                    setUsers(data.data?.users);                
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false))
        }
    }, [props.show, reloadData]);

    useEffect(() => {
        if (selectedUser && selectedUser.id) {
            setLoading(true);
            setSelectedActive(selectedUser.active);
            setSelectedProfile(selectedUser.profile);
            
            getCustomersByUser(selectedUser.id)
            .then(data => {
                    setCustomers(data.data?.customers.map(c => {return {value: c.customerId, label: `${c.issuer} ${c.customerName}`, ...c}}));                
                    setSelectedCustomers(data.data?.customers.map(c => {return {value: c.customerId, label: `${c.issuer} ${c.customerName}`}}));
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false))
        }
    }, [selectedUser]);

    const update = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const customersBody: CustomerResponse[] = selectedCustomers?.map((c: any) => {
            let customer = customers?.filter(cs => cs.customerId === c.value)?.[0];

            if (customer) {
                return {
                    customerId: customer.customerId,
                    issuer: customer.issuer,
                    customerName: customer.customerName,
                    cpfCnpj: customer.cpfCnpj,
                    country: customer.country ?? 'BR',
                    userId: selectedUser.id
                }
            }
        });

        setLoading(true);
        const response = await updateUser(selectedUser.id, Number(selectedProfile), selectedActive, customersBody);
        setLoading(false);

        if (!!response.success) {
            setShowForm(false);
            setReloadData(true);
        }
        else {
            setShowError(true);
        }
    }

    const selectProfileOptions = [
        { value: '3', label: 'Operador' },
        // { value: '2', label: 'Operador Log.' },
        { value: '1', label: 'Visualizador' }
    ]

    return (
        <Modal
            show={props.show} 
            onHide={() => hide()} 
            className={ showForm ? 'modal-user modal-form-user' : 'modal-user'} 
            contentClassName={darkMode ? 'dark-modal-content' : ''}            
        >
            <Modal.Header closeButton>
                <div className='header-body'>
                    <Modal.Title className='mt-3'>
                        {showForm ? `Editar usuário` : 'Usuários'}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    !showForm ? (
                        <table className='table-uni'>
                            <thead>
                                <tr>
                                    {
                                        mockDataTable.headers.map(header => (
                                            <td key={header.name}>
                                                {header.name}
                                            </td>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? (
                                        <tr>...</tr>
                                    ) : (
                                        users?.map((user: UsersResponse | any) => (                                            
                                            <tr key={user.name}>
                                                {
                                                    mockDataTable.headers.map(header => {
                                                        if (header.property === 'actions') {
                                                            return (
                                                                <td key={header.name} className='table-actions'>
                                                                    <div className='table-icon' onClick={() => {
                                                                            setShowForm(true);
                                                                            setShowError(false);
                                                                            setSelecteUser(user);
                                                                        }}>
                                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                                    </div>
                                                                </td>
                                                            );
                                                        }

                                                        if (typeof(user?.[header.property]) === 'boolean') {
                                                            return (<td key={header.name}>{user?.[header.property] === true ? 'SIM' : 'NÃO'}</td>);
                                                        }

                                                        if (!!header.date) {
                                                            return (<td key={header.name}>{new Date(user?.[header.property]).toLocaleDateString() || ''}</td>);
                                                        }

                                                        if (!!header.enum) {
                                                            return (<td key={header.name}>{getProfileToStr(user?.[header.property])}</td>);
                                                        }

                                                        if (typeof(user?.[header.property]) === 'string') {
                                                            return (
                                                                <td 
                                                                    key={header.name} 
                                                                    title={user?.[header.property]?.length > 12 ? 
                                                                        user?.[header.property] ?? '' : 
                                                                        ''}>
                                                                    {user?.[header.property]?.length > 14 ? user?.[header.property]?.slice(0, 13) + '...' : user?.[header.property] ?? ''}
                                                                </td>
                                                            );
                                                        }

                                                        return (
                                                            <td key={header.name}>{user?.[header.property] ?? ''}</td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    ) : (
                        <Form onSubmit={update} className='form-user'>
                            <div className='card-user'>
                                <FontAwesomeIcon icon={faUserAlt} />
                                <p>{selectedUser.name} - {selectedUser.email}</p>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-between form-user-content'>
                                <div className='profile-inputs'>
                                    {showError && (<p className='p-error'>Não foi possivel realizar a alteração</p>)}
                                    <label className='label'>Usuário ativo</label>
                                    <div>
                                        <Form.Check
                                            inline
                                            label='Sim'
                                            name='active'
                                            value='0'
                                            type='radio'
                                            defaultChecked={selectedUser.active}
                                            onChange={() => setSelectedActive(true)}
                                        />
                                        <Form.Check
                                            inline
                                            label='Não'
                                            name='active'
                                            value='1'
                                            type='radio'
                                            defaultChecked={!selectedUser.active}
                                            onChange={() => setSelectedActive(false)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between w-100'>
                                    <div className='profile-inputs'>
                                        <label className='label'>Perfil</label>
                                        <div>
                                            <Select 
                                                placeholder='Selecione'
                                                defaultValue={selectProfileOptions.filter(p => p.value === selectedUser.profile.toString())?.[0] ?? {}}
                                                onChange={(value) => setSelectedProfile(value?.value)}
                                                options={selectProfileOptions} 
                                                className='select-customers'
                                            />
                                        </div>
                                    </div>
                                    <div className='customer-input'>
                                        <label className='label'>Clientes</label>
                                        {
                                            loading ? (<Spinner style={{color: '#027948', width: 25, height: 25}}/>) : (
                                                <Select 
                                                    placeholder='Selecione'
                                                    onChange={(newValue) => setSelectedCustomers(newValue)}
                                                    defaultValue={customers}
                                                    options={customers} 
                                                    isMulti 
                                                    closeMenuOnSelect={false}
                                                    className='select-customers'
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='buttons-user-form d-flex'>
                                <Button 
                                    variant='outline'
                                    disabled={loading}
                                    onClick={() =>  {
                                        setSelecteUser({});
                                        setShowForm(false);
                                        setReloadData(true);
                                    }
                                }>
                                    Voltar
                                </Button>
                                <Button type='submit' disabled={loading}>{loading ? <Spinner style={{width: 20, height: 20}}/> : 'Salvar'}</Button>
                            </div>
                        </Form>
                    )
                }
            </Modal.Body>
        </Modal>    
    );
}

export default UniModalUser;