import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import messages from "./helpers/intl/messages";
import { useUserStore } from "./store/user";

i18n.use(initReactI18next).init({
  lng: "pt-BR",
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
  resources: messages,
});

export default i18n;