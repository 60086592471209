import { useEffect, useState } from 'react';
import './UniWidgetProgram.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faFaceFrownOpen, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useUserStore } from '../../store/user';
import { getListOrderOfProgramming, getProgramming } from '../../services/orderService';
import { OrderIntentResponseDto } from '../../services/orderHistoryService';
import { StatusIntent } from '../../types/orderIntent';
import { getDescriptionStatusIntent, getDescriptionStatusIntentLanguage, getTranslateIdStatusIntent } from '../../helpers/enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type UniWidgetProgramProps = {
    materialCode?: string,
    materialDescription?: string
};

const UniWidgetProgram = ({materialCode, materialDescription}: UniWidgetProgramProps) => {
    const [activeDate, setActiveDate] = useState(new Date());
    const [dates, setDates] = useState<string[]>();
    const [orders, setOrders] = useState<OrderIntentResponseDto[]>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const { darkMode, selectedCustomer } = useUserStore();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!materialCode) return;

        setLoading(true);
        const month = moment(activeDate).month() + 1;
        getProgramming(
            selectedCustomer.issuer, 
            materialCode ?? '', 
            month.toString() ?? '', 
            activeDate?.getFullYear()?.toString() ?? ''
        ).then(r => {
            if (r?.success ?? false) {
                setDates(r.data);

                if ((r?.data?.length > 0) ?? false)
                    onChangeDate(r.data?.[0]);
                else 
                    setOrders([]);
            }
        }).catch(err => setError(err))
        .finally(() => setLoading(false))
    }, [materialCode, activeDate])

    const mockStatusStyle = [
        {
            name: StatusIntent.UniparValidation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.CustomerPendency,
            bgColor: '#FFEFEA',
            color: '#FF6636',
        },
        {
            name: StatusIntent.OrderConfirmed,
            bgColor: '#FFE7FD',
            color: '#FF35EB',
        },
        {
            name: StatusIntent.Arrived,
            bgColor: '#FFF6C7',
            color: '#DFBC07',
        },
        {
            name: StatusIntent.EntryWeight,
            bgColor: '#F4EDFF',
            color: '#9E7BD5',
        },
        {
            name: StatusIntent.LoadAndSealed,
            bgColor: '#CFFFFC',
            color: '#0BE3D6',
        },
        {
            name: StatusIntent.Invoiced,
            bgColor: '#EEFFF2',
            color: '#30C10C',
        },
        {
            name: StatusIntent.Recused,
            bgColor: '#ffcfcf',
            color: '#e35b5b',
        },
        {
            name: StatusIntent.Canceled,
            bgColor: '#f7dcde',
            color: '#db2131',
        },
        {
            name: StatusIntent.EditSolicitation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
    ];


    const onChangeDate = (v: any) => {
        const date = moment(v).toDate();
        setLoadingOrders(true);

        getListOrderOfProgramming(
            selectedCustomer.issuer, 
            materialCode ?? '', 
            moment(date).format('yyyy-MM-DD')
        ).then(r => {
            if (r?.success ?? false) {
                setOrders(r.data);
            }
        }).catch(err => setError(err))
        .finally(() => setLoadingOrders(false))
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center navigation-wrapper'>
                <p className='d-flex align-items-end'>{t('homeWidgets.programTitle')} <span>|  </span> <p className='program-name-product'>{materialDescription ?? '-'}</p></p>
                <div className='navigation'>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={() => setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1))}/>
                        <p>{activeDate.toLocaleString(i18n.language === 'pt' ? 'pt-BR' : i18n.language === 'es' ? 'es' : i18n.language === 'en' ? 'en-ES' : 'pt-BR', { month: 'long' }) + ' ' + activeDate.getFullYear()}</p>
                    <FontAwesomeIcon icon={faAngleRight} onClick={() => setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1))}/>
                </div>
            </div>

            <div className='d-flex justify-content-between calendar-order-wrapper'>
                {
                    loading ? (
                        <Skeleton 
                            width={240}
                            height={230}
                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'} 
                            baseColor={darkMode? '#021717' : '#ebebeb'}/>
                    ) : (
                        <Calendar 
                            className='calendar-program'
                            next2Label={null}
                            prev2Label={null}
                            showNavigation={false}
                            locale={i18n.language === 'pt' ? 'pt-BR' : i18n.language === 'es' ? 'es' : i18n.language === 'en' ? 'en-ES' : 'pt-BR'}
                            activeStartDate={dates ? moment(dates?.[0] ?? activeDate).toDate() : undefined}  
                            tileClassName={({ date, view }) => {
                                if (dates?.find(x => x === moment(date).format("YYYY-MM-DD"))){
                                    return  'highlight';
                                }
                            }}              
                            tileDisabled={({ date }) => {
                                if (dates?.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                                    return false;
                                }
                                return true;
                            }}
                            defaultValue={dates ? moment(dates?.[0] ?? activeDate).toDate() : undefined}
                            onChange={(v, e) => onChangeDate(v)}
                        />
                    )
                }

                {
                    loadingOrders ? (
                        <Skeleton 
                            width={180}
                            height={230}
                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'} 
                            baseColor={darkMode? '#021717' : '#ebebeb'}/>
                    ) : orders ? (
                        <div className='card-order-wrapper'>
                            {
                                orders?.map(order => {
                                    return (
                                        <div key={order.id} className='card-order' onClick={() => navigate(`/my-orders?search=${order.salesNumber ?? order.intentNumber }`)}>
                                            <div>
                                                <div className='card-order-title'>
                                                    <p onClick={() => navigate(`/my-orders?search=${order.salesNumber ?? order.intentNumber }`)}>
                                                        Pedido
                                                        <FontAwesomeIcon icon={faArrowRight} className='item-arrow'/>
                                                    </p>
                                                    <div className='card-order-status' title={t(`homeWidgets.${getTranslateIdStatusIntent(order?.status)}`) ?? getDescriptionStatusIntentLanguage(order.status, i18n.language.toUpperCase())} style={{backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.color ?? '#fff'}}></div>
                                                </div>
                                                <p>{order.customerOrderNumber === '' ? '-' : order.customerOrderNumber}</p>
                                            </div>
                                            <div className='card-order-delivery'>
                                                <div className='table-status' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.bgColor ?? '#fff' }}>
                                                    <span className='table-status-icon' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.color ?? '#fff' }}></span>
                                                    {t(`homeWidgets.${getTranslateIdStatusIntent(order?.status)}`) ?? getDescriptionStatusIntentLanguage(order?.status, i18n.language.toUpperCase()) ?? ''}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div className='card-order-wrapper'>
                            <p>{t('homeWidgets.notfoundOrdersProgramming')}</p>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default UniWidgetProgram;