import '../globals.scss';
import '../styles/ForgotPassword.scss';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import logoPreto from '../assets/icons/logo_preto.svg';
import { Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faEyeSlash, faEye, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import PublicApp from '../PublicApp';
import { RequestType, sendNewPass, validateForgotPassCode, validateForgotPassEmail } from '../services/userService';
import { useTranslation } from 'react-i18next';
import { uniparEncrypt } from '../helpers/encrypt';
import UniModal from '../components/UniModal/UniModal';
import { faFileCircleCheck } from '@fortawesome/free-solid-svg-icons';

interface PassRequisites {
    lowerCase: boolean,
    upperCase: boolean,
    minLength: boolean,
    matchConfirm: boolean,
    specialCaracter: boolean,
    number: boolean,
};

const ForgotPasssword = () => {
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const [showCode, setShowCode] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');
    const [time, setTime] = useState(120);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [passRequisites, setPassRequisites] = useState<PassRequisites>({
        lowerCase: false,
        matchConfirm: false,
        minLength: false,
        specialCaracter: false,
        upperCase: false,
        number: false
    });

    const {t} = useTranslation();

    const navigate = useNavigate();

    const goToLogin = () => {
        navigate('/');
    }

    useEffect(() => {
        if (showCode && time > 0) {
            const interval = setInterval(() => setTime(time - 1), 1000);
        
            return () => clearInterval(interval);
        }
      }, [time, showCode]);

    const doForgotPassword = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (!passRequisites.lowerCase || !passRequisites.upperCase ||
            !passRequisites.matchConfirm || !passRequisites.minLength ||
            !passRequisites.specialCaracter || !passRequisites.number) 
        {
            return;
        }

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setLoading(true);
        const password = uniparEncrypt(newPass);
        const result = await sendNewPass(email, code, password);
        setLoading(false);

        if (result.success) {
            setValidated(false);
            setShowModal(true);
        } else {
            setShowError(true);
        }
    }

    const resendEmail = async () => {
        setLoading(true);
        const result = await validateForgotPassEmail(email, RequestType.FirstAccess);
        setLoading(false);

        if (result.success) {
            setTime(120);
        } else {
            setShowError(true);
        }
    }

    const validateEmail = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setLoading(true);
        const result = await validateForgotPassEmail(email, RequestType.FirstAccess);
        setLoading(false);

        if (result.success) {
            setValidated(false);
            setShowError(false);
            setShowEmail(false);
            setShowCode(true);
        } else {
            setShowError(true);
        }
    }

    const validateCode = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        setLoading(true);
        const result = await validateForgotPassCode(email, code, RequestType.FirstAccess);
        setLoading(false);

        if (result.success) {
            setValidated(false);
            setShowError(false);
            setShowCode(false);
            setShowNewPass(true);
        } else {
            setShowError(true);
        }
    }

    const validatePass = (pass: string) => {
        setNewPass(pass);
        let newPassValidate = passRequisites;

        if (pass === confirmNewPass && confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: true}
        } else if (confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: false}
        }

        newPassValidate = {
            ...newPassValidate, 
            minLength: pass.length > 7,
            number: /[1-9]/.test(pass),
            upperCase: /[A-Z]/.test(pass),
            lowerCase: /[a-z]/.test(pass),
            specialCaracter: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)
        }

        if (passRequisites !== newPassValidate) {
            setPassRequisites(newPassValidate);
        }
    }

    const validateConfirmPass = (confirmPass: string) => {
        setConfirmNewPass(confirmPass);
        let newPassValidate = passRequisites;

        if (confirmPass === newPass) {
            newPassValidate = {...newPassValidate, matchConfirm: true}
        } else if (confirmNewPass.length > 0) {
            newPassValidate = {...newPassValidate, matchConfirm: false}
        }

        if (passRequisites !== newPassValidate) {
            setPassRequisites(newPassValidate);
        }
    }

    const tooltip = (
        <Tooltip>
          <strong>{t('forgotPass.tooltipResend')}</strong>
        </Tooltip>
      );

    return (
        <>
            <PublicApp>
                <div className='card-forgot'>
                    <div className='d-flex justify-content-center flex-column align-items-center'>
                        <img src={logoPreto} alt='Logo Unipar' width={130}/>
                        <p className='mt-4 text-md-center'>
                            {
                                showEmail ? t('firstAccess.msgEmail') :
                                showCode ? t('forgotPass.msgCode') :
                                showNewPass ? t('forgotPass.msgNewPass') : t('forgotPass.msgEmail')
                            }
                        </p>
                    </div>

                    {
                        showEmail && (
                        <Form noValidate validated={validated} onSubmit={validateEmail}>
                            {showError && 
                            <>
                                <p className='p-error'>{t('forgotPass.erroEmail')}</p>
                                <div className='p-error' style={{textAlign:'left', fontSize:'13px', color:'#006343',width:'500px'}}>
                                    {t('forgotPass.erroEmailContato')}<br/>
                                    {t('forgotPass.erroEmailContatoPVCBrasil')}<br/>
                                    {t('forgotPass.erroEmailContatoQuimicoBrasil')}<br/>
                                    {t('forgotPass.erroEmailContatoArgentina')}
                                </div>                            
                            </>
                            }
                            <FormGroup>
                                <InputGroup hasValidation className="mb-3">
                                    <FormControl 
                                        id='forgot'
                                        className='mt-4'
                                        onChange={e => setEmail(e.currentTarget.value)}
                                        type='email'
                                        placeholder='Email'
                                        value={email}
                                        required
                                        disabled={loading}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('forgotPass.validationEmail')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <div className='terms'>
                                <Form.Check
                                    type={'checkbox'}
                                    name={`default-check`}
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {                                                                    
                                            setDisableButton(false);
                                        } else {
                                            setDisableButton(true);                                                                                                                                        
                                        }
                                    }}
                                />
                                <p>Li e aceito os <span onClick={() => setShowTerms(true)}>Termos de uso e condições</span></p>
                            </div>
                            <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                                <Button className='btn-primary mb-3' type="submit" disabled={loading || disableButton}> {loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyEmail')}</Button>
                                <p className='mb-3 p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.back')}</p>
                            </div>
                        </Form>
                        )
                    }

                    { showCode && (
                        <Form noValidate validated={validated} onSubmit={validateCode}>
                            {showError && <p className='p-error'>{t('forgotPass.errorCode')}</p>}
                            <FormGroup>
                                <InputGroup hasValidation className="mb-3">
                                    <FormControl 
                                        className='mt-4'
                                        onChange={e => setCode(e.currentTarget.value)}
                                        type='number'
                                        placeholder={t('forgotPass.inputCode')}
                                        value={code}
                                        required
                                        disabled={loading}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('forgotPass.validationCode')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                                <Button className='btn-primary mb-3' type="submit">{loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyCode')}</Button>
                                <p className='p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.backLogin')}</p>

                                {
                                    time === 0 ? (<p className='p-action m-2' onClick={!loading ? () => resendEmail() : () => {}}>{t('forgotPass.resendEmail')}</p>) : 
                                    (<p className='m-2 timer'>
                                        <b>
                                            {t('forgotPass.notReceiveCode')}
                                            <OverlayTrigger placement="top" overlay={tooltip}>
                                                <FontAwesomeIcon icon={faCircleQuestion} height={16} style={{marginLeft: 3}}/>
                                            </OverlayTrigger>
                                        </b>
                                        <p>
                                        {t('forgotPass.msgResend')} {time} {t('forgotPass.seconds')}
                                        </p>
                                    </p>)
                                }
                                
                            </div>
                        </Form>
                    )}

                    { showNewPass && (
                        <Form noValidate validated={validated} onSubmit={doForgotPassword}>
                            {showError && <p className='p-error'>{t('forgotPass.errorPass')}</p>}
                            <FormGroup>
                                <InputGroup hasValidation className="mb-3 input-pass">
                                    <FormControl 
                                        id='senha'
                                        className='mt-4'
                                        onChange={e => validatePass(e.currentTarget.value)}
                                        type={showPass ? 'text' : 'password'}
                                        placeholder={t('forgotPass.inputNewPass')}
                                        value={newPass}
                                        required
                                        disabled={loading}
                                    />
                                    <Button variant='outline'>
                                        <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} color='black' onClick={() => setShowPass(!showPass)}/>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                        {t('forgotPass.validationNewPass')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                                <InputGroup hasValidation className="mb-3 input-pass">
                                    <FormControl 
                                        id='confirm-senha'
                                        className='mt-4'
                                        onChange={e => validateConfirmPass(e.currentTarget.value)}
                                        type={showConfirmPass ? 'text' : 'password'}
                                        placeholder={t('forgotPass.inputConfirmNewPass')}
                                        value={confirmNewPass}
                                        required
                                        disabled={loading}
                                    />
                                    <Button variant='outline'>
                                        <FontAwesomeIcon icon={showConfirmPass ? faEyeSlash : faEye} color='black' onClick={() => setShowConfirmPass(!showConfirmPass)}/>
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                        {t('forgotPass.validationConfirmPass')}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <div className='pass-requisites'>
                                <p>{t('forgotPass.validationMsg')}</p>
                                <p style={{color: passRequisites.minLength ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationLength')}</p>
                                <p style={{color: passRequisites.number ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationNumber')}</p>
                                <p style={{color: passRequisites.upperCase ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationUpper')}</p>
                                <p style={{color: passRequisites.lowerCase ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationLower')}</p>
                                <p style={{color: passRequisites.specialCaracter ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationSpecial')}</p>
                                <p style={{color: passRequisites.matchConfirm ? '#00BE82' : '#EA2027'}}>{t('forgotPass.validationMatch')}</p>
                            </div>
                            <div className='buttons mt-5 w-100 d-flex align-items-center flex-column'>
                                <Button className='btn-primary mb-3' type="submit">{loading ?  <Spinner animation='border'></Spinner> : t('forgotPass.verifyNewPass')}</Button>
                                <p className='mb-3 p-action' onClick={!loading ? () => goToLogin() : () => {}}>{t('forgotPass.backLogin')}</p>
                            </div>
                        </Form>
                    )}
                </div>        
            </PublicApp>
            <UniModal 
                title={t('firstAccess.modalTitle')}
                message={t('firstAccess.modalBody')}
                show={showModal} 
                icon={faCheckCircle}
                closeButton={false} 
                onHide={() => {}}
            >
                <div>
                    <Button onClick={() => goToLogin()} style={{marginTop: 25, marginBottom: -30}}>{t('firstAccess.modalBtn')}</Button>
                </div>
            </UniModal>

            <UniModal 
                title={'Termos e Condições de Uso do Portal de Clientes Unipar'}
                message=''
                show={showTerms} 
                icon={faFileCircleCheck}
                isFontAwsome
                iconColor='#0A3200'
                closeButton={true} 
                onHide={() => setShowTerms(false)}
            >
                <div>
                    <p>
                        A UNIPAR CARBOCLORO S.A. e a UNIPAR INDUPA DO BRASIL S.A., aqui designadas como Unipar, disponibiliza uma plataforma online através do site “https://portalcliente.unipar.com” (denominado “Portal de Clientes Unipar”) que se destina a prover conteúdo informativo das demandas comerciais aos clientes da Unipar (“Clientes”). No Portal de Clientes Unipar, o Cliente poderá realizar a gestão de seus pedidos, visualizar a sua situação financeira com relação à Unipar, acessar a versão digital de Nota Fiscal eletrônica (NF-e) via arquivo XML, solicitar a reemissão de DANFE (Documento Auxiliar da NF-e), reemitir boletos, e emitir Certificado de Qualidade.<br />
                        <br />
                        O acesso ao Portal de Clientes Unipar pelo Cliente está sujeito às regras a seguir (“Regras”), bem como à legislação aplicável. Ao acessar e utilizar o Portal de Clientes Unipar, o Cliente aceita e concorda com as Regras, sem limitações ou restrições.<br />
                        <br />
                        A Unipar pode atualizar as Regras a qualquer momento, para refletir alterações relativas a obrigações legais ou atualizações à medida que a plataforma evolui. O Cliente, pois, é obrigado a cumprir com o determinado a partir dessas revisões.<br />
                        <br />
                        Do Acesso e Uso da Plataforma<br />
                        Para acessar os conteúdos disponibilizados no Portal de Clientes Unipar, os funcionários do Cliente que irão acessar a plataforma deverão criar login e senha de acesso próprios, de caráter pessoal e intransferível, os quais deverão ser mantidos em sigilo para proteção da conta. A conta do usuário estará vinculada ao e-mail corporativo do respectivo funcionário e ao Código SAP Unipar, de forma que, caso o e-mail seja desabilitado por conta de desligamento do funcionário, o acesso ao Portal de Clientes Unipar através deste e-mail será impedido por meio de apresentação de mensagem de “Dados de Acesso Inválidos” ou outra semelhante.<br />
                        <br />
                        O Cliente deverá notificar a Unipar imediatamente sobre qualquer violação de segurança ou uso não autorizado de sua conta. É imprescindível que o Cliente não faça uso indevido de nossa plataforma, por exemplo, ao tentar acessá-la por meios diversos da interface e das instruções orientadas pela Unipar.<br />
                        <br />
                        A Unipar não se responsabiliza pelas perdas e danos causados pelo uso não autorizado da conta do Cliente; todavia, o Cliente poderá ser responsabilizado por eventuais danos causados à Unipar nos casos acima descritos, bem como devido ao mau uso do Portal de Clientes Unipar.<br />
                        <br />
                        Renúncia<br />
                        O Cliente concorda que o uso da plataforma será por sua conta e risco exclusivos. A Unipar não assume qualquer obrigação ou responsabilidade por quaisquer: (i) erros de qualquer natureza resultantes do acesso do Cliente e de seu uso da plataforma; (ii) qualquer interrupção ou cessação de transmissão da plataforma; (iii) quaisquer vírus ou afins, que possam ser transmitidos para ou através da plataforma por quaisquer terceiros.<br />
                        <br />
                        Responsabilidade<br />
                        O Portal de Clientes Unipar tem por objetivo criar uma interface de proximidade com o Cliente através da ferramenta online. A Unipar se dedica a assegurar que as informações fornecidas através da ferramenta sejam precisas e atualizadas, todavia não garante que o conteúdo em exibição ao Cliente corresponde ao estado em que o mesmo se encontra, em razão de atrasos na atualização momentânea das informações na ferramenta, pois o Portal de Clientes Unipar é um website interativo e dinâmico.<br />
                        <br />
                        Assim, o Cliente reconhece que: (i) é impossível disponibilizar a plataforma livre de qualquer defeito, e que a Unipar não pode se comprometer a fazê-lo; (ii) a operação da ferramenta pode vir a ser afetada por eventos e/ou questões fora do alcance da Unipar, razão que levaria à indisponibilidade temporária da plataforma; (iii) a Unipar não se responsabiliza por qualquer suspensão ou interrupção da ferramenta.<br />
                        <br />
                        Ainda, a Unipar não se responsabiliza por qualquer dano resultante de invasão fraudulenta por terceiros, resultando em alteração da informação ou itens disponibilizados na plataforma e, de forma mais ampla, por qualquer dano de propriedade ou consequente perda, por qualquer razão, de qualquer origem, natureza ou com qualquer consequência, mesmo se a Unipar tiver sido alertada da possibilidade de tais danos ou perdas causados (i) por qualquer acesso à plataforma ou pela impossibilidade de acessá-la; (ii) pelo uso da plataforma, incluindo qualquer dano ou vírus que possa infectar seu computador ou qualquer outra propriedade; e/ou (iii) pela confiança depositada às informações obtidas direta ou indiretamente da ferramenta.<br />
                        <br />
                        Do Uso do Conteúdo e da Propriedade Intelectual<br />
                        As Regras não conferem ao Cliente o uso de nomes de domínio, marcas ou logotipos, registrados ou não, utilizados na plataforma; os mesmos são de exclusiva propriedade da Unipar e estão respaldados pelas normas de proteção à propriedade intelectual, notadamente a lei de direitos autorais (Lei n° 9.610/98) e a lei de propriedade industrial (Lei n° 9.279/96).<br />
                        <br />
                        Da mesma forma, o conteúdo disponibilizado no Portal de Clientes Unipar, que inclui, mas não se limita a, informações, imagens, especificações técnicas e tributárias dos produtos da Unipar, inovações e lançamentos, não poderão ser utilizadas pelo Cliente para fins diversos do que se limita a essas Regras. Sendo assim, o Cliente não poderá copiar, reproduzir, alterar, editar, descaracterizar, distribuir, transmitir, exibir ou explorar qualquer conteúdo para quaisquer outros fins.<br />
                        <br />
                        Compromisso com a confidencialidade dos dados – Privacidade<br />
                        Somente funcionários autorizados da Unipar terão acesso aos dados dos Clientes. Todos os envolvidos com a administração da plataforma, bem como as empresas contratadas para serviços técnicos de manutenção e atualização, são obrigados a acatar os termos previstos neste item. O funcionário ou empresa que violar estes Termos está sujeito a ação disciplinar, que pode levar até mesmo à demissão ou ao cancelamento de contrato de prestação de serviços.<br />
                        <br />
                        Nenhuma informação fornecida pelo Cliente será repassada, fornecida, vendida, alugada ou distribuída a terceiros, salvo nos casos de determinações legais, judiciais e de auditoria.<br />
                        <br />
                        Sobre o uso de cookies<br />
                        O Portal de Clientes Unipar utiliza também uma tecnologia conhecida como cookies. Apesar do uso deste recurso ser comum à grande maioria dos sites disponíveis na Internet, existem brechas de segurança que permitem, eventualmente, que pessoas mal-intencionadas utilizem essa tecnologia de forma ilegal e venham a prejudicar a privacidade ou a integridade dos sistemas dos usuários.<br />
                        <br />
                        A Unipar possui uma política ética e transparente quanto ao uso de cookies. Leia atentamente as explicações abaixo, principalmente caso não esteja familiarizado com as características dessa tecnologia.<br />
                        <br />
                        Os cookies são pequenos arquivos de informação gravados no disco rígido de seu computador quando você visita um site que usa essa tecnologia. Eles não executam comandos, não transportam vírus nem leem informações pessoais de seu computador. Em geral, os cookies armazenam códigos e preferências que identificam você perante o site visitado e apenas por ele podem ser lidos. O que diferencia os cookies “normais” dos "maliciosos" é o uso das informações gravadas, principalmente quando associadas a outras informações que você fornece voluntariamente ao site visitado.<br />
                        <br />
                        Neste site, o cookie gravado em seu computador armazena um único número que identifica você perante nosso servidor e que nos informa se você está acessando alguma informação ou página deste site. Uma das vantagens práticas do uso de cookies é permitir que você não tenha que digitar sua senha de acesso mais de uma vez em uma mesma visita.<br />
                        <br />
                        Disposições Legais<br />
                        As Regras são regidas e interpretadas de acordo com as leis brasileiras. O Cliente se compromete a utilizar o Portal de Clientes Unipar em conformidade com os dispositivos legais e regulamentos aplicáveis, sejam regionais, nacionais ou internacionais. Qualquer reclamação ou controvérsia será dirimida exclusivamente pelo Foro da Comarca de São Paulo.<br />
                        <br />
                        O Cliente declara que leu e aceitou os Termos e Condições de Uso do Portal de Clientes Unipar.<br />
                        
                    </p>
                </div>
            </UniModal>
        </>
    )
}

export default ForgotPasssword;