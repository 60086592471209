import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/ApproveChanges.scss';
import { faFileCircleCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionButton, Button, Form, Spinner } from 'react-bootstrap';
import tuckLocation from '../assets/icons/truck-location.svg';
import orders from '../assets/icons/orders.svg';
import { useUserStore } from '../store/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { ApprovalDto, OrderIntentResponseDto, getOrderHistoryByGroupId, putApprove } from '../services/orderHistoryService';
import Switch from '../components/UniSwitch/UniSwitch';
import UniModal from '../components/UniModal/UniModal';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';

const ApproveChanges = () => {
    const [orderHistory, setOrderHistory] = useState<OrderIntentResponseDto[]>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPut, setLoadingPut] = useState<boolean>(false);
    const [take, setTake] = useState<any>(10);
    const [page, setPage] = useState<number>(0);
    const [showModalSucces, setShowModalSucces] = useState<boolean>(false);
    const [msgErrorIntent, setErrorIntent] = useState<string>('');
    const {darkMode } = useUserStore();
    const navigate = useNavigate();
    let { idOrder } = useParams();
    const [valueSwitch, setValueSwitch] = useState<boolean>(false);
    const size = useWindowSize();
    const { t, i18n } = useTranslation();    

    useEffect(() => {
        if (localStorage.getItem('tkn')) {
            getOrderHistory();
        }
    }, []);

    const getOrderHistory = () => {
        setLoading(true);
        setError('');
        getOrderHistoryByGroupId(take, page, '2', idOrder!)
            .then(response => {
                if (!response.success) {

                    var error = response.error ?? '';
            
                    if(i18n.language.toUpperCase() === 'PT')
                        error = response.errorPT ?? '';
                    if(i18n.language.toUpperCase() === 'EN')
                        error = response.errorEN ?? '';
                    if(i18n.language.toUpperCase() === 'ES')
                        error = response.errorES ?? '';                    

                    setError(error);
                } else {
                    response.data.map(m => m.isChecked = false);
                    setOrderHistory(response.data);
                }
            }).catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    const onToglle = (idx: any) => {
        let newHistory = [...orderHistory!];
        if (idx === 'all') {
            setValueSwitch(!valueSwitch);
            newHistory.map(m => m.isChecked = !valueSwitch);
        } else {
            newHistory![idx].isChecked = !newHistory![idx].isChecked;
        }

        setOrderHistory(newHistory)
    }

    const onApproval = () => {
        const approvalList: ApprovalDto[] = [];
        orderHistory?.map(m => {
            const pro: ApprovalDto = {
                id: m.id,
                confirm: m.isChecked!,
                isRecused: !m.isChecked!,
                veichleType: m.orderIntentHistory.veichleType,
                veichleTypeName: m.orderIntentHistory.veichleTypeName,
                shipmentDate: m.orderIntentHistory.shipmentDate,
                shipmentQuantity: m.orderIntentHistory.shipmentQuantity,
                orderIntentGroupId: m.orderIntentGroupEntityId,
            };
            approvalList?.push(pro)
        });

        setLoadingPut(true);
        putApprove(approvalList).then(response => {
            if (!response.success) {
                setErrorIntent(t('aproveChangesScreen.labelTryAgain'));
            } else {
                setShowModalSucces(true);
            }
        }).catch(err => {
            setErrorIntent(t('aproveChangesScreen.labelTryAgain'));
        })
        .finally(() => setLoadingPut(false));
    }

    const mockDataTable = {
        headers: [
            {
                property: 'shipmentDate',
                name: t('aproveChangesScreen.tdShipmentDate'),
                isDate: true
            },
            {
                property: 'shipmentQuantity',
                name: t('aproveChangesScreen.tdShipmentQuantity'),
                isNumber: true
            },
            {
                property: 'veichleTypeName',
                name: t('aproveChangesScreen.tdVeichleTypeName'),
                isVehicle: true
            },
            {
                property: 'transporterName',
                name: t('aproveChangesScreen.tdTransporterName'),
                isTransporter: true
            },
            {
                property: 'orderNumber',
                name: t('aproveChangesScreen.tdOrderNumber'),
                isOrderNumber: true
            },
            {
                property: 'materialCode',
                name: t('aproveChangesScreen.tdMaterialCode'),
                isMaterialCode: true
            },
            {
                property: 'materialDescription',
                name: t('aproveChangesScreen.tdMateriaDescription'),
                isOrderItem: true
            },
            {
                property: 'isChecked',
                name: t('aproveChangesScreen.tdSelect'),
                isCheck: true
            },
            {
                property: 'actions',
                name: '',
            },
        ]
    };

    return (
        <div className='my-history-wrapper'>
            <div className='my-history-header w-100'>
                <div className='my-history-header-i'>
                    <img src={orders} alt='icone de novo pedido' />
                    <p>{t('aproveChangesScreen.labelAproveOrder')}</p>
                </div>
            </div>
            <div className='w-100 d-flex justify-content-center order-body'>
                <div className='table-uni'>
                    <div className='header-item'>
                        <div className='item-body'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex w-100'>
                                    <div className='d-flex flex-column w-100'>
                                        <div className='item-body-labels'>
                                            {
                                                loading ? (
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={400}
                                                        duration={1}
                                                        highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                                        baseColor={darkMode ? '#021717' : '#ebebeb'} />
                                                ) : (
                                                    <>
                                                        <div className='item-body-labels-wrapper'>
                                                            <label>{t('aproveChangesScreen.labelOrderNumber')}</label>
                                                            <p>{orderHistory![0]?.['orderNumber'] ? Number(orderHistory![0]?.['orderNumber']) : '-'}</p>
                                                        </div>
                                                        <div className='item-body-labels-wrapper'>
                                                            <label>{t('aproveChangesScreen.labelMaterialCode')}</label>
                                                            <p>{orderHistory![0]?.['materialCode'] ? Number(orderHistory![0]?.['materialCode']) : '-'}</p>
                                                        </div>
                                                        <div className='item-body-labels-wrapper'>
                                                            <label>{t('aproveChangesScreen.labelPaymentConditions')}</label>
                                                            <p>{orderHistory![0]?.['paymentConditionDescription'] ?? '-'}</p>
                                                        </div>
                                                        <div className='item-body-labels-wrapper'>
                                                            <label>{t('aproveChangesScreen.labelMaterialDescription')}</label>
                                                            <p>{orderHistory![0]?.['materialDescription'] ?? '-'}</p>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center w-80 p-2 mt-2">
                        <div>
                            <p className='p-title-body'>{t('aproveChangesScreen.labelPendenciesAprove')}</p>
                        </div>
                        <div className="label-all-switch">
                            <label>{t('aproveChangesScreen.labelAllAprove')}</label>
                            <Switch
                                isOn={valueSwitch}
                                onColor="#BFE8C8"
                                handleToggle={() => onToglle('all')}
                                keyName={'all'}
                            />
                        </div>
                    </div>
                    {
                        loading ? (
                            <Skeleton
                                width={'100%'}
                                height={400}
                                duration={1}
                                highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                baseColor={darkMode ? '#021717' : '#ebebeb'} />
                        ) :
                            (
                                error ? (<p className='w-100 text-center p-2'>{t('aproveChangesScreen.labelNotFound')}</p>) :
                                    <>
                                        {
                                            orderHistory?.map((order: any, idx) => (
                                                size?.width && size?.width < 700 ? (
                                                    <Accordion as={'div'} defaultActiveKey={idx.toString()}>
                                                        <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                                                            <div className='item-header flex-column'>
                                                                <div className='d-flex w-100 flex-wrap' style={{marginLeft: 0}}>
                                                                    <div style={{marginLeft: 0}}>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <p style={{ color: '#027948', fontWeight:'bold' }}>{order?.['intentNumber'] ? Number(order?.['intentNumber']) : '-'}</p>
                                                                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faFileCircleCheck} color='#027948' size='xl' />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        mockDataTable.headers.map((header) => {
                                                                            if (header.isDate) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelDelivery')}</label>
                                                                                            <p>{order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isNumber) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelQuantity')}</label>
                                                                                            <p>{order?.[header.property] + ' t' ?? '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isVehicle) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelVehicle')}</label>
                                                                                            <p>{order?.[header.property] ?? '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isTransporter) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelTransporter')}</label>
                                                                                            <p>{order?.[header.property] ?? '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </div>

                                                                <div className='d-flex justify-content-evenly align-items-center w-100'>
                                                                    {
                                                                        mockDataTable.headers.map((header) => {
                                                                            if (header.isOrderNumber) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelOrderNumber')}</label>
                                                                                            <p>{order?.[header.property] ? Number(order?.[header.property]) : '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isMaterialCode) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelMaterialCode')}</label>
                                                                                            <p>{order?.[header.property] ? Number(order?.[header.property]) : '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isOrderItem) {
                                                                                return (
                                                                                    <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                        <>
                                                                                            <label>{t('aproveChangesScreen.labelOrderItem')}</label>
                                                                                            <p>{order?.[header.property] ?? '-'}</p>
                                                                                        </>
                                                                                    }</div>
                                                                                )
                                                                            }

                                                                            if (header.isCheck) {
                                                                                return (
                                                                                    <Switch
                                                                                        isOn={order?.[header.property]}
                                                                                        onColor="#BFE8C8"
                                                                                        keyName={idx}
                                                                                        key={header.name}
                                                                                        handleToggle={() => onToglle(idx)}
                                                                                    />
                                                                                );
                                                                            }

                                                                            if (header.property === 'actions') {
                                                                                return (<AccordionButton />);
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Accordion.Body as={`div`} style={{padding: 10}}>
                                                                <div className='item-body'>
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='d-flex w-100'>
                                                                            <div className='d-flex flex-column w-100'>
                                                                                <div className='item-body-labels w-100'>
                                                                                    <div className='justify-content-center'>
                                                                                        <p className='item-body-labels-p'>Backoffice</p>
                                                                                        <div className='item-body-labels-wrapper'>
                                                                                            <label>{t('aproveChangesScreen.labelModify')}</label>
                                                                                            <p>{order?.orderIntentHistory?.['lastUpdatedAt'] ? moment(order?.orderIntentHistory?.['lastUpdatedAt']).format('DD/MM/yyyy') : '-'}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            moment(order?.orderIntentHistory?.['shipmentDate']).format('DD/MM/yyyy') === moment(order?.['shipmentDate']).format('DD/MM/yyyy')
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelDelivery')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['shipmentDate'] ? moment(order?.orderIntentHistory?.['shipmentDate']).format('DD/MM/yyyy') : '-'}</p>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            Number(order?.orderIntentHistory?.['shipmentQuantity']) === Number(order?.['shipmentQuantity'])
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelQuantity')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['shipmentQuantity'] ? Number(order?.orderIntentHistory?.['shipmentQuantity']) : '-'}</p>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            order?.orderIntentHistory?.['veichleTypeName']?.trim() === order?.['veichleTypeName']?.trim()
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelVehicle')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['veichleTypeName'] ?? order?.orderIntentHistory?.['veichleType'] ?? '-'}</p>
                                                                                    </div>
                                                                                    <div className='item-body-labels-wrapper'>
                                                                                        <label>{t('aproveChangesScreen.labelObs')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['recusedComment'] ?? '-'}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                ) : (
                                                    <Accordion as={'div'} defaultActiveKey={idx.toString()}>
                                                        <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                                                            <div className='item-header'>
                                                                <div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <p style={{ color: '#027948', fontWeight:'bold' }}>{order?.['intentNumber'] ? Number(order?.['intentNumber']) : '-'}</p>
                                                                        <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faFileCircleCheck} color='#027948' size='xl' />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    mockDataTable.headers.map((header) => {
                                                                        if (header.isDate) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelDelivery')}</label>
                                                                                        <p>{order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isNumber) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelQuantity')}</label>
                                                                                        <p>{order?.[header.property] + ' t' ?? '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isVehicle) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelVehicle')}</label>
                                                                                        <p>{order?.[header.property] ?? '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isTransporter) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelTransporter')}</label>
                                                                                        <p>{order?.[header.property] ?? '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isOrderNumber) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelOrderNumber')}</label>
                                                                                        <p>{order?.[header.property] ? Number(order?.[header.property]) : '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isMaterialCode) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelMaterialCode')}</label>
                                                                                        <p>{order?.[header.property] ? Number(order?.[header.property]) : '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isOrderItem) {
                                                                            return (
                                                                                <div key={header.name} className='item-body-labels-wrapper'>{
                                                                                    <>
                                                                                        <label>{t('aproveChangesScreen.labelOrderItem')}</label>
                                                                                        <p>{order?.[header.property] ?? '-'}</p>
                                                                                    </>
                                                                                }</div>
                                                                            )
                                                                        }

                                                                        if (header.isCheck) {
                                                                            return (
                                                                                <Switch
                                                                                    isOn={order?.[header.property]}
                                                                                    onColor="#BFE8C8"
                                                                                    keyName={idx}
                                                                                    key={header.name}
                                                                                    handleToggle={() => onToglle(idx)}
                                                                                />
                                                                            );
                                                                        }

                                                                        if (header.property === 'actions') {
                                                                            return (<AccordionButton />);
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                            <Accordion.Body as={`div`}>
                                                                <div className='item-body'>
                                                                    <div className='d-flex flex-column'>
                                                                        <div className='d-flex w-100'>
                                                                            <div className='d-flex flex-column w-100'>
                                                                                <div className='item-body-labels'>
                                                                                    <div className='justify-content-center'>
                                                                                        <p className='item-body-labels-p'>Backoffice</p>
                                                                                        <div className='item-body-labels-wrapper'>
                                                                                            <label>{t('aproveChangesScreen.labelModify')}</label>
                                                                                            <p>{order?.orderIntentHistory?.['lastUpdatedAt'] ? moment(order?.orderIntentHistory?.['lastUpdatedAt']).format('DD/MM/yyyy') : '-'}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            moment(order?.orderIntentHistory?.['shipmentDate']).format('DD/MM/yyyy') === moment(order?.['shipmentDate']).format('DD/MM/yyyy')
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelDelivery')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['shipmentDate'] ? moment(order?.orderIntentHistory?.['shipmentDate']).format('DD/MM/yyyy') : '-'}</p>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            Number(order?.orderIntentHistory?.['shipmentQuantity']) === Number(order?.['shipmentQuantity'])
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelQuantity')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['shipmentQuantity'] ? Number(order?.orderIntentHistory?.['shipmentQuantity']) : '-'}</p>
                                                                                    </div>
                                                                                    <div 
                                                                                        className={
                                                                                            order?.orderIntentHistory?.['veichleTypeName']?.trim() === order?.['veichleTypeName']?.trim()
                                                                                            ? 'item-body-labels-wrapper'
                                                                                            : 'item-body-labels-wrapper-red'
                                                                                        }
                                                                                    >
                                                                                        <label>{t('aproveChangesScreen.labelVehicle')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['veichleTypeName'] ?? order?.orderIntentHistory?.['veichleType'] ?? '-'}</p>
                                                                                    </div>
                                                                                    <div className='item-body-labels-wrapper'>
                                                                                        <label>{t('aproveChangesScreen.labelObs')}</label>
                                                                                        <p>{order?.orderIntentHistory?.['recusedComment'] ?? '-'}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                )
                                            ))
                                        }
                                    </>
                            )
                    }

                    {
                        <>
                            <div className='bottom-btn'>
                                <Button disabled={loadingPut} variant='outline-primary' href='/my-orders'>{t('aproveChangesScreen.labelBack')}</Button>
                                <Button disabled={loadingPut} onClick={() => onApproval()}>{loadingPut ? <Spinner /> : t('aproveChangesScreen.labelSave')}</Button>
                            </div>
                        </>
                    }
                </div>
            </div>
            <UniModal
                icon={tuckLocation}
                title={t('aproveChangesScreen.labelMsgSave')}
                message=''
                show={showModalSucces}
                onHide={() => {
                    setShowModalSucces(false);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={100}
            >
                <div className='modal-body-resume'>
                    <p style={{ margin: 0 }}>{t('aproveChangesScreen.labelComingSoon')}</p>
                    <p>{t('aproveChangesScreen.labelAccessArea')} <span>{t('aproveChangesScreen.labelMyOrders')}</span></p>
                    <Button onClick={() => navigate('/my-orders')}>{t('aproveChangesScreen.labelGotoMyOrders')}</Button>
                </div>
            </UniModal>
            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('aproveChangesScreen.labelErrorSave')}
                message=''
                iconColor='#EA2027'
                show={msgErrorIntent !== ''}
                onHide={() => {
                    setErrorIntent('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorIntent}</p>
                    <Button onClick={() => setErrorIntent('')}>{t('aproveChangesScreen.labelOK')}</Button>
                </div>
            </UniModal>
        </div>
    );
}

export default ApproveChanges;