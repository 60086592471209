import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UniInput from "../UniInput/UniInput";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './UniPagination.scss';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type UniPaginationProps = {
    totalPages?: number,
    totalItems?: number,
    changeTake: (take: any) => void,
    changePage: (page: number) => void 
}

const UniPagination = ({totalItems, totalPages, changeTake, changePage}: UniPaginationProps) => {

    const [take, setTake] = useState('10');
    const [page, setPage] = useState(1);
    const [mockPages, setMockPages] = useState([1]);
    const [showPages, setShowPages] = useState([1]);
    const { t } = useTranslation();
    
    useEffect(() => {
        let allPages = [];
        if (totalPages) {
            for (let i = 1; i <= totalPages; i++) {
                allPages.push(i);
            }
            setMockPages(allPages);
        }
    }, [totalPages])

    useEffect(() => {
        let showPages = [];
        if (mockPages.length > 5) {
            let idx = mockPages.indexOf(page);
            showPages = mockPages.slice(idx, (idx + 5) > mockPages.length ? mockPages.length : idx + 5);
        } else {
            showPages = mockPages;
        }

        setShowPages(showPages);
    }, [page, mockPages])

    return (
        <div className='pagination w-100'>
            
            <p>{(Number(take) * (page-1)) + (page-(page-1))}-{Number(take) <= (totalItems ?? 0) ? ((Number(take) * page) > totalItems! ? totalItems : (Number(take) * page))  : totalItems} de {totalItems}</p>
            
            <div className='take d-flex'>
                <p>{t('pagination.itensPerPage')}</p>
                <UniInput 
                    id='pages'
                    isSelect
                    defaultValue={'10'}
                    onChange={(e) => {
                        setTake(e.target?.value);
                        setPage(1);
                        changeTake(e.target?.value);
                    }}
                >
                    <option value={'10'}>10</option>
                    <option value={'20'}>20</option>
                    <option value={'30'}>30</option>
                </UniInput>
            </div>
            <div className='d-flex pages'>
                {
                    page !== 1 &&
                    <FontAwesomeIcon icon={faChevronLeft} onClick={() => {
                        if (page == 1) return;
    
                        setPage(page - 1);
                        changePage(page - 1);       
                    }}/>

                }
                {
                    showPages?.map(pg => <p key={pg} onClick={() => {setPage(pg); changePage(pg);}} className={pg === page ? 'active' : ``}>{pg}</p>)
                }
                {
                    page !== totalPages &&
                    <FontAwesomeIcon icon={faChevronRight} onClick={() => {
                        if (page == totalPages) return;

                        setPage(page + 1);
                        changePage(page + 1);
                    }}/>
                }
            </div>
        </div>
    );
}

export default UniPagination;