import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/Financial.scss';
import { faArrowsRotate, faBarcode, faCode, faDownLeftAndUpRightToCenter, faDownload, faEllipsis, faEllipsisVertical, faFileDownload, faFileInvoice, faFileInvoiceDollar, faReceipt, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import { AccordionButton, Button, Dropdown, DropdownButton, Form, Spinner } from 'react-bootstrap';
import financial from '../assets/icons/financial-2.svg';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import UniPagination from '../components/UniPagination/UniPagination';
import { ResponseOrderIntentShipment, getOrdersIntentsShipments, getFileOrders } from '../services/orderService';
import { useUserStore } from '../store/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getDescriptionStatusFinancial, getDescriptionStatusIntent } from '../helpers/enums';
import { StatusIntent } from "../types/orderIntent";
import { TabMaterial, getMaterialsByIssuerBought } from '../services/materialService';
import { faXmarkCircle, faCalendarDays, faCalendarXmark, faFile, faDotCircle, faFileCode } from '@fortawesome/free-regular-svg-icons';
import UniModal from '../components/UniModal/UniModal';
import UniBankTicket from '../components/UniBankTicket/UniBankTicket';
import certify from '../assets/icons/certify.svg';
import DatePicker from 'react-date-picker';
import { Extract, getCertify, getCertifyAR, getDanfe, getFileFinancial, getFinancialData, getRemito, getShipments, getXML } from '../services/financialService';
import moment from 'moment';
import { moneyMask } from '../helpers/utils';
import { useTranslation } from 'react-i18next';
import UniDatePickerRange from '../components/UniDatePickerRange/UniDatePickerRange';

const Financial = () => {
    const [enableCancel, setEnableCancel] = useState<boolean>(false);
    const [enableExport, setEnableExport] = useState<boolean>(false);
    const [loadingExport, setLoadingExport] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [ordersShipments, setOrders] = useState<ResponseOrderIntentShipment[]>();
    const [search, setSearch] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [period, setPeriod] = useState<string>('');
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>();
    const [totalItems, setTotalItems] = useState<number>();
    const [take, setTake] = useState<any>(10);
    const [page, setPage] = useState<number>(0);
    const [allCheck, setAllcheck] = useState<boolean>(false);
    const [showRebuyError, setShowRebuyError] = useState<boolean>(false);
    const [materials, setMaterials] = useState<TabMaterial[]>();
    const [financialData, setFinancialData] = useState<Extract[]>();
    const [initialDate, setInitialDate] = useState<Date | any>(undefined);
    const [finalDate, setFinalDate] = useState<Date | any>(undefined);
    const [errorDate, setErrorDate] = useState<string>('');
    const [downloadError, setErrorDoc] = useState<string>('');
    const [loadingDANFE, setLoadDANFE] = useState<boolean>(false);
    const [loadingXML, setLoadXML] = useState<boolean>(false);
    const [loadingCertify, setLoadCertify] = useState<boolean>(false);
    const [cnpjCpf, setCnpjCpf] = useState<string>('');
    const [salesOrg, setSalesOrg] = useState<string>('');
    const { loginData, darkMode, selectedCustomer, lng } = useUserStore();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [periodValue, setPeriodValue] = useState<string | null>('');
    const [showRangeDates, setShowRangeDates] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChangeDates = (dates: any) => {
        console.log(dates);
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setPeriodValue(dateRange(start,end));

        setShowRangeDates(false);
        setInitialDate(start);
        setFinalDate(end);
        return;
    };

    const dateRange = (start: any, end: any) => {
        return moment(start).format('DD/MM/yyyy') + ' - ' + moment(end).format('DD/MM/yyyy');
    }

    useEffect(() => {
        setLoading(true)

        let initialDateParam = initialDate;
        let finalDateParam = finalDate;
        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }
        getFinancialData(take, page, selectedCustomer.issuer, status === '' ? 'ALL' : status, search, initialDateParam, finalDateParam ?? null, period === '' ? '0' : period)
        .then(m => {
            if (m.success) {
                setFinancialData(m.data);
                setTotalPages(m.totalPages);
                setTotalItems(m.totalItems);
            }
        })
        .catch(e => console.log(e))
        .finally(() => setLoading(false));
    }, [take, page]);

    useEffect(() => {
        document.querySelectorAll("input[data-input='true']")?.forEach(el => {
            el?.setAttribute('disabled', 'true');
        });

    }, [[document.querySelectorAll("input[data-input='true']")]]);

    const getFinancial = () => {
        setLoading(true);
        let initialDateParam = initialDate;
        let finalDateParam = finalDate;
        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }

        getFinancialData(take, page, selectedCustomer.issuer, status === '' ? 'ALL' : status, search, initialDateParam, finalDateParam ?? null, period === '' ? '0' : period)
        .then(m => {
            if (m.success) {
                setFinancialData(m.data);
                setTotalPages(m.totalPages);
                setTotalItems(m.totalItems);
            }
        })
        .catch(e => console.log(e))
        .finally(() => setLoading(false));
    }

    const exportOrders = async () => {
        setLoadingExport(true);

        let initialDateParam = initialDate;
        let finalDateParam = finalDate;
        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }
        let nfs = financialData?.filter(f => f.checked === true)?.map(d => d.invoice);
        const resp = await getFileFinancial(selectedCustomer.issuer, status === '' ? 'ALL' : status, search, initialDateParam, finalDateParam ?? null, nfs);

        if (!!resp) {
            setLoadingExport(false);
        }
    }

    const getBankTicket = (salesOrg:string) =>
    {
        document.getElementById('CpfCnpj')?.setAttribute('value', selectedCustomer?.cpfCnpj);
        document.getElementById('CnpjEmpresa')?.setAttribute('value', salesOrg);
        document.getElementById('Bloqueto')?.click();
    }

    const mockDataTable = {
        headers: [
            {
                property: 'check',
                name: t('financialScreen.labelSelect'),
                isCheck: true
            },
            {
                property: selectedCustomer.country === 'AR' ? 'contractNumber' : 'invoice',
                name: t('financialScreen.labelInvoice'),
                isNumber: true
            },
            {
                property: 'emissionDate',
                name: t('financialScreen.labelEmissionDate')
            },
            {
                property: 'expiredDate',
                name: t('financialScreen.labelExpiredDate')
            },
            {
                property: 'salesNumber',
                name: t('financialScreen.labelSalesNumber'),
                isNumber: true
            },
            {
                property: 'totalValue',
                name: t('financialScreen.labelTotalValue'),
                isValue: true
            },
            {
                property: 'status',
                name: t('financialScreen.labelStatus'),
                isStatus: true
            },
            {
                property: 'actions',
                name: t('financialScreen.labelAction')
            },
        ]
    };

    const mockStatusStyle = [
        {
            name: 'V',
            bgColor: '#ffcfcf',
            color: '#e35b5b',
        },
        {
            name: 'C',
            bgColor: '#EEFFF2',
            color: '#30C10C',
        },
        {
            name: StatusIntent.OrderConfirmed,
            bgColor: '#FFE7FD',
            color: '#FF35EB',
        },
        {
            name: 'A',
            bgColor: '#FFF6C7',
            color: '#DFBC07',
        },
        {
            name: StatusIntent.EntryWeight,
            bgColor: '#F4EDFF',
            color: '#9E7BD5',
        },
        {
            name: StatusIntent.LoadAndSealed,
            bgColor: '#CFFFFC',
            color: '#0BE3D6',
        },
        {
            name: StatusIntent.Invoiced,
            bgColor: '#EEFFF2',
            color: '#30C10C',
        },
        {
            name: StatusIntent.Recused,
            bgColor: '#ffcfcf',
            color: '#e35b5b',
        },
        {
            name: StatusIntent.Canceled,
            bgColor: '#f7dcde',
            color: '#db2131',
        },
        {
            name: StatusIntent.EditSolicitation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.ReviewCancelation,
            bgColor: '#f7dcde',
            color: '#db2131',
        },
    ];

    const onChangeSearch = (e: ChangeEvent<FormControlElement>) => {
        if (e?.target?.value.length > 2 || e?.target?.value.length === 0) {
            setSearch(e?.target?.value);
        }
    }

    const onChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        setStatus(e?.target?.value);
    }

    const onChangePeriod = (e: ChangeEvent<HTMLSelectElement>) => {
        setPeriod(e?.target?.value);
    }

    const onChangeCheckedItem = (e: ChangeEvent<HTMLInputElement>, idx: any) => {
        const financial = financialData;
        financial![idx].checked = e.currentTarget.checked;
        setFinancialData([...financialData ?? []]);
        setEnableExport(financial!.filter(f => f.checked === true).length > 0);
    }

    const handleEnterKey = (event:any) => {
        if(event.key === 'Enter') {
            getFinancial();
        }
    }

    return (
        <>
            <div className='financial-wrapper'>
                <div className='my-orders-header w-100'>
                    <div className='my-orders-header-i'>
                        <img src={financial} alt='icone de novo pedido'/>
                        <p>{t('financialScreen.labelDocumentsFinancial')}</p>
                    </div>
                </div>
                <div className='d-flex flex-column w-100'>
                    <div className='my-orders-search w-100'>
                        <div className='input-nf-filter'>
                            <UniInput
                                id='search'
                                placeholder={t('financialScreen.labelNfNumber')}
                                label={t('financialScreen.labelSearch')}
                                type='text'
                                onChangeInput={(e) => onChangeSearch(e)}
                                enterKey={handleEnterKey}
                            />
                        </div>

                        <UniInput
                            id='Período'
                            label={t('financialScreen.labelPeriod')}
                            isSelect
                            value={period ?? '0'}
                            onChange={(e) => onChangePeriod(e)}
                            disabled={loading}
                        >
                            <option value={'0'}>{t('financialScreen.labelForEmission')}</option>
                            <option value={'1'}>{t('financialScreen.labelForExpired')}</option>
                        </UniInput>


                        <div style={{zIndex: 100}}>
                            <div style={{zIndex: 100}}>
                                <UniDatePickerRange
                                    loading={loading}
                                    periodValue={periodValue}
                                    onChange={e => onChangeDates(e)}
                                    options={[]}
                                    onHideChange={() => setShowRangeDates(!showRangeDates)}
                                />
                            </div>
                        </div>
                        <UniInput
                            id='status'
                            label='Status'
                            isSelect
                            value={status ?? 'ALL'}
                            onChange={(e) => onChangeStatus(e)}
                            disabled={loading}
                        >
                            <option value={'ALL'}>{t('financialScreen.labelAll')}</option>
                            <option value={'V'}>{t('financialScreen.labelOverdue')}</option>
                            <option value={'C'}>{t('financialScreen.labelPaidOut')}</option>
                            <option value={'A'}>{t('financialScreen.labelPending')}</option>
                        </UniInput>

                        <div className='d-flex search-refresh align-items-center'>
                            <FontAwesomeIcon title={t('financialScreen.labelSearch')} icon={faSearch} onClick={() => getFinancial()}/>
                        </div>
                         </div>
                </div>
                <div className='w-100 d-flex justify-content-center order-body'>
                    <div className='table-uni'>
                        {(enableCancel || enableExport) &&
                            <div className='buttons-selecteds'>
                                {enableExport && <div className='btn-selected' title={t('financialScreen.labelExportExcel')} onClick={() => exportOrders()}>{loadingExport ? <Spinner></Spinner> : <FontAwesomeIcon icon={faFileDownload} />}</div>}
                            </div>
                        }
                        <table>
                            <tr className='table-header'>
                                {
                                    mockDataTable.headers.map(header => (
                                        <td key={header.name} className={header.isCheck ? `check-header` : ``}>
                                            {
                                                header.isCheck ? (
                                                    <>
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            name={`default-check`}
                                                            onChange={(e) => {
                                                                if (e.currentTarget.checked) {
                                                                    setAllcheck(true);
                                                                    financialData?.forEach(o => o.checked = true);
                                                                    setFinancialData(financialData);
                                                                } else {
                                                                    setAllcheck(false);
                                                                    financialData?.forEach(o => o.checked = false);
                                                                    setFinancialData(financialData);
                                                                }
                                                                setEnableExport(financialData!.filter(f => f.checked === true).length > 0);
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {header.name}
                                                    </>
                                                )
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                            {
                                loading ? (
                                        <Skeleton
                                            width={'100%'}
                                            height={400}
                                            duration={1}
                                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                            baseColor={darkMode? '#021717' : '#ebebeb'}/>
                                ) :
                                (
                                    error ? (<p className='w-100 text-center p-2'>{t('financialScreen.labelNotFound')}</p>) :
                                    <tbody>
                                        {
                                            financialData?.map((order: any, idx) => (
                                                <tr key={idx.toString()}>
                                                    {
                                                        mockDataTable.headers.map((header, idxh) => {

                                                            if (header.property === 'check') {
                                                                return (
                                                                    <td className='td-check'>
                                                                        <Form.Check
                                                                            key={idxh.toString() + idx.toString()}
                                                                            type={'checkbox'}
                                                                            checked={order.checked}
                                                                            onChange={(e) => onChangeCheckedItem(e, idx)}
                                                                        />
                                                                    </td>
                                                                );
                                                            }

                                                            if (header.isStatus) {
                                                                return (
                                                                    <td key={idxh.toString() + idx.toString()}>
                                                                        <div className='table-status' style={{backgroundColor: mockStatusStyle.filter(s => s.name === order.statusFlag)?.[0]?.bgColor ?? '#fff'}}>
                                                                            <span className='table-status-icon' style={{backgroundColor: mockStatusStyle.filter(s => s.name === order.statusFlag)?.[0]?.color ?? '#fff'}}></span>
                                                                            <div className='w-100' style={{fontSize: 12, minWidth: 'fit-content'}}>{getDescriptionStatusFinancial(order?.statusFlag ?? '', lng)}</div>
                                                                        </div>
                                                                    </td>
                                                                );
                                                            }

                                                            if (header.isValue) {
                                                                return (
                                                                    <td key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}>{isNaN(Number(order?.[header.property])) ? order?.[header.property] ?? '-' : moneyMask(Number(order?.[header.property]).toFixed(2).replace('.', ','), selectedCustomer.country === 'AR' ? 'USD' : 'R$')}</td>
                                                                );
                                                            }

                                                            if (header.isNumber) {
                                                                return (
                                                                    <td key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}>{isNaN(Number(order?.[header.property])) ? order?.[header.property] ?? '-' : Number(order?.[header.property])}</td>
                                                                );
                                                            }

                                                            if (header.property === 'actions') {
                                                                return (
                                                                    <td className='d-flex'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle>
                                                                                <FontAwesomeIcon icon={faEllipsis}/>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{padding: 0}}>
                                                                                {
                                                                                    selectedCustomer.country === 'AR' ? (
                                                                                        <>
                                                                                        <div
                                                                                            className={'button-icon'}
                                                                                            title='Factura'
                                                                                            onClick={async () => {
                                                                                                setLoadDANFE(true);
                                                                                                const r = await getDanfe(order.salesNumber)

                                                                                                if (!r) {
                                                                                                    setErrorDoc(t('financialScreen.labelDANFEError'));
                                                                                                }
                                                                                                setLoadDANFE(false);
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                                                                                            <span>Factura</span>
                                                                                        </div>

                                                                                        <div
                                                                                            className={'button-icon'}
                                                                                            title={t('financialScreen.labelDownloadCertify')}
                                                                                            onClick={async () => {
                                                                                                setLoadCertify(true);
                                                                                                const numbers = await getShipments(order.salesNumber);
                                                                                                setLoadCertify(false);

                                                                                                numbers?.data?.forEach(async shipment => {
                                                                                                    setLoadCertify(true);
                                                                                                    const r = await getCertify(shipment)
                                                                                                    setLoadCertify(false);
                                                                                                })

                                                                                                if (!numbers?.data) {
                                                                                                    setErrorDoc(t('financialScreen.labelCertifyError'));
                                                                                                }

                                                                                            }}
                                                                                        >
                                                                                            <img src={certify} alt='certify'/>
                                                                                            {/* <FontAwesomeIcon icon={faFile}/> */}
                                                                                            <span>{t('financialScreen.labelDownloadCertify')}</span>
                                                                                        </div>

                                                                                            <div
                                                                                                className={'button-icon'}
                                                                                                title={'Remito'}
                                                                                                onClick={async () => {
                                                                                                    setLoadCertify(true);
                                                                                                    const numbers = await getShipments(order.salesNumber);
                                                                                                    setLoadCertify(false);

                                                                                                    numbers?.data?.forEach(async shipment => {
                                                                                                        setLoadCertify(true);
                                                                                                        const r = await getRemito(shipment)
                                                                                                        setLoadCertify(false);
                                                                                                    })

                                                                                                    if (!numbers?.data) {
                                                                                                        setErrorDoc(t('financialScreen.labelCertifyError'));
                                                                                                    }

                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faFile}/>
                                                                                                <span>{'Remito'}</span>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                            {
                                                                                                order?.statusFlag !== 'C' && (
                                                                                                    <div
                                                                                                        className={'button-icon'}
                                                                                                        title={t('financialScreen.labelGenerateBankBill')}
                                                                                                        onClick={() => getBankTicket(order.center) }
                                                                                                    >
                                                                                                        <FontAwesomeIcon icon={faBarcode}/>
                                                                                                        <span>{t('financialScreen.labelGenerateBankBill')}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            <div
                                                                                                className={'button-icon'}
                                                                                                title={t('financialScreen.labelDownloadXML')}
                                                                                                onClick={async () => {
                                                                                                    setLoadXML(true);
                                                                                                    const r = await getXML(order.salesNumber)

                                                                                                    if (!r) {
                                                                                                        setErrorDoc(t('financialScreen.labelXmlError'));
                                                                                                    }
                                                                                                    setLoadXML(false);
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faFileCode}/>
                                                                                                <span>{t('financialScreen.labelDownloadXML')}</span>
                                                                                            </div>

                                                                                            <div
                                                                                                className={'button-icon'}
                                                                                                title={t('financialScreen.labelDownloadDANFE')}
                                                                                                onClick={async () => {
                                                                                                    setLoadDANFE(true);
                                                                                                    const r = await getDanfe(order.salesNumber)

                                                                                                    if (!r) {
                                                                                                        setErrorDoc(t('financialScreen.labelDANFEError'));
                                                                                                    }
                                                                                                    setLoadDANFE(false);
                                                                                                }}
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                                                                                                <span>{t('financialScreen.labelDownloadDANFE')}</span>
                                                                                            </div>

                                                                                            <div
                                                                                                className={'button-icon'}
                                                                                                title={t('financialScreen.labelDownloadCertify')}
                                                                                                onClick={async () => {
                                                                                                    setLoadCertify(true);
                                                                                                    const r = await getCertify(order.salesNumber)
                                                                                                    if (!r) {
                                                                                                        setErrorDoc(t('financialScreen.labelCertifyError'));
                                                                                                    }
                                                                                                    setLoadCertify(false);
                                                                                                }}
                                                                                            >
                                                                                                <img src={certify} alt='certify'/>
                                                                                                {/* <FontAwesomeIcon icon={faFile}/> */}
                                                                                                <span>{t('financialScreen.labelDownloadCertify')}</span>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </td>
                                                                );
                                                            }

                                                            return (
                                                                <td key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}>{order?.[header.property]?.length > 15 ?  (order?.[header.property]?.slice(0, 15)?.replace(`.`, `/`) + '...' ?? '-') : order?.[header.property]?.replace(`.`, `/`)?.replace(`.`, `/`) ?? '-'}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
                { !error &&
                <UniPagination totalItems={totalItems} totalPages={totalPages} changePage={(e:any) => setPage(e)} changeTake={(e:any) => {setPage(1); setTake(e)}}/>
                }
            </div>

            <UniModal
                icon={faCalendarXmark}
                isFontAwsome
                title={t('financialScreen.labelInvalidDate')}
                message=''
                iconColor='#EA2027'
                show={errorDate != ''}
                onHide={() => {
                    setErrorDate('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{errorDate}</p>
            </UniModal>

            <UniModal
                icon={faDownload}
                isFontAwsome
                title={t('financialScreen.labelErrorDownloadComplete')}
                message=''
                iconColor='#EA2027'
                show={downloadError != ''}
                onHide={() => {
                    setErrorDoc('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{downloadError}</p>
            </UniModal>

            <UniModal
                icon={faDownload}
                isFontAwsome
                title={t('financialScreen.labelDownloadPending')}
                message=''
                iconColor='#00FF3C'
                show={loadingCertify || loadingDANFE || loadingXML}
                closeButton={false}
                onHide={() => {
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <Spinner />
            </UniModal>

            <UniBankTicket
                cnpjCpf={cnpjCpf}
                salesOrg={salesOrg}
            />

        </>
    );
}

export default Financial;