import '../styles/EditOrder.scss';
import newOrder from '../assets/icons/new-order-uni.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tuckLocation from '../assets/icons/ic-truck.svg';
import weightTon from '../assets/icons/new-ton.png';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import { faFileUpload, faInfo, faInfoCircle, faMinus, faPlus, faUpload, faWarning, faXmark, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faCalendarXmark, faCheckCircle, faTrashAlt, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import truckLocation from '../assets/icons/ic-truck2.svg';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Accordion, AccordionButton, Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { v4 as uuidv4, v4 } from 'uuid';
import UniModal from '../components/UniModal/UniModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Material, getMaterialsByIssuer } from '../services/materialService';
import { useUserStore } from '../store/user';
import { CustomerData, getCustomerDataByIssuer } from '../services/customerService';
import { useTranslation } from 'react-i18next';
import { Delivery, PaymentCond, Product, Receiver, Transporter, Veichle } from '../types/newOrder';
import UniNewOrderResume from '../components/UniNewOrderResume/UniNewOrderResume';
import { RequestOrderIntent, postOrderIntent, getFileModel, VehicleModel, TransporterModel, TransporterVehicleModel, getOrderToupdate, DeliveryResponseModel, putOrderIntent, RequestOrderUpdateIntent  } from '../services/orderService';
import { StatusIntent } from '../types/orderIntent';
import { useWindowSize } from '@uidotdev/usehooks';

const EditOrder = () => {
    const [products, setProducts] = useState<Material[]>();
    const [data, setData] = useState<DeliveryResponseModel>();
    const [customerData, setCustomerData] = useState<CustomerData>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedReceiver, setSelectedReceiver] = useState<Receiver>();
    const [selectedIncoterm, setSelectedIncoterm] = useState<string>();
    const [error, setError] = useState<string>();
    const [msgErrorDate, setErrorDate] = useState<string>('');
    const [msgErrorIntent, setErrorIntent] = useState<string>('');
    const [msgErrorFileModel, setErrorFileModel] = useState<string>('');
    const [showResume, setShowResume] = useState<boolean>(false);
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    const [multipleWeight, setMultipleWeight] = useState<number>(1);
    const [informedQuantity, setInformedQuantity] = useState<string>('');
    const [showNotMultiple, setShowNotMultiple] = useState<boolean>(false);
    const [block, setBlock] = useState<boolean>(false);
    const [showErroBlock, setErrorBlock] = useState<string>('');
    const [showModalSucces, setShowModalSucces] = useState<boolean>(false);
    const [selectedPaymentCondition, setSelectedPaymentCondition] = useState<PaymentCond>();
    const [paymentConditions, setPaymentConditions] = useState<PaymentCond[]>();
    const [transporters, setTransporters] = useState<Transporter[]>();
    const [receivers, setReceivers] = useState<Receiver[]>();
    const [incoterms, setIncoterms] = useState<string[]>();
    const [deliveries, setDeliveries] = useState<Delivery[]>([]);
    const [veichles, setVeichles] = useState<Veichle[]>([]);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [obs, setObs] = useState<string>('');
    const navigate = useNavigate();
    const { selectedCustomer, loginData } = useUserStore();
    const { i18n, t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    let { id } = useParams();
    const size = useWindowSize();

    useEffect(() => {
        let idOrder = '';

        if (searchParams.get('idOrder')) {
            idOrder = searchParams.get('idOrder') ?? '';
        }
        
        setLoadingProduct(true);
        getOrderToupdate(id ?? '', selectedCustomer.issuer, idOrder)
        .then(r => {
            if (r.success) {
                setData(r.data);
                if (r.data.orders) {
                    let orders = r.data.orders;
                    setBlock(false);

                    if (!orders.some(o => o.error == '' || o.error == null)) {
                        setBlock(true);
                    }
                }
                getCustomerDataByIssuer(selectedCustomer.issuer, r.data?.materialCode ?? '', true)
                .then(c => {
                    setCustomerData(c.data);
                    setMultipleWeight(c.data.multipleWeight);
                    populateInputs(c.data, r.data.orders, r.data);
                })
                .catch(e => console.log(e))
                .finally(() => setLoadingProduct(false));
            }
            else {                
                setErrorIntent(t('newOrderScreen.labelOrderNotFound'))
            }
        })
        .catch(err => setErrorIntent(t('newOrderScreen.labelOrderNotFound')));
    }, []);

    useEffect(() => {
        document.querySelectorAll("input[data-input='true']")?.forEach(el => {
            el?.setAttribute('disabled', 'true');
        });
    }, [document.querySelectorAll("input[data-input='true']")]);


    const populateInputs = (customerDataR: CustomerData, deliveriesR: Delivery[], dataR: DeliveryResponseModel) => {
        let transporterList: Transporter[] = [];
        let receiverList: Receiver[] = [];
        let incotermsList: string[] = [];
        let paymentConditionsAdd: PaymentCond[] = [];
        let veichlesAdd: Veichle[] = [];

        if(selectedCustomer?.country?.toUpperCase() === 'AR')
        {
            incotermsList.push('CPT');
            incotermsList.push('FCA');
        } 

        if (customerDataR?.defaultDataResponseDto?.length !== 0) {
            customerDataR?.defaultDataResponseDto?.forEach(d => {
                let newTransporter: Transporter = {
                    cod: d.FreightAgent,
                    name: d.FreightAgentName,
                    incoterm: d.Incoterm?.toUpperCase()
                }

                transporterList.push(newTransporter);

                let newIncoterm: string = d.Incoterm;

                if (selectedCustomer?.country?.toUpperCase() !== 'AR' && !incotermsList?.some(i => i === newIncoterm)) {
                    incotermsList.push(newIncoterm);
                }
                
                var description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition ?? '';
                
                if(description === '' || description === null || description === undefined)
                    description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition ?? ''; 

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }

                customerDataR?.veichleTypeResponseDto?.forEach(v => {                
                    if (d.FreightMaterialAgent) {
                        if (v.tms?.some(t => t.tm === d.FreightMaterialAgent?.trim()) ?? true) {
                            if (!veichlesAdd?.some(ve => ve.tm === v.tm && ve.incoterm === v.incoterm) || veichlesAdd.length === 0) {                                                            

                                let vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === i18n.language.toUpperCase());

                                if(vehiclesDescriptions.length === 0)
                                    vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === 'PT')

                                let newVeichle: Veichle = {
                                    id: v.id,
                                    descriptions: vehiclesDescriptions,
                                    incoterm: v.incoterm,
                                    tm: v.tm,
                                    maxValue: v.maxValue,
                                    minValue: v.minValue,
                                };
                
                                veichlesAdd.push(newVeichle);
                            }
                        }
                    }
                });
            });
        }
        else {
            customerDataR?.partnerResponseDtos?.forEach(d => {

                if (d.PartnerRole === 'AF') {
                    let newTransporter: Transporter = {
                        cod: d.Partner,
                        name: d.Name,
                        incoterm: 'AF'
                    }
    
                    transporterList.push(newTransporter);
                }
            });

            customerDataR?.salesDataResponseDto?.forEach(s => {
                let newIncoterm: string = s.Incoterms1;
                if (selectedCustomer?.country?.toUpperCase() === 'AR' && !incotermsList?.some(i => i === newIncoterm)) {
                    incotermsList.push(newIncoterm);
                }

                var description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? s.PaymentCondition  ?? '';

                if(description === '' || description === null || description === undefined)
                    description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? s.PaymentCondition  ?? '';

                let paymentNew: PaymentCond = {
                    cod: s.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            });

            customerDataR?.veichleTypeResponseDto?.forEach(v => {
                let newVeichle: Veichle = {
                    id: v.id,
                    descriptions: v.veichleDescriptions,
                    incoterm: v.incoterm,
                    tm: v.tm,
                    maxValue: v.maxValue,
                    minValue: v.minValue,
                };
                
                veichlesAdd.push(newVeichle);
            });
        }

        setSelectedIncoterm(dataR?.incoterms);
        setSelectedPaymentCondition(paymentConditionsAdd?.filter(p => p.cod == dataR?.paymentCondition)?.[0]);

        customerDataR?.partnerResponseDtos?.forEach(d => {
            if (d.PartnerRole === 'RM' && !receiverList?.some(i => i.cod === d.Partner)) {
                let newReceiver: Receiver = {
                    cod: d.Partner,
                    address: `${d.CNPJ ?? ''} - ${d.Name} - ${d.City ?? ''}`
                }

                receiverList.push(newReceiver);
            }
        });

        if (customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0] ?? false) {
            setSelectedReceiver({
                address: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Name ?? '',
                cod: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Partner ?? ''
            });
        }

        if (deliveriesR) {
            deliveriesR.forEach(d => {
                if (veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.id == d.veichleType?.id)?.[0] ?? false) {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.id == d.veichleType?.id)?.[0] ??
                        veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.tm == d.veichleType.tm)?.[0];
                }
                else {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms)?.[0]
                }

                if ((selectedCustomer.country === 'BR' || !selectedCustomer.country)) {
                    d?.itens?.forEach(i => {
                        if (transporterList?.filter(v => v.cod === i.transp?.cod)?.[0] ?? false) {
                            i.transp = {
                                cod: transporterList?.filter(v => v.cod === i.transp?.cod)?.[0]?.cod,
                                name: transporterList?.filter(v => v.cod === i.transp?.cod)?.[0]?.name
                            };
                            i.quantity = i.quantity;
                        }
                    })
                }
            });
        }
        
        setPaymentConditions(paymentConditionsAdd);
        setTransporters(transporterList);
        setIncoterms(incotermsList);
        setReceivers(receiverList);
        setVeichles(veichlesAdd);
        setDeliveries(deliveriesR); 
    }

    const getErrorBlock = (error: string) => {
        switch (error) {
            case 'ErrorDateOutOfLimit':
                return t('newOrderScreen.labelErrorDateOutOfLimit');
            case 'ErrorDatePassed':
                return t('newOrderScreen.labelErrorDatePassed');
            case 'ErrorDateEqual':
                return t('newOrderScreen.labelErrorDateEqual');
        }
    }

    const onAddDelivery = (delivery: Delivery) => {
        setDeliveries([delivery, ...deliveries]);
    }

    const onChangeProduct = (event: ChangeEvent<HTMLSelectElement>) => {
        setLoadingProduct(true);

        setSelectedProduct({
            description: event.target?.selectedOptions[0].text,
            materialCode: event.target?.value,
            pvc: products?.filter(p => p.materialCode === event.target?.value)?.[0].pvc ?? false 
        });

        getCustomerDataByIssuer(selectedCustomer.issuer, event.target?.value, products?.filter(p => p.materialCode === event.target?.value)?.[0]?.pvc ?? false)
            .then(r => {
                setCustomerData(r.data);
                setMultipleWeight(r.data.multipleWeight);
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingProduct(false));
    }

    const onChangeIncoterm = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedIncoterm(event.target?.value);

        let paymentConditionsAdd: PaymentCond[] = [];
        if (customerData?.defaultDataResponseDto?.length !== 0) {
            customerData?.defaultDataResponseDto.map(d => {

                var description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition  ?? '';

                if(description === '' || description === undefined || description === null)
                    description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition  ?? ''

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }
        else {
            customerData?.salesDataResponseDto.map(d => {

                var description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition  ?? '';

                if(description === '' || description === undefined || description === null)
                    description = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition  ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }

        deliveries?.forEach(d => {
            d.veichleType = veichles?.filter(v => v.incoterm === event.target?.value)?.[0] ?? 
            {
                tm: '-',
                descriptions: [],
                incoterm: ''
            };
            d.date = undefined;

            d.itens?.forEach(i => {
                i.date = undefined;
                i.quantity = d?.veichleType?.minValue ?? i.quantity;

                if (event.target?.value === 'CEF') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                }
                                  
            });
        })

        setSelectedPaymentCondition(paymentConditionsAdd?.[0]);
        setPaymentConditions(paymentConditionsAdd);
    }

    const onChangeReceiver = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedReceiver({
            address: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
    }

    const onChangePayment = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedPaymentCondition({
            description: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
    }

    const changeDeposit = (id: string, value: string|undefined) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery || !value) return;

        delivery.deposit = value;

        setDeliveries([...deliveries]);
    }    
 
    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    const isValidDate = (e: any): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidEqualDate'));
            return false;
        }        

        if (date < new Date()) {
            setErrorDate(t('newOrderScreen.returnValidPassedDate'));
            return false;
        } 

        if ((date.getDay() === 0 || date.getDay() === 6) && !!selectedProduct?.pvc && selectedIncoterm === 'FOB') {
            setErrorDate(t('newOrderScreen.returnValidEndofWeek'));
            return false; 
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter17Hour'));            
            return false;
        }

        const dateNow4 = new Date();
        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter11Hour'));                        
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);

        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            setErrorDate(t('newOrderScreen.returnValidAfter11FridayHour')); 
            return false;
        }

        return true;
    }

    const onChangeDate = (e: any, id: string) => {    
        
        if (!isValidDate(e)) return;

        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            delivery.date = e;

            delivery.itens?.forEach(i => {
                i.date = delivery.date
            });

            setDeliveries([...deliveries]);
        }
    }

    const onChangeVeichle = (e: ChangeEvent<HTMLSelectElement>, id: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            const element = veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm && v.descriptions.some(d => d.description === e.target?.selectedOptions[0].text))?.[0] ?? 
                            veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm)?.[0];

            delivery.veichleType = {
                id: e.target?.selectedOptions[0].value ?? '-',
                tm: element?.tm ?? '-',
                incoterm: element?.incoterm ?? 'CEF',
                minValue: element?.minValue ?? 0,
                maxValue: element?.maxValue ?? 0,
                descriptions: element?.descriptions ?? [],
            }

            let total = 0;
            delivery?.itens?.forEach(i => {
                i.quantity = delivery?.veichleType?.minValue ?? 0;
                total += i.quantity;
            });

            delivery.weight = total;

            setDeliveries([...deliveries]);
        }
    }

    const onChangeTransporter = (e: ChangeEvent<HTMLSelectElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.transp = {
                    name: e.target?.selectedOptions[0].text,
                    cod: e.target?.value
                }     
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeQuantityItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.quantity = Number(e?.target?.value) >= 0 && Number(e?.target?.value) <= delivery.veichleType.maxValue ? (Number(e?.target?.value) ?? 0) : item.quantity    
            }
            
            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }

            setDeliveries([...deliveries]);
        }
    }

    const onBlurQuantityItem = (e: FocusEvent<FormControlElement, Element>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);

        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];
            let change = false;
            if (item) {
                if (Number(e?.target?.value) < delivery.veichleType.minValue) {
                    item.quantity = delivery.veichleType.minValue;
                    change = true;     
                }
                else if (Number(e?.target?.value) > delivery.veichleType.maxValue) {
                    item.quantity = delivery.veichleType.maxValue;
                    change = true;
                } else {
                    if (multipleWeight != 1 && Number(e?.target?.value) % multipleWeight > 0) {
                        change = true;
                        item.quantity = delivery.veichleType.minValue;
                        setInformedQuantity(e?.target?.value);
                        setShowNotMultiple(true);
                    }
                }
            }
            
            if (!change) return;

            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }

            setDeliveries([...deliveries]);
        }
    }


    const onChangeOrderNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderNumber = e?.target?.value ?? ''   
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeMaterialNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.materialNumber = e?.target?.value ?? '';   
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderItem = e?.target?.value ?? '';    
            }

            setDeliveries([...deliveries]);
        }
    }

    const recountDeliveryWeight = (delivery: Delivery) => {
        if (delivery.weight !== undefined){
            let total = 0;

            delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                total += n;
            });
            delivery.weight = total;
        }
    }

    const removeDelivery = (id: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1)
            setDeliveries(deliveries?.filter(d => d.id !== id));
        else return
    }

    const removeDeliveryItem = (id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            delivery.itens = delivery.itens?.filter(i => i.id !== idItem);

            recountDeliveryWeight(delivery);
            setDeliveries([...deliveries]);
            changeOnlyCount(id, '');
        }
        else return
    }

    const changeOnlyCount = (id: string, count: string) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery) return;

        if (count === 'plus') {
            delivery.quantity += 1;
        }
        else if ((delivery.quantity - 1) >= 0) {
            delivery.quantity -= 1;
        }

        recountDeliveryWeight(delivery);
        setDeliveries([...deliveries]);
    }

    const changeCount = (id: string, count: string) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery) return;

        if (count === 'plus') {
            delivery.quantity += 1;
            
            if (delivery.itens) {
                delivery.itens?.push({
                    id: uuidv4(),
                    materialNumber: '',
                    orderItem: '',
                    orderNumber: '',
                    quantity: delivery?.veichleType?.minValue ?? 0,
                    date: delivery.date,
                    transp: transporters?.[0] ? {
                        cod: transporters?.[0]?.cod,
                        name: transporters?.[0]?.name,
                    } : undefined
                })
            }
            else {
                delivery.itens = [{
                    id: uuidv4(),
                    materialNumber: '',
                    orderItem: '',
                    orderNumber: '',
                    quantity: delivery?.veichleType?.minValue ?? 0,
                    date: delivery.date,
                    transp: transporters?.[0] ? {
                        cod: transporters?.[0]?.cod,
                        name: transporters?.[0]?.name,
                    } : undefined
                }];
            }
        }
        else if ((delivery.quantity - 1) >= 0) {
            delivery.quantity -= 1;
            delivery.itens?.pop();
        }

        recountDeliveryWeight(delivery);
        setDeliveries([...deliveries]);
    }

    const goToResume = () => {
        if (deliveries) {
            let anyWithoutDate = false;
            let anyWithoutTransporter = false;
            let anyWithoutVeichleType = false;
            let anyWithoutQuantity = false;
            let anyWithoutDeposit = false;

            anyWithoutVeichleType = deliveries?.some(d => d.veichleType.tm === '-') ?? false;
            anyWithoutDate = deliveries?.some(d => d.date === undefined) ?? false;
            anyWithoutDeposit = ((selectedCustomer?.country === 'AR' ?? false) && (deliveries?.some(d => !d.deposit || d.deposit === '-') ?? true));

            for (let d of deliveries) {
                anyWithoutTransporter = d.itens?.some(i => i.transp === undefined) ?? false;
                anyWithoutQuantity = d.itens?.some(i => i.quantity === 0) ?? false;
    
                if (anyWithoutDate || anyWithoutTransporter || anyWithoutVeichleType || anyWithoutQuantity || anyWithoutDeposit) {                    
                    setShowErrorForm(true);
                    return;
                }
            }

            setShowErrorForm(false);
            setShowResume(true);
        }
    }

    const sendOrders = async () => {
        let request: RequestOrderUpdateIntent[] = [];

        for (let d of deliveries) {
            
            if (!isValidDate(d.date)) return;
            if(selectedCustomer.country === 'AR' && (!d.deposit || d.deposit === '-')) return;

            d?.itens?.forEach(i => {

                if (selectedIncoterm === 'CEF') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                }

                let item: RequestOrderUpdateIntent = {
                    shipmentDate: d.date,
                    shipmentQuantity: i.quantity,
                    orderMaterial: i.materialNumber,
                    orderItem: i.orderItem,
                    orderNumber: i.orderNumber,
                    transporterCode: i.transp?.cod,
                    transporterName: i.transp?.name,
                    veichleType: d.veichleType?.tm,
                    veichleTypeName: d.veichleType?.descriptions?.[0]?.description ?? '',
                    comment: obs,
                    receiverCode: selectedReceiver?.cod,
                    isPVC: selectedProduct?.pvc,
                    materialCode: selectedProduct?.materialCode,
                    materialDescription: selectedProduct?.description,
                    paymentCondition: selectedPaymentCondition?.cod,
                    paymentConditionDescription: selectedPaymentCondition?.description,
                    customerCode: selectedCustomer.issuer,
                    incoterms: selectedIncoterm,
                    unitMeasurement: 'TO',
                    veichleTypeId: d.veichleType?.id,
                    status: StatusIntent.UniparValidation,
                    orderIntentGroupId: id === 'null' ? uuidv4().toString() : id ?? '',
                    id: searchParams.get('idOrder') ?? '',
                    deposit: d.deposit ?? undefined
                };
    
                request.push(item);
            })
        }

        setLoadingCreate(true);
        const result = await putOrderIntent(request, loginData?.email ?? '');
        setLoadingCreate(false);
        if (!result.success) {
            setErrorIntent(result.data);
        } else {
            setShowModalSucces(true);
        }
    }

    return (
        <>
            <div className='d-flex new-order-body'>
                <div className='edit-order-content'>
                    <div className='new-order-content-header'>
                        <img src={newOrder} alt='icone de novo pedido'/>
                        <p>{t('newOrderScreen.labelEditOrder')}</p>
                    </div>
                    {
                        !showResume ? (
                            <>
                                <div className='new-order-content-from'>
                                    <div>                
                                        <p className='form-title'>
                                            {t('newOrderScreen.labelProductInformation')}
                                        </p>
                
                                        <div className='product-info'>
                                            <div>
                                                <span>{data?.materialCode ? Number(data?.materialCode) : ''} - {data?.materialDescription}</span>
                                            </div>
                
                                            <div className='new-order-snacker'>
                                                <div>
                                                    <img src={weightTon} alt='icone tonelada' style={{height: 40}}/>
                                                    <div className='new-order-snacker-text'>
                                                        <span>{t('newOrderScreen.labelTotalVolume')}</span>
                                                        <p>{
                                                                deliveries?.length ?
                                                                    deliveries?.map(d => d.weight ?? 0).reduce((acc, cur) => acc + cur, 0) + ' t'
                                                                :   '-'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src={tuckLocation} alt='icone caminhao'/>
                                                    <div className='new-order-snacker-text'>
                                                        <span>{t('newOrderScreen.labelOrder')}</span>
                                                        <p>
                                                            {
                                                                deliveries?.length ?
                                                                    deliveries?.map(d => d.itens?.length ?? 0).reduce((acc, cur) => acc + cur, 0) 
                                                                :   '-'
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div className='divider'></div>
                                    </div>
                
                                    <div>
                                        <p className='form-title'>
                                            {t('newOrderScreen.labelOrderDetail')}
                                        </p>
                                        <div className='new-order-selects'>
                                            <div className='select-rm'>
                                                <UniInput 
                                                    label={t('newOrderScreen.labelDeliveryPlace')}
                                                    id='lcl' 
                                                    isSelect 
                                                    type='select' 
                                                    disabled={block}
                                                    loading={loadingProduct}
                                                    value={selectedReceiver?.cod} 
                                                    defaultValue={receivers?.[0]?.cod} 
                                                    placeholder={t('newOrderScreen.labelSelect')}
                                                    onChange={(e) => onChangeReceiver(e)}
                                                >
                                                    <option disabled value={undefined}>{t('newOrderScreen.labelSelect')}</option>
                                                    {
                                                        receivers?.map((pt, idx) => {
                                                            return <option key={idx} value={pt.cod}>{pt.address}</option>
                                                        })
                                                    }
                                                </UniInput>
                                            </div>
                
                                            <div className='d-flex flex-wrap'>
                                                <UniInput disabled={block} label={t('newOrderScreen.labelFreight')} id='frt' loading={loadingProduct} isSelect type='select' defaultValue={incoterms?.[0]} value={selectedIncoterm} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangeIncoterm(e)}>
                                                    <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                    {
                                                        incoterms?.map((i, idx) => {
                                                            if (i === 'CEF' || i === 'FOB' || i === 'SFR') {
                                                                return (
                                                                    <option value={i} key={idx}>{i === 'CEF' ? t('newOrderScreen.labelCIFFile') : i === 'SFR' ? t('newOrderScreen.labelSFRFile') : t('newOrderScreen.labelFOBFile')}</option>
                                                                )
                                                            }
                                                            if (i === 'FCA' || i === 'CPT') {
                                                                return (
                                                                    <option value={i} key={idx}>{i === 'CPT' ? t('newOrderScreen.labelCPTFile') : t('newOrderScreen.labelFCAFile')}</option>
                                                                )
                                                            }  
                                                        })
                                                    }
                                                </UniInput>
                                                
                                                {
                                                    selectedCustomer.country != 'AR' && (
                                                        <UniInput disabled={block} label={t('newOrderScreen.labelPaymentConditions')} loading={loadingProduct} id='cond' isSelect type='select' value={selectedPaymentCondition?.cod} defaultValue={paymentConditions?.[0]?.cod} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangePayment(e)}>
                                                            <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                            {
                                                                paymentConditions?.map((pt, idx) => {
                                                                    return <option key={idx} value={pt.cod}>
                                                                            {customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && i18n.language.toUpperCase() === p.Language)?.[0]?.Description ?? 
                                                                            customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && p.Language === 'PT')?.[0]?.Description ?? ''}
                                                                        </option>
                                                                })
                                                            }
                                                        </UniInput>
                                                    )
                                                }
                                            </div>
                                            {deliveries?.length > 0 && <p className='p-changes'>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelChangesInformationFOB'): t('newOrderScreen.labelChangesInformationCIF') }</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='deliveries'>
                                    <div className='d-flex justify-content-between align-items-center deliveries-header'>
                                    </div>
                                    <div className='d-flex flex-column-reverse'>
                                        {
                                            deliveries?.map((delivery, idx) => {
                                                return (
                                                    <Accordion key={idx} defaultActiveKey={'0'}>
                                                        {delivery.error && <span className='error-date'>*{getErrorBlock(delivery.error)}</span>}
                                                        <Accordion.Item key={idx} eventKey={'0'}>
                                                            <div className='d-flex wrapper-delivery-form'>
                                                                <div className='inputs-body d-flex justify-content-between align-items-center'>
                                                                    <div className='dt-tp d-flex gap-2'>
                                                                        <div className={selectedCustomer?.country === 'AR' ? 'date-wrapper-ar': 'date-wrapper'}>
                                                                            <label htmlFor='date-picker' style={{ marginBottom: 8 }}>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelIncotermDateFOB') : t('newOrderScreen.labelIncotermDateEditCIF')} *</label>
                                                                            <div className={showErrorForm && delivery.date === undefined ? 'input date input-error' : 'input date'} style={{width: '200px'}}>
                                                                                <DatePicker 
                                                                                    id='date-picker'
                                                                                    clearIcon={null}
                                                                                    calendarIcon={<FontAwesomeIcon icon={faCalendarDays}/>}
                                                                                    dayPlaceholder=''
                                                                                    monthPlaceholder=''
                                                                                    yearPlaceholder=''
                                                                                    onChange={e => onChangeDate(e, delivery.id)} 
                                                                                    value={delivery.date}
                                                                                    closeCalendar
                                                                                    disabled={block}
                                                                                    minDetail='year'
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className={showErrorForm && delivery.veichleType.tm === '-' ? 'input input-error' : 'input'} style={{margin: '0px 20px 0px 0px'}}>
                                                                            <UniInput 
                                                                                id='vei' 
                                                                                label={t('newOrderScreen.labelVehicleType') + ' *'}
                                                                                isSelect 
                                                                                type='select' 
                                                                                value={delivery.veichleType.id} 
                                                                                disabled={block}
                                                                                placeholder={t('newOrderScreen.labelVehicleType')} 
                                                                                // htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}} title={`Peso minímo: ${delivery.veichleType.minValue}t \nPeso máximo: ${delivery.veichleType.maxValue}t`}/>}
                                                                                defaultValue={'-'}
                                                                                onChange={(e) => onChangeVeichle(e, delivery.id)}
                                                                            >
                                                                                <option disabled value={'-'}>Selecione</option>
                                                                                {
                                                                                    veichles?.filter(v => v.incoterm === selectedIncoterm)?.map((v, idx) => (
                                                                                        <option key={idx} value={v.id}>{v.descriptions.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? 
                                                                                                                        v.descriptions.filter(d => d.lang === 'PT')?.[0]?.description ?? ''}</option>
                                                                                    ))
                                                                                }
                                                                            </UniInput>
                                                                        </div>

                                                                        {selectedCustomer?.country === 'AR' &&
                                                                            <div className={showErrorForm && ((selectedCustomer?.country === 'AR' ?? false) && ((selectedCustomer?.country === 'AR' ?? false) && (deliveries?.some(d => !d.deposit || d.deposit === '-') ?? true))) ? 'input input-error' : 'input'} style={{margin: '0px 20px 0px 0px'}}>
                                                                                <UniInput
                                                                                    id='deposit'
                                                                                    label={t('newOrderScreen.labelDeposit') + ' *'}
                                                                                    isSelect
                                                                                    type='select'
                                                                                    value={delivery.deposit}
                                                                                    placeholder={t('newOrderScreen.labelDeposit')}
                                                                                    // htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}} title={`Peso minímo: ${delivery.veichleType.minValue}t \nPeso máximo: ${delivery.veichleType.maxValue}t`}/>}
                                                                                    defaultValue={'-'}   
                                                                                    onChange={(e) => {changeDeposit(delivery.id, e.target.value)}}                                                                             
                                                                                >
                                                                                    <option value={'-'}>{t('newOrderScreen.labelSelect')}</option>
                                                                                    <option value='BAHIA BLANCA'>Bahia Blanca</option>
                                                                                    <option value='EZEIZA'>Ezeiza</option>
                                                                                </UniInput>
                                                                            </div>
                                                                        }
                                                                    </div>                                                                  
                                                                    {/* <div>
                                                                        <label htmlFor='counter'>Qtde. de veículos</label>
                                                                        <div className='counter-delivery' id='counter'>
                                                                            <div className='control' onClick={() => changeCount(delivery.id, '')}>
                                                                                <FontAwesomeIcon icon={faMinus} />
                                                                            </div>
                                                                            <div className='number'>{delivery.quantity?.toString()}</div>
                                                                            <div className='control' onClick={() => changeCount(delivery.id, 'plus')}>
                                                                                <FontAwesomeIcon icon={faPlus} />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className='icons-acc align-items-center d-flex w-0'>
                                                                        <div className='input-weight input'>
                                                                            <UniInput id='weight' type='text' label={t('newOrderScreen.labelTotalQty')} readOnly placeholder={`${delivery.weight} t`} value={`${delivery.weight} t`}/>
                                                                        </div>
                                                                        <AccordionButton />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Accordion.Body as={`div`} className='delivery-form'>
                                                                {
                                                                    delivery?.itens?.map((item, key) => {
                                                                        return (
                                                                            <div key={key}>
                                                                                <div className='wrapper-delivery-form-body'>
                                                                                    <div className='body-key'>
                                                                                        {key + 1}
                                                                                        <img src={truckLocation} alt='logo do caminhão' height={40}/>
                                                                                    </div>
                                                                                    <div className='body-header'>
                                                                                        <label>{t('newOrderScreen.labelProduct')}</label>
                                                                                        {data?.materialDescription}
                                                                                    </div>
                                                                                    <div className={showErrorForm && (item?.quantity === 0 ?? undefined) ? 'input-error' : ''}>
                                                                                        <UniInput 
                                                                                            label={t('newOrderScreen.labelQuantityWeight')}
                                                                                            type='number'
                                                                                            id='qtd-item' 
                                                                                            disabled={block}
                                                                                            htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5, color: '#ed9306', height: 14, cursor: 'help'}} title={`Peso minímo: ${delivery?.veichleType?.minValue}t \nPeso máximo: ${delivery?.veichleType?.maxValue}t`}/>}
                                                                                            defaultValue={delivery?.veichleType?.minValue ?? 0}
                                                                                            placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                            value={(item?.quantity === 0 ?? '') ? delivery?.veichleType?.minValue : item?.quantity} 
                                                                                            onChangeInput={(e) => onChangeQuantityItem(e, delivery.id, item.id)}
                                                                                            onBlurInput={(e) => onBlurQuantityItem(e, delivery.id, item.id)}
                                                                                            step={customerData?.multipleWeight ?? 1}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='wrapper-delivery-form-inputs'>
                                                                                    {
                                                                                        selectedIncoterm === 'FOB' && (selectedCustomer.country === 'BR' || !selectedCustomer.country) && (
                                                                                        <div className={showErrorForm && (item.transp?.cod === undefined ?? true) ? 'input-error' : ''}>
                                                                                            <UniInput 
                                                                                                id='transp' 
                                                                                                label={t('newOrderScreen.labelShippingCompany')}
                                                                                                isSelect 
                                                                                                type='select' 
                                                                                                disabled={block}
                                                                                                placeholder={t('newOrderScreen.labelSelect')}
                                                                                                value={item.transp?.cod}
                                                                                                defaultValue={transporters ? transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.[0]?.cod ?? transporters[0]?.cod ?? `` : ''}
                                                                                                onChange={(e) => onChangeTransporter(e, delivery.id, item.id)}
                                                                                            >
                                                                                                <option disabled value={''} selected>{t('newOrderScreen.labelSelect')}</option>
                                                                                                {
                                                                                                    transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.map((tr, idx) => {
                                                                                                        return <option key={idx} value={tr.cod}>{tr.name}</option>
                                                                                                    })
                                                                                                }
                                                                                            </UniInput>
                                                                                        </div>
                                                                                    )}
                                                                                    <div>
                                                                                        <UniInput id='numPed' disabled={block} label={t('newOrderScreen.labelOrderNumber')} type='text' placeholder={t('newOrderScreen.labelOrderNumber')} value={item.orderNumber} onChangeInput={(e) => onChangeOrderNumber(e, delivery.id, item.id)} htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}/>
                                                                                    </div>
                                                                                    <div>
                                                                                        <UniInput id='numMat' disabled={block} label={t('newOrderScreen.labelMaterialNumber')} type='text' placeholder={t('newOrderScreen.labelMaterialNumber')} value={item.materialNumber} onChangeInput={(e) => onChangeMaterialNumber(e, delivery.id, item.id)} htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}/>
                                                                                    </div>
                                                                                    <div>
                                                                                        <UniInput id='itemPed' disabled={block} label={t('newOrderScreen.labelOrdemItem')} type='text' placeholder={t('newOrderScreen.labelOrdemItem')} value={item.orderItem} onChangeInput={(e) => onChangeOrderItem(e, delivery.id, item.id)} htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='divider-body'></div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                )
                                            })
                                        }
                                    </div>
                
                                    <div className='buttons-send-order'>
                                        <p className={showErrorForm ? 'obs-error' : 'obs'}>
                                            {showErrorForm ? t('newOrderScreen.labelRequiredFieldsValidate') : t('newOrderScreen.labelRequiredFields')}
                                        </p>
                                        <div>
                                            <Button variant='outline' disabled={loadingProduct || loadingCreate} onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelBack')}</Button>
                                            <Button 
                                                onClick={() => sendOrders()} 
                                                disabled={
                                                    block ||
                                                    loadingCreate ||
                                                    (deliveries?.length === 0 ?? true) || 
                                                    selectedIncoterm === undefined || 
                                                    selectedPaymentCondition === undefined || 
                                                    selectedReceiver === undefined ||
                                                    loadingProduct
                                                }
                                                title={
                                                    selectedReceiver === undefined ? t('newOrderScreen.labelSelectedReceiver') :
                                                    selectedIncoterm === undefined ? t('newOrderScreen.labelSelectedIncoterm') :
                                                        selectedPaymentCondition === undefined ? t('newOrderScreen.labelPaymentCondition') :
                                                            (deliveries?.length === 0 ?? true) ? t('newOrderScreen.labelMessageDeliveryDate') : t('newOrderScreen.labelSaveChanges')

                                                }
                                                >
                                                    { loadingCreate ? <Spinner color='#fff'/> : t('newOrderScreen.labelSaveChanges')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <UniNewOrderResume 
                                deliveries={deliveries} 
                                selectedProduct={selectedProduct} 
                                sendOrders={() => sendOrders()} 
                                setObs={(e) => setObs(e.target?.value ?? '')} 
                                setShowResume={() => setShowResume(false)} 
                                weightTon={weightTon}
                                loading={loadingCreate}/>
                        )
                    }
                </div>
                <div className='new-order-instructions'>
                    <div className='img'></div>

                    <div className='instructions'>
                        <div className='flag'></div>
                        <p className='instructions-title'>{t('newOrderScreen.rulesTitle')}</p>
                        <p className='instructions-body'>
                            <p><FontAwesomeIcon icon={faCheckCircle}/> {t('newOrderScreen.rule1')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle}/> {t('newOrderScreen.rule2')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle}/> {t('newOrderScreen.rule3')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle}/> {t('newOrderScreen.rule4')}</p>

                        </p>
                    </div>
                </div>
            </div>
            <UniModal 
                icon={tuckLocation}
                title={t('newOrderScreen.labelConfirmAlterOrder')}
                message=''
                show={showModalSucces}
                onHide={() => {
                    setShowModalSucces(false);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={100}
            >
                <div className='modal-body-resume'>
                    <p style={{margin: 0}}>{t('newOrderScreen.labelComingSoon')}</p>
                    <p>{t('newOrderScreen.labelAccessArea')} <span>{t('newOrderScreen.labelMyOrders')}</span></p>
                    <Button onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelForMyOrders')}</Button>
                </div>
            </UniModal>

            <UniModal 
                icon={faCalendarXmark}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidDate')}
                message=''
                iconColor='#EA2027'
                show={msgErrorDate !== ''}
                onHide={() => {
                    setErrorDate('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorDate}</p>
                    <Button onClick={() => setErrorDate('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal 
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelErrorAlterOrder')}
                message=''
                iconColor='#EA2027'
                show={msgErrorIntent !== ''}
                onHide={() => {
                    setErrorIntent('');
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorIntent}</p>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title={t('newOrderScreen.labelMultipleQuantityError')}
                message=''
                iconColor='#ECEF4E'
                show={showNotMultiple}
                onHide={() => {
                    setShowNotMultiple(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>                    
                    <p>{t('newOrderScreen.labelMultipleQuantityError').replace('[informedQuantity]',informedQuantity).replace('[multipleWeight]',multipleWeight.toString())}</p>
                    <Button onClick={() => setShowNotMultiple(false)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal 
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelFileModel')}
                message=''
                iconColor='#EA2027'
                show={msgErrorFileModel !== ''}
                onHide={() => {
                    setErrorFileModel('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorFileModel}</p>
                    <Button onClick={() => setErrorFileModel('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>            
        </>
    )
}

export default EditOrder;