export function uniparEncrypt(password: string) {
    var aesjs = require('aes-js');
    var { v4: uuidv4 } = require('uuid');

    let guid = uuidv4();
    var replace = guid.split('-').join('');

    let key = new TextEncoder();
    var keyByte = key.encode(replace);

    let iv = new TextEncoder();
    var ivByte = iv.encode(replace.slice(0, 16));

    var textBytes = aesjs.utils.utf8.toBytes(password);
    var aesCfb = new aesjs.ModeOfOperation.cfb(keyByte, ivByte);
    var encryptedBytes = aesCfb.encrypt(textBytes);

    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    var ecryptedKeyHex = aesjs.utils.hex.fromBytes(keyByte);
    var ecryptedIvHex = aesjs.utils.hex.fromBytes(ivByte);

    var encrypted = [encryptedHex, ecryptedKeyHex, ecryptedIvHex].join(':');

    return encrypted;
}

export function encryptByte(str: string) {
    var key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
    var aesjs = require('aes-js');

    var textBytes = aesjs.utils.utf8.toBytes(str);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    return encryptedHex;
}

export function decryptByte(str: string) {
    var key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
    var aesjs = require('aes-js');

    var encryptedBytes = aesjs.utils.hex.toBytes(str);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

    return decryptedText;
}