import { Button, Spinner } from 'react-bootstrap';
import '../styles/UtilLinks.scss';
import links from '../assets/icons/links-2.svg';
import brasil from '../assets/icons/brasil.png';
import argentina from '../assets/icons/argentina.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp91, faArrowUpAZ, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link, LinkGroup, getUsefulLinksGroup } from '../services/usefulLinks';
import { useEffect, useState } from 'react';
import UniUsefulInformationCard from '../components/UniUsefulInformationCard/UniUsefulInformationCard';

const UtilLinks = () => {
const { i18n, t } = useTranslation();
const [usefulLinks, setUsefulLinks] = useState<Link[] | undefined>();
const [usefulLinkGroups, setUsefulLinkGroups] = useState<LinkGroup[] | undefined>();
const [loading, setLoading] = useState<boolean>(true);
const [error, setError] = useState<string>();


useEffect (() => {
        getCardsLinks();
    },[]);

const getCardsLinks = () => {
    setLoading(true);
        getUsefulLinksGroup()
            .then(response => {
                if (!response.success) {
                    setError(response.error);
                    setUsefulLinkGroups([]);
                }
                else {
                    setUsefulLinkGroups(response.linkGroups);
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    const setCountryNameByLanguage = (linkgroup:LinkGroup) =>{
        if (i18n.language === 'en')
            return linkgroup.descriptionEN;
        else if(i18n.language === 'es')
            return linkgroup.descriptionES;
        else
            return linkgroup.descriptionBR;
    }

    return (
        <div className='util-links-wrapper'>
            <div className='util-links-header w-100'>
                <div className='util-links-header-i'>
                    <img src={links} alt='icone de link'/>
                    <p>{t('usefulInformation.usefulInformation')}</p>
                </div>
            </div>

            <div className='util-links-body'>
            {loading ? (<Spinner style={{ color: '#027948', width: 25, height: 25 }} />) : (
                    usefulLinkGroups?.map(usefulLinkGroup => (
                        <div>
                        <UniUsefulInformationCard
                            links={usefulLinkGroup.links}
                            country={setCountryNameByLanguage(usefulLinkGroup)}
                            flag={usefulLinkGroup.flag}
                            accountBanks={usefulLinkGroup.bankAccounts}
                        /></div>
                    ))
                )}
            </div>
        </div>
    )
}
export default UtilLinks;