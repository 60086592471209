import logoVerde from '../../assets/icons/logo-pos.svg';
import menuHam from '../../assets/icons/menu-ham.svg';
import newOrder from '../../assets/icons/ic-order.svg';
import orders from '../../assets/icons/orders-2.svg';
import financial from '../../assets/icons/financial-2.svg';
import links from '../../assets/icons/links-2.svg';
import home from '../../assets/icons/home-2.svg';
import './UniMenu.scss';
import { routesMenu } from '../../routes';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useUserStore } from '../../store/user';
import { Profile } from '../../services/userService';
import { useWindowSize } from "@uidotdev/usehooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
interface UniMenuProps {
    activeRoute?: string;
}

const UniMenu = ({ activeRoute }: UniMenuProps) => {
    const [extend, setExtend] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loginData } = useUserStore();
    const size = useWindowSize();

    const onTouchMenu = (show: boolean = false) => {
        setExtend(show);
    };

    const goToRoute = (route: string) => {
        navigate(`/${route}`);
    }

    const getIcon = (iconName: string) => {
        switch (iconName) {
            case 'home':
                return home;
            case 'newOrder':
                return newOrder;
            case 'orders':
                return orders;
            case 'links':
                return links;
            case 'financial':
                return financial;
            default:
                return newOrder;
        }
    }
    return (
        <menu
            id='menu'
            className={extend ? 'extend' : ''}
            onPointerOver={() => (size?.width && size?.width >= 910) ? onTouchMenu(true) : (!size?.width) ? onTouchMenu(true) : console.log('mobile')}
            onPointerLeave={() => (size?.width && size?.width >= 910) ? onTouchMenu(false) : (!size?.width) ? onTouchMenu(false) : console.log('mobile')}
            onClick={() => (size?.width && size?.width < 910) ? onTouchMenu(!extend) : console.log('mobile')}
        >
            <div className={extend ? 'menu-item-on-center' : 'menu-item'}>
                {!extend ? (<img src={menuHam} width={28} style={{ marginTop: 20 }} alt='logo Unipar verder' />)
                    : (<img src={logoVerde} width={60} style={{ marginTop: 20 }} className='me-lg-5 on-center' alt='logo Unipar verde' />)}

                    {size?.width && size?.width < 910 && extend &&
                        <div className='d-flex align-items-center gap-3 justify-content-start w-100'>
                            <FontAwesomeIcon icon={faX} />
                            <img src={logoVerde} width={60} className='me-lg-5 on-center logo-mobile' alt='logo Unipar verde' />
                        </div>
                    }
            </div>
            {
                routesMenu.map(r => (
                    r.id == 'new-order' ? (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) && (
                        <div className={activeRoute === r.id ? 'menu-item item-active' : 'menu-item'} key={r.id} onClick={() => goToRoute(r.id)}>
                            <div className='menu-icon'>
                                <img src={getIcon(r.icon)} width={28} alt={r.icon} />
                            </div>
                            {extend && (<span>{t(r.translateId)}</span>)}
                        </div>
                    ) : (
                        <div className={activeRoute === r.id ? 'menu-item item-active' : 'menu-item'} key={r.id} onClick={() => goToRoute(r.id)}>
                            <div className='menu-icon'>
                                <img src={getIcon(r.icon)} width={28} alt={r.icon} />
                            </div>
                            {extend && (<span>{t(r.translateId)}</span>)}
                        </div>
                    )
                ))
            }
        </menu>
    )
};

export default UniMenu;