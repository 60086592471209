import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/MyOrders.scss';
import { faArrowsRotate, faFileDownload, faSearch, faSort, faSortAmountDown, faSortAmountUp, faSquareCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionButton, Button, Form, Spinner } from 'react-bootstrap';
import orders from '../assets/icons/orders-2.svg';
import newOrder from '../assets/icons/new-order.svg';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import UniPagination from '../components/UniPagination/UniPagination';
import UniTracking from '../components/UniTracking/UniTracking';
import { ResponseOrderIntentShipment, getOrdersIntentsShipments, ReasonCancellationTypeResponseModel, cancelOrderIntent, OrderCancellationRequestModel, getFileOrders } from '../services/orderService';
import { useUserStore } from '../store/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getDescriptionStatusIntentLanguage } from '../helpers/enums';
import { StatusIntent } from "../types/orderIntent";
import moment from 'moment';
import UniModalOrderDetail from '../components/UniModalOrderDetail/UniModalOrderDetail';
import { TabMaterial, getMaterialHasPrice, getMaterialsByIssuerBought } from '../services/materialService';
import { faPenToSquare, faCheckCircle, faXmarkCircle, faCalendarDays, faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
import UniModal from '../components/UniModal/UniModal';
import { Profile } from '../services/userService';
import { useTranslation } from "react-i18next";
import { useWindowSize } from '@uidotdev/usehooks';
import DatePicker from 'react-date-picker';
import UniDatePickerRange, { DateOptions } from '../components/UniDatePickerRange/UniDatePickerRange';
import Multiselect from 'multiselect-react-dropdown';
import { statusToFilter } from '../helpers/utils';

type SearchForm = {
    search?: string,
    periodId?: string,
    materialCode?: string,
    status?: string[]
};

var sortField = '';
var isChecked = false;

const MyOrders = () => {
    const [enableCancel, setEnableCancel] = useState<boolean>(false);
    const [enableExport, setEnableExport] = useState<boolean>(false);
    const [loadingExport, setLoadingExport] = useState<boolean>(false);
    const [exportError, setExportError] = useState<boolean>(false);
    const [cancelOrderId, setCancelOrderId] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [ordersShipments, setOrders] = useState<ResponseOrderIntentShipment[]>();
    const [search, setSearch] = useState<SearchForm>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>();
    const [totalItems, setTotalItems] = useState<number>();
    const [take, setTake] = useState<any>(10);
    const [page, setPage] = useState<number>(0);
    const [showModalOrderDetail, setShowModalOrderDetail] = useState<boolean>(false);
    const [allCheck, setAllcheck] = useState<boolean>(false);
    const [showRebuyError, setShowRebuyError] = useState<boolean>(false);
    const [orderId, setOrder] = useState<string>('');
    const [materials, setMaterials] = useState<TabMaterial[]>();
    const [showMaterials, setShowMaterials] = useState<TabMaterial[]>();
    const [showModalCancelOrderIntent, setShowModalCancelOrderIntent] = useState(false);
    const [msgOkCancelOrderIntent, setMsgOkCancelOrderIntent] = useState('');
    const [msgErrorCancelOrderIntent, setMsgErrorCancelOrderIntent] = useState('');
    const [reasonsCancellationType, setReasonCancellationType] = useState<ReasonCancellationTypeResponseModel[]>();
    const [showOpened, setShowOpened] = useState(false);
    const { loginData, darkMode, selectedCustomer, lng } = useUserStore();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [statusSelectedValues, setStatusSelectedValue] = useState<any>([{name: t('myOrders.intervalAll'), id: 0}]);
    const size = useWindowSize();    
    //const [sortField, setSortField] = useState<string>('');
    const [initialDate, setInitialDate] = useState<Date | any>(undefined);
    const [finalDate, setFinalDate] = useState<Date | any>(undefined);
    const [errorDate, setErrorDate] = useState<string>('');
    const [periodValue, setPeriodValue] = useState<string | null>(t('myOrders.intervalCurrentMonth'));
    const [periodId, setPeriodId] = useState<any | null>(0);

    const [optionSelected, setOptionSelected] = useState<DateOptions | null>(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showRangeDates, setShowRangeDates] = useState(false);
    const onChangeDates = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setPeriodValue(dateRange(start,end));
        setOptionSelected(null);
        setShowRangeDates(false);
        setInitialDate(start);
        setFinalDate(end);
        setPeriodId(null);
        return;
    };

    const dateRange = (start: any, end: any) => {
        return moment(start).format('DD/MM/yyyy') + ' - ' + moment(end).format('DD/MM/yyyy');
    }

    const onChangePeriod = (option: DateOptions) => {
        setStartDate(null);
        setEndDate(null);
        setInitialDate(null);
        setFinalDate(null);
        setPeriodValue(option.description);
        setOptionSelected(option);
        setShowRangeDates(false);
        setPeriodId(option.value);
        return;
    };

    const datePickerOptions: DateOptions[] = [
        {
            value: '',
            description: t('myOrders.intervalAll')
        },
        {
            value: '0',
            description: t('myOrders.intervalCurrentMonth')
        },
        {
            value: '4',
            description: t('myOrders.intervalNextMonth')
        },
        {
            value: '1',
            description: t('myOrders.intervalLastMonth')
        },
        {
            value: '2',
            description: t('myOrders.intervalLastSixty')
        },
        {
            value: '3',
            description: t('myOrders.intervalLastNinety')
        }
    ]

    useEffect(() => {
        if (searchParams.get('materialCode') || searchParams.get('status') || searchParams.get('search')) {
            if (materials) getOrders();
        } else {
            getOrders();
        }
    }, [take, page]);

    useEffect(() => {
        getMaterialsByIssuerBought(selectedCustomer.issuer)
            .then(m => {
                if (m.success) {
                    setMaterials(m.materials);
                    let materialParam = '';
                    setShowOpened(false);

                    if (searchParams.get('status') && searchParams.get('materialCode')) {
                        let status = searchParams.get('status') ?? '';
                        let materialCode = searchParams.get('materialCode') ?? '';
                        setSearch({ ...search, status: [status], materialCode });
                        setStatusSelectedValue(statusToFilter(t)?.filter(s => s.id.toString() === status) ?? [{name: t('myOrders.intervalAll'), id: 0}]);
                        getOrdersWithParam(materialCode, [status]);
                    }
                    else if (searchParams.get('materialCode')) {
                        let materialCode = searchParams.get('materialCode') ?? '';
                        setSearch({ ...search, materialCode});
                        getOrdersWithParam(materialCode);
                    }
                    else if (searchParams.get('search')) {
                        let searchP = searchParams.get('search') ?? '';
                        setSearch({ ...search, search: searchP, periodId: search?.periodId ?? '' });
                        setShowOpened(true);
                        getOrdersWithParam(undefined, undefined, searchP);
                    }

                    let materialsToShow: TabMaterial[] = m.materials?.some(m => m.materialCode === materialParam) ? [m.materials.filter(m => m.materialCode === materialParam)?.[0]] : [];
                    m.materials?.forEach(m => {
                        if (!materialsToShow?.some(ms => ms.materialDescription == m.materialDescription)) {
                            materialsToShow.push(
                                {
                                    materialCode: m.materialCode,
                                    materialDescription: m.materialDescription
                                }
                            )
                        }
                    });
                    setShowMaterials(materialsToShow);
                }
            })
            .catch(e => console.log(e));
    }, [])  

    const getOrders = () => {
        setEnableCancel(false);
        setEnableExport(false);

        if(!selectedCustomer.issuer)
        {
            setLoading(false);
            return;
        }
        let initialDateParam = initialDate;
        let finalDateParam = finalDate;

        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }

        setLoading(true);
        setError('');
        getOrdersIntentsShipments(take, page, search?.search ?? '', selectedCustomer.issuer, periodId , search?.materialCode ?? '', search?.status ?? [''], '', '', sortField, i18n.language.toUpperCase(), initialDateParam, finalDateParam)
            .then(response => {
                if (!response.success) {
                    var error = response.error ?? '';

                    if(i18n.language.toUpperCase() === 'PT')
                        error = response.errorPT ?? '';
                    if(i18n.language.toUpperCase() === 'EN')
                        error = response.errorEN ?? '';
                    if(i18n.language.toUpperCase() === 'ES')
                        error = response.errorES ?? '';

                    setError(error);
                }
                else {
                    response.data?.forEach(d => d.checked = false);
                    setOrders(response.data);
                    setTotalItems(response.totalItems);
                    setTotalPages(response.totalPages);
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const getOrdersWithParam = (materialCode?: string, status?: string[], searchP?: string) => {
        setLoading(true);
        setError('');
        getOrdersIntentsShipments(take, page, searchP ?? search?.search ?? '', selectedCustomer.issuer, materialCode || status ? '0' : (search?.periodId ?? ''), materialCode ?? '', status ?? [''], '', '')
            .then(response => {
                if (!response.success) {
                    var error = response.error ?? '';

                    if(i18n.language.toUpperCase() === 'PT')
                        error = response.errorPT ?? '';
                    if(i18n.language.toUpperCase() === 'EN')
                        error = response.errorEN ?? '';
                    if(i18n.language.toUpperCase() === 'ES')
                        error = response.errorES ?? '';

                    setError(error);
                }
                else {
                    response.data?.forEach(d => d.checked = false);
                    setOrders(response.data);
                    setTotalItems(response.totalItems);
                    setTotalPages(response.totalPages);
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const cancelOrders = () => {
        var orderIds = [];

        if (cancelOrderId !== '')
            orderIds = ordersShipments!.filter(f => f.id === cancelOrderId)?.map(d => d.id);
        else
            orderIds = ordersShipments!.filter(f => f.checked === true && (f.status === 1 || f.status === 3))?.map(d => d.id);

        let request: OrderCancellationRequestModel = {
            orderIds: orderIds,
            issuerCode: selectedCustomer?.issuer,
            reasonCancelation: t('myOrders.reasonCancelation')
        }

        setLoading(true);
        cancelOrderIntent(request)
            .then(response => {
                if (response.success)
                    setMsgOkCancelOrderIntent(t('myOrders.orderCancelSuccess'));
                else
                    setMsgErrorCancelOrderIntent(t('myOrders.orderErrorCancelSuccess'));
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false);
                getOrders();
                setShowModalCancelOrderIntent(false);
                setEnableCancel(false);
                setCancelOrderId('');
                setEnableExport(false);
            })
    }

    const cancelOrder = (id: string) => {
        setCancelOrderId(id);
        setShowModalCancelOrderIntent(true);
    }

    const exportOrders = async () => {
        setLoadingExport(true);

        let initialDateParam = initialDate;
        let finalDateParam = finalDate;

        if (typeof(initialDate) === 'object' && typeof(finalDate) === 'object'){
            initialDateParam = moment(initialDate).format('yyyy-MM-DD');
            finalDateParam = moment(finalDate).format('yyyy-MM-DD');
        }

        let orderIds = ordersShipments?.filter(f => f.checked === true)?.map(d => d.id);
        const resp = await getFileOrders(search?.search ?? '', selectedCustomer.issuer, periodId, search?.materialCode ?? '', search?.status ?? [''], '', '', allCheck ? undefined : orderIds, sortField, i18n.language.toUpperCase(), initialDateParam, finalDateParam);
        if (!!resp) {
            setLoadingExport(false);
        } else {

        }
    }

    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    const isValidDate = (e?: any): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            return false;
        }

        if (date < new Date()) {
            return false;
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            return false;
        }

        const dateNow4 = new Date();
        if (dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            return false;
        }

        const dateNow3 = new Date();
        const nextDay = new Date(dateNow3.getFullYear(), dateNow3.getMonth(), dateNow3.getDate() + 1);
        if (dateNow.getHours() >= 11 && date.toDateString() === nextDay.toDateString()) {
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);
        if (dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            return false;
        }

        return true;
    }

    const mockDataTable = {
        headers: [
            {
                index: 0,
                property: 'check',
                name: `${t('myOrders.select')}`,
                isCheck: true
            },
            {
                index: 1,
                property: 'materialDescription',
                name: `${t('myOrders.product')}`
            },
            {
                index: 2,
                property: 'customerOrderNumber',
                name: `${t('myOrders.customerOrderNumber')}`
            },
            {
                index: 3,
                property: 'salesNumber',
                name: `${t('myOrders.uniparOrderNumberAbr')}`
            },
            {
                index: 4,
                property: 'transporterName',
                name: `${t('myOrders.transporter')}`
            },
            {
                index: 5,
                property: 'shipmentDate',
                name: `${t('myOrders.shipmentDate')}`,
                isDate: true
            },
            {
                index: 6,
                property: 'shipmentQuantity',
                name: `${t('myOrders.shipmentQnt')}`,
                isNumber: true
            },
            {
                index: 7,
                property: 'nfNumber',
                name: 'NFe',
                isNumber: true
            },
            {
                index: 8,
                property: 'status',
                name: 'Status',
                isStatus: true
            },
            {
                index: 9,
                property: 'actions',
                name: '',
            },
        ]
    };


    const mockStatusStyle = [
        {
            name: StatusIntent.UniparValidation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.CustomerPendency,
            bgColor: '#FFEFEA',
            color: '#FF6636',
        },
        {
            name: StatusIntent.OrderConfirmed,
            bgColor: '#FFE7FD',
            color: '#FF35EB',
        },
        {
            name: StatusIntent.Arrived,
            bgColor: '#FFF6C7',
            color: '#DFBC07',
        },
        {
            name: StatusIntent.EntryWeight,
            bgColor: '#F4EDFF',
            color: '#9E7BD5',
        },
        {
            name: StatusIntent.LoadAndSealed,
            bgColor: '#CFFFFC',
            color: '#0BE3D6',
        },
        {
            name: StatusIntent.Invoiced,
            bgColor: '#EEFFF2',
            color: '#30C10C',
        },
        {
            name: StatusIntent.Recused,
            bgColor: '#ffcfcf',
            color: '#e35b5b',
        },
        {
            name: StatusIntent.EditSolicitation,
            color: '#2C99F7',
            bgColor: '#ECF6FF',
        },
        {
            name: StatusIntent.ReviewCancelation,
            bgColor: '#f7dcde',
            color: '#db2131',
        }
    ];

    const onChangeSearch = (e: ChangeEvent<FormControlElement>) => {
        setSearch({ ...search, search: e?.target?.value });
    }

    const onChangeMaterialCode = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearch({ ...search, materialCode: e?.target?.value });
    }

    const onChangePeriodId = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearch({ ...search, periodId: e?.target?.value });
    }

    const onChangeStatusMulti = (e: any, s: any) => {
        if (s.id === 0) {
            setStatusSelectedValue([{name: t('myOrders.intervalAll'), id: 0}]);
            setSearch({ ...search, status: []});
            return;
        }

        if (e?.some((s: any) => s.id === 0) ?? true) {
            const statusSelected = e?.filter((s: any) => s.id === 0)?.[0];
            if (statusSelected) {
                let index = e?.indexOf(statusSelected);
                e?.splice(index, 1);
            }
        }
        
        setStatusSelectedValue(e);

        const statusIds = e?.map((i: any) => i.id.toString());
        setSearch({ ...search, status: e ? statusIds : '' })
    }

    const onRemoveStatusMulti = (e: any, s: any) => {
        if (s.id === 0) {
            setStatusSelectedValue([]);
        } else {
            setStatusSelectedValue(e);
        }

        const statusIds = e?.map((i: any) => i.id);
        setSearch({ ...search, status: e ? statusIds : '' })
    }

    const onChangeCheckedItem = (e: ChangeEvent<HTMLInputElement>, idx: any) => {
        const orders = ordersShipments;
        orders![idx].checked = e.currentTarget.checked;
        setOrders([...orders ?? []]);
        setEnableCancel(orders!.filter(f => f.checked === true && (f.status === 1 || f.status === 3)).length > 0 && !orders!.some(f => f.checked === true && !isValidDate(f.shipmentDate)));
        setEnableExport(orders!.filter(f => f.checked === true).length > 0);
    }

    const openModalCancelation = () => {
        if (ordersShipments!.filter(f => f.checked === true).length === 0) {
            setMsgErrorCancelOrderIntent('Selecione pelo menos um pedido!');
            return;
        }
        setShowModalCancelOrderIntent(true);
    }

    const rebuy = async (materialCode: string, order: any) => {
        const response = await getMaterialHasPrice(selectedCustomer.issuer, materialCode);
        if (response.data) {
            navigate(`/rebuy/${order?.orderIntentGroupId}?idOrder=${order?.id}`);
        } else {
            setShowRebuyError(true);
        }
    }

    const sortList = (property: string) =>
    {
        isChecked=false;
        setAllcheck(false);

        var sort = '';
        if(sortField.indexOf(property)>=0)
        {
            if(sortField.indexOf('=asc')>0)
                sort = property + '=desc'
            else
                sort = property + '=asc'
        }
        else
            sort = property + '=asc'

        sortField = sort;

        getOrders();
    }

    const MobileGrid = () => (
        <Accordion as={'div'}>
            {
                ordersShipments?.map((order: any, idx) => (
                    <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                        <div className='item-header flex-column'>
                            <div className='d-flex gap-3'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {

                                        if (header.property === 'check') {
                                            return (
                                                <Form.Check
                                                    key={idxh.toString() + idx.toString()}
                                                    type={'checkbox'}
                                                    checked={order.checked}
                                                    onChange={(e) => onChangeCheckedItem(e, idx)}
                                                />
                                            );
                                        }

                                        if (header.isStatus) {
                                            return (
                                                <div key={idxh.toString() + idx.toString()} style={{ minWidth: 'fit-content', width: 165 }}>
                                                    <div className='table-status' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.bgColor ?? '#fff', minWidth: 145 }}>
                                                        <span className='table-status-icon' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.color ?? '#fff' }}></span>
                                                        <div className='w-100' style={{ fontSize: 12, minWidth: 'fit-content' }}>{getDescriptionStatusIntentLanguage(order?.[header.property] ?? '', i18n.language)}</div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property == 'materialDescription') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}><span>{t('myOrders.product')}:</span> {order?.[header.property]?.length > 25 ? (order?.[header.property]?.slice(0, 21) + '...' ?? '-') : order?.[header.property] ?? '-'}</div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex gap-3'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property === 'orderNumber') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}>{
                                                    order?.['orderNumber'] ? (isNaN(Number(order?.['orderNumber'])) ? order?.['orderNumber'] : Number(order?.['orderNumber'])) : '-'
                                                }
                                                </div>
                                            )
                                        }

                                        if (header.property === 'customerOrderNumber') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}><span>{t('myOrders.customerOrderNumber')}:</span> {
                                                    order?.['customerOrderNumber'] ? (isNaN(Number(order?.['customerOrderNumber'])) ? order?.['customerOrderNumber'] : Number(order?.['customerOrderNumber'])) : '-'
                                                }
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property === 'salesNumber') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}><span>{t('myOrders.uniparOrderNumberAbr')}:</span> {
                                                    order?.['salesNumber'] ? (isNaN(Number(order?.['salesNumber'])) ? order?.['salesNumber'] : Number(order?.['salesNumber'])) : '-'
                                                }
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property == 'transporterName') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}><span>{t('myOrders.transporter')}:</span> {order?.[header.property]?.length > 30 ? order?.[header.property] ?? '-' : order?.[header.property] ?? '-'}</div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex gap-3'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.isDate) {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}><span>{t('myOrders.shipmentDate')}: </span> {order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-'}</div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex gap-3'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property == 'nfNumber') {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}>
                                                    <span>NFe:</span> {order?.['nfNumber'] ? (isNaN(Number(order?.['nfNumber'])) || isNaN(Number(order?.['nfSeries']))) ? order?.['nfNumber'] : Number(order?.['nfNumber']) + '-' + Number(order?.['nfSeries']) : '-'}
                                                    </div>
                                            )
                                        }

                                        if (header.isNumber) {
                                            return (
                                                <div key={idxh.toString() + idx.toString()}><span>{t('myOrders.shipmentQnt')}:</span> {order?.[header.property] ? order?.[header.property]?.toString()?.replace('.', ',') + ' t' ?? '-' : '-'}</div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            <div className='d-flex align-self-end'>
                                {
                                    mockDataTable.headers.map((header, idxh) => {
                                        if (header.property === 'actions') {
                                            return (<AccordionButton key={idxh.toString() + idx.toString()} />);
                                        }

                                    })
                                }
                            </div>
                        </div>
                        <Accordion.Body as={`div`}>
                            <div className='item-body'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex w-100 flex-column'>
                                        <div className='d-flex flex-column w-100'>
                                            <div className='item-body-labels flex-wrap'>
                                                <div className='item-body-labels-wrapper'>
                                                    <label>{t('myOrders.uniparOrderNumber')}</label>
                                                    <p>
                                                        {
                                                            order?.['salesNumber'] ? (isNaN(Number(order?.['salesNumber'])) ? order?.['salesNumber'] : Number(order?.['salesNumber'])) :
                                                                order?.['orderNumber'] ? (isNaN(Number(order?.['orderNumber'])) ? order?.['orderNumber'] : Number(order?.['orderNumber'])) : '-'
                                                        }
                                                    </p>
                                                </div>
                                                <div className='item-body-labels-wrapper'>
                                                    <label>{t('myOrders.materialNumber')}</label>
                                                    <p>{order?.['materialCode'] ? Number(order?.['materialCode']) : '-'}</p>
                                                </div>
                                                {
                                                    selectedCustomer.country != 'AR' && (
                                                        <div className='item-body-labels-wrapper'>
                                                            <label>{t('myOrders.paymentConditions')}</label>
                                                            <p>{order?.['paymentConditionDescription'] ?? order?.['paymentCondition'] ?? '-'}</p>
                                                        </div>
                                                    )
                                                }
                                                <div className='item-body-labels-wrapper'>
                                                    <label>{t('myOrders.materialDescription')}</label>
                                                    <p>{order?.['materialDescription'] ?? '-'}</p>
                                                </div>
                                                <div className='item-body-labels-wrapper'>
                                                    <label>{t('myOrders.totalValue')}</label>
                                                    <p>{order?.['totalValue'] ? Number(order?.['totalValue']).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</p>
                                                </div>
                                            </div>
                                            <div className='item-body-labels justify-content-start flex-wrap' style={{ maxWidth: '60%' }}>
                                                <div className='item-body-labels-wrapper' style={{ marginRight: 50 }}>
                                                    <label>{t('myOrders.uniparOrderNumber')}</label>
                                                    <p>{order?.['intentNumber'] ?? '-'}</p>
                                                </div>
                                                <div className='item-body-labels-wrapper' style={{ marginRight: 50 }}>
                                                    <label>{t('myOrders.typeOfShipping')}</label>
                                                    <p>{order?.['incoterms'] ?? '-'}</p>
                                                </div>
                                                <div className='item-body-labels-wrapper'>
                                                    <label>{t('myOrders.orderItem')}</label>
                                                    <p>{isNaN(Number(order?.['orderItem'])) ? order?.['orderItem'] ?? '-' : Number(order?.['orderItem']) === 0 ? '-' : Number(order?.['orderItem'])}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='item-body-actions'>
                                            {(loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) &&
                                                (<>
                                                    <label>{t('myOrders.actions')}</label>
                                                    <div className='order-butons'>
                                                        <div className='button-icon' title='Recomprar' onClick={() => { rebuy(order?.materialCode, order) }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                                        {/* {order?.status > StatusIntent.CustomerPendency && <div className='button-icon' title='Baixar Doc.'><FontAwesomeIcon icon={faFileDownload} /></div>} */}
                                                        <div
                                                            className={(order?.status === StatusIntent.UniparValidation ||
                                                                order?.status === StatusIntent.OrderConfirmed) &&
                                                                isValidDate(order?.shipmentDate) ?
                                                                'button-icon' :
                                                                'button-icon-disabled'
                                                            }
                                                            title='Editar'
                                                            onClick={() => {
                                                                if ((order?.status === StatusIntent.UniparValidation ||
                                                                    order?.status === StatusIntent.OrderConfirmed) &&
                                                                    isValidDate(order?.shipmentDate)) {
                                                                    navigate(`/edit-order/${order?.orderIntentGroupId}?idOrder=${order?.id}`)
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                        </div>
                                                        <div
                                                            className={((order?.status === StatusIntent.UniparValidation || order?.status === StatusIntent.OrderConfirmed || order?.status === StatusIntent.EditSolicitation) && isValidDate(order?.shipmentDate)) ?
                                                                'button-icon' :
                                                                'button-icon-disabled'
                                                            }
                                                            title='Cancelar'
                                                            onClick={() => {
                                                                if ((order?.status === StatusIntent.UniparValidation ||
                                                                    order?.status === StatusIntent.OrderConfirmed ||
                                                                    order?.status === StatusIntent.EditSolicitation) && isValidDate(order?.shipmentDate)) {
                                                                    cancelOrder(order?.id)
                                                                }
                                                            }
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </div>
                                                        {order?.status == StatusIntent.CustomerPendency &&
                                                            <div className='button-icon' title={t('myOrders.approveRefuse')} onClick={() => navigate(`/approve/${order?.orderIntentGroupId}`)}><FontAwesomeIcon icon={faSquareCheck} /></div>
                                                        }
                                                    </div>
                                                </>)
                                            }
                                            {order?.status == StatusIntent.CustomerPendency ? (
                                                (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) &&
                                                <Button
                                                    variant='outlined'
                                                    onClick={() => {
                                                        navigate(`/approve/${order?.orderIntentGroupId}`);
                                                    }}
                                                >
                                                    {t('myOrders.changeReview')}
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant='outlined'
                                                    onClick={() => {
                                                        setOrder(order.id);
                                                        setShowModalOrderDetail(true);
                                                    }}
                                                >
                                                    {t('myOrders.checkDetails')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    {(order?.status > 2 && order?.status != 8 && order?.status != 9 && order?.status != 10) && <UniTracking
                                        actualStatus={order.status - 1}
                                        invoiceDate={order.invoiceDate}
                                        invoiceTime={order.invoiceTime}
                                        loadDate={order.loadDate}
                                        loadTime={order.loadingTimeDuration}
                                        vehicleArrivalDate={order.vehicleArrivalDate}
                                        vehicleArrivalTime={order.vehicleArrivalTime}
                                        vehicleEntryDate={order.vehicleEntryDate}
                                        vehicleEntryTime={order.vehicleEntryTime}
                                        initialWeight={(order.vehicleEntryWeight?.trim() === '0.000' ?? false) ? '' : order.vehicleEntryWeight?.replace('.', ',') ?? ''}
                                        finalWeight={(order?.departureWeight?.trim() === '0.000' ?? false) ? '' : order.departureWeight?.replace('.', ',') ?? ''}
                                    />}
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    );

    // FUNCTION TO SEARCH ORDERS WHEN ENTER KEY IS CLICKED
    const handleEnterKey = (event:any) => {
        if(event.key === 'Enter') {
            setShowOpened(false);
            getOrders();
        }
    }

    return (
        <>
            <div className='my-orders-wrapper'>
                <div className='my-orders-header w-100'>
                    <div className='my-orders-header-i'>
                        <img src={orders} alt='icone de novo pedido' />
                        {t('myOrders.myOrders')}
                    </div>
                    {(loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) && (
                        <Button variant='primary' href='/new-order'>
                            <img src={newOrder} alt='icone de novo pedido' />
                            {t('myOrders.newOrder')}
                        </Button>
                    )}
                </div>
                <div className='d-flex flex-column w-100'>

                    <div className='my-orders-search w-100'>
                        <div>
                            <UniInput
                                id='search'
                                placeholder={t('myOrders.searchFor')}
                                label={t('myOrders.search')}
                                type='text'
                                value={search?.search}
                                onChangeInput={(e) => {onChangeSearch(e)}}
                                enterKey={handleEnterKey}
                            />
                        </div>

                        <div style={{zIndex: 100}}>
                            <div style={{zIndex: 100}}>
                                <UniDatePickerRange
                                    loading={loading}
                                    periodValue={periodValue}
                                    onChange={e => onChangeDates(e)}
                                    options={datePickerOptions}
                                    onChangePeriod={(e) => onChangePeriod(e)}
                                    onHideChange={() => setShowRangeDates(false)}
                                />
                            </div>
                        </div>

                        <div>
                            <UniInput
                                id='materialCode'
                                label={t('myOrders.product')}
                                isSelect
                                defaultValue={''}
                                value={search?.materialCode ?? ''}
                                onChange={(e) => onChangeMaterialCode(e)}
                                disabled={loading}
                            >
                                <option value={''}>{t('myOrders.intervalAll')}</option>
                                {
                                    showMaterials?.map(p => (
                                        <option key={p.materialCode} value={p.materialCode}>{p.materialDescription}</option>
                                    ))
                                }
                            </UniInput>
                        </div>

                        <div className='d-flex flex-column'>
                            <Form.Label htmlFor='status'>Status</Form.Label>
                            <Multiselect
                                options={statusToFilter(t)}
                                onSelect={(e: any, s: any) => onChangeStatusMulti(e, s)}
                                onRemove={(e: any, s: any) => onRemoveStatusMulti(e, s)}
                                selectedValues={statusSelectedValues}
                                displayValue='name'
                                placeholder='Selecione'
                                disable={loading}
                                hidePlaceholder
                                showCheckbox 
                                showArrow
                            />
                        </div>
                        <div title={t('myOrders.search')} className='search-mobile' style={{ cursor: 'pointer', position: 'relative', top: '35px', height: 20 }} onClick={() => {sortField=''; setShowOpened(false); getOrders()}} ><FontAwesomeIcon style={{ color: '#FFFFFF' }} icon={faSearch}/></div>
                    </div>

                </div>

                <div className='d-flex flex-column w-100'></div>
                <div className='w-100 d-flex justify-content-center order-body'>
                    <div className='table-uni'>
                        {(enableCancel || enableExport) &&
                            <div className='buttons-selecteds'>
                                {(enableCancel && (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm))  && <div className='btn-selected' title={t('myOrders.cancelOrder')} onClick={() => openModalCancelation()}><FontAwesomeIcon icon={faXmark} /><span>{t('myOrders.cancelOrder')}</span></div>}
                                {enableExport && <div className='btn-selected' title={t('myOrders.exportOrders')} onClick={() => exportOrders()}>{loadingExport ? <Spinner></Spinner> : <FontAwesomeIcon icon={faFileDownload} />}<span>{t('myOrders.exportOrders')}</span></div>}
                            </div>
                        }
                        <div>
                            <div className='table-header'>
                                {
                                    mockDataTable.headers.map(header => (
                                        <div key={header.name} style={header.isStatus ? { width: 170 } : {}} className={header.isCheck ? `check-header` : `header`}>
                                            {
                                                header.isCheck ? (
                                                    <>
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            name={`default-check`}
                                                            checked={isChecked}
                                                            onChange={(e) => {
                                                                isChecked=!isChecked;
                                                                if (e.currentTarget.checked) {
                                                                    setAllcheck(true);
                                                                    ordersShipments?.forEach(o => o.checked = true);
                                                                    setOrders(ordersShipments);
                                                                } else {
                                                                    setAllcheck(false);
                                                                    ordersShipments?.forEach(o => o.checked = false);
                                                                    setOrders(ordersShipments);
                                                                }
                                                                setEnableCancel(ordersShipments!.filter(f => f.checked === true && (f.status === 1 || f.status === 3)).length > 0 && !ordersShipments!.some(f => f.checked === true && !isValidDate(f.shipmentDate)));
                                                                setEnableExport(ordersShipments!.filter(f => f.checked === true).length > 0);
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div onClick={()=>{sortList(header.property)}} style={{ cursor: `pointer` }}>
                                                            {header.name}
                                                            {header.name && (sortField !== header.property + '=asc' && sortField !== header.property + '=desc') && <FontAwesomeIcon icon={faSort} />}
                                                            {header.name && sortField === header.property + '=asc' && <FontAwesomeIcon icon={faSortAmountDown} />}
                                                            {header.name && sortField === header.property + '=desc' && <FontAwesomeIcon icon={faSortAmountUp} />}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            loading ? (
                                <Skeleton
                                    width={'100%'}
                                    height={400}
                                    duration={1}
                                    highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                                    baseColor={darkMode ? '#021717' : '#ebebeb'} />
                            ) :
                                (
                                    error ? (<p className='w-100 text-center p-2'>{t('myOrders.noResults')}</p>) :
                                    size?.width && size?.width < 770 ?
                                        <MobileGrid />
                                    :
                                        <Accordion as={'div'} defaultActiveKey={showOpened ? '0' : null}>
                                        {
                                            ordersShipments?.map((order: any, idx) => (
                                                <Accordion.Item as={'div'} eventKey={idx.toString()} key={idx.toString()}>
                                                    <div className='item-header'>
                                                        {
                                                            mockDataTable.headers.map((header, idxh) => {

                                                                if (header.property === 'check') {
                                                                    return (
                                                                        <Form.Check
                                                                            key={idxh.toString() + idx.toString()}
                                                                            type={'checkbox'}
                                                                            checked={order.checked}
                                                                            onChange={(e) => onChangeCheckedItem(e, idx)}
                                                                        />
                                                                    );
                                                                }


                                                                if (header.property === 'orderNumber') {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()}>{
                                                                            order?.['orderNumber'] ? (isNaN(Number(order?.['orderNumber'])) ? order?.['orderNumber'] : Number(order?.['orderNumber'])) : '-'
                                                                        }
                                                                        </div>
                                                                    )
                                                                }

                                                                if (header.property === 'customerOrderNumber') {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()} style={{ maxWidth: 125 }}>{
                                                                            order?.['customerOrderNumber'] ? (isNaN(Number(order?.['customerOrderNumber'])) ? order?.['customerOrderNumber'] : Number(order?.['customerOrderNumber'])) : '-'
                                                                        }
                                                                        </div>
                                                                    )
                                                                }

                                                                if (header.property === 'salesNumber') {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()} className='m-umd-r'> {
                                                                            order?.['salesNumber'] ? (isNaN(Number(order?.['salesNumber'])) ? order?.['salesNumber'] : Number(order?.['salesNumber'])) : '-'
                                                                        }
                                                                        </div>
                                                                    )
                                                                }

                                                                if (header.property === 'nfNumber') {
                                                                    return (
                                                                        <span key={idxh.toString() + idx.toString()}>
                                                                            {order?.['nfNumber'] ? (isNaN(Number(order?.['nfNumber'])) || isNaN(Number(order?.['nfSeries']))) ? order?.['nfNumber'] : Number(order?.['nfNumber']) + '-' + Number(order?.['nfSeries']) : '-'}
                                                                        </span>
                                                                    )
                                                                }

                                                                if (header.isStatus) {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()} style={{ minWidth: 'fit-content', width: 165 }}>
                                                                            <div className='table-status' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.bgColor ?? '#fff', minWidth: 145 }}>
                                                                                <span className='table-status-icon' style={{ backgroundColor: mockStatusStyle.filter(s => s.name === order.status)?.[0]?.color ?? '#fff' }}></span>
                                                                                <div className='w-100' style={{ fontSize: 12, minWidth: 'fit-content' }}>{getDescriptionStatusIntentLanguage(order?.[header.property] ?? '', i18n.language)}</div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (header.isDate) {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()}>{order?.[header.property] ? moment(order?.[header.property]).format('DD/MM/yyyy') : '-'}</div>
                                                                    )
                                                                }

                                                                if (header.isNumber) {
                                                                    return (
                                                                        <div key={idxh.toString() + idx.toString()}>{order?.[header.property] ? order?.[header.property]?.toString()?.replace('.', ',') + ' t' ?? '-' : '-'}</div>
                                                                    )
                                                                }

                                                                if (header.property === 'actions') {
                                                                    return (<AccordionButton key={idxh.toString() + idx.toString()} />);
                                                                }

                                                                return (
                                                                    <div key={idxh.toString() + idx.toString()} title={order?.[header.property] ?? '-'}>{order?.[header.property]?.length > 13 ? (order?.[header.property]?.slice(0, 12) + '...' ?? '-') : order?.[header.property] ?? '-'}</div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <Accordion.Body as={`div`}>
                                                        <div className='item-body'>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex w-100'>
                                                                    <div className='d-flex flex-column w-100'>
                                                                        <div className='item-body-labels'>
                                                                            <div className='item-body-labels-wrapper'>
                                                                                <label>{t('myOrders.uniparOrderNumber')}</label>
                                                                                <p>
                                                                                    {
                                                                                        order?.['salesNumber'] ? (isNaN(Number(order?.['salesNumber'])) ? order?.['salesNumber'] : Number(order?.['salesNumber'])) :
                                                                                            order?.['orderNumber'] ? (isNaN(Number(order?.['orderNumber'])) ? order?.['orderNumber'] : Number(order?.['orderNumber'])) : '-'
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className='item-body-labels-wrapper'>
                                                                                <label>{t('myOrders.materialNumber')}</label>
                                                                                <p>{order?.['materialCode'] ? Number(order?.['materialCode']) : '-'}</p>
                                                                            </div>
                                                                            {
                                                                                selectedCustomer.country != 'AR' && (
                                                                                    <div className='item-body-labels-wrapper'>
                                                                                        <label>{t('myOrders.paymentConditions')}</label>
                                                                                        <p>{order?.['paymentConditionDescription'] ?? order?.['paymentCondition'] ?? '-'}</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className='item-body-labels-wrapper'>
                                                                                <label>{t('myOrders.materialDescription')}</label>
                                                                                <p>{order?.['materialDescription'] ?? '-'}</p>
                                                                            </div>
                                                                            {/* <div className='item-body-labels-wrapper'>
                                                                        <label>Valor unitário</label>
                                                                        <p>R$ 6.255,00</p>
                                                                    </div>
                                                                    <div className='item-body-labels-wrapper'>
                                                                        <label>Subtotal</label>
                                                                        <p>R$ 18.765,00</p>
                                                                    </div> */}
                                                                            <div className='item-body-labels-wrapper'>
                                                                                <label>{t('myOrders.totalValue')}</label>
                                                                                <p>{order?.['totalValue'] ? Number(order?.['totalValue']).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='item-body-labels justify-content-start' style={{ maxWidth: '61%' }}>
                                                                            <div className='item-body-labels-wrapper' style={{ marginRight: 50 }}>
                                                                                <label>{t('myOrders.portalOrderNumber')}</label>
                                                                                <p>{order?.['intentNumber'] ?? '-'}</p>
                                                                            </div>
                                                                            <div className='item-body-labels-wrapper' style={{ marginRight: 50 }}>
                                                                                <label>{t('myOrders.typeOfShipping')}</label>
                                                                                <p>{order?.['incoterms'] ?? '-'}</p>
                                                                            </div>
                                                                            <div className='item-body-labels-wrapper' style={{ marginRight: 50 }}>
                                                                                <label>{t('myOrders.orderItem')}</label>
                                                                                <p>{isNaN(Number(order?.['orderItem'])) ? order?.['orderItem'] ?? '-' : Number(order?.['orderItem']) === 0 ? '-' : Number(order?.['orderItem'])}</p>
                                                                            </div>
                                                                            {selectedCustomer?.country === 'AR' &&
                                                                                <div className='item-body-labels-wrapper'>
                                                                                    <label>{t('myOrders.deposit')}</label>
                                                                                    <p>{isNaN(Number(order?.['deposit'])) ? order?.['deposit'] ?? '-' : Number(order?.['deposit']) === 0 ? '-' : Number(order?.['deposit'])}</p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='item-body-actions'>
                                                                        {((loginData.profile == Profile.Operator || loginData.profile == Profile.Adm)) &&
                                                                            (<>
                                                                                <label>{t('myOrders.actions')}</label>
                                                                                <div className='order-butons'>
                                                                                    <div className='button-icon' title={t('myOrders.rebuy')} onClick={() => { rebuy(order?.materialCode, order) }}><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                                                                    {/* {order?.status > StatusIntent.CustomerPendency && <div className='button-icon' title='Baixar Doc.'><FontAwesomeIcon icon={faFileDownload} /></div>} */}
                                                                                    <div
                                                                                        className={(order?.status === StatusIntent.UniparValidation ||
                                                                                            order?.status === StatusIntent.OrderConfirmed) &&
                                                                                            isValidDate(order?.shipmentDate) ?
                                                                                            'button-icon' :
                                                                                            'button-icon-disabled'
                                                                                        }
                                                                                        title='Editar'
                                                                                        onClick={() => {
                                                                                            if ((order?.status === StatusIntent.UniparValidation ||
                                                                                                order?.status === StatusIntent.OrderConfirmed) &&
                                                                                                isValidDate(order?.shipmentDate)) {
                                                                                                navigate(`/edit-order/${order?.orderIntentGroupId}?idOrder=${order?.id}`)
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                                                    </div>
                                                                                    <div
                                                                                        className={((order?.status === StatusIntent.UniparValidation || order?.status === StatusIntent.OrderConfirmed || order?.status === StatusIntent.EditSolicitation) && isValidDate(order?.shipmentDate)) ?
                                                                                            'button-icon' :
                                                                                            'button-icon-disabled'
                                                                                        }
                                                                                        title={t('myOrders.cancel')}
                                                                                        onClick={() => {
                                                                                            if ((order?.status === StatusIntent.UniparValidation ||
                                                                                                order?.status === StatusIntent.OrderConfirmed ||
                                                                                                order?.status === StatusIntent.EditSolicitation) && isValidDate(order?.shipmentDate)) {
                                                                                                cancelOrder(order?.id)
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faXmark} />
                                                                                    </div>
                                                                                    {order?.status == StatusIntent.CustomerPendency &&
                                                                                        <div className='button-icon' title={t('myOrders.approveRefuse')} onClick={() => navigate(`/approve/${order?.orderIntentGroupId}`)}><FontAwesomeIcon icon={faSquareCheck} /></div>
                                                                                    }
                                                                                </div>
                                                                            </>)
                                                                        }
                                                                        {order?.status == StatusIntent.CustomerPendency ? (
                                                                            (loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) &&
                                                                            <Button
                                                                                variant='outlined'
                                                                                onClick={() => {
                                                                                    navigate(`/approve/${order?.orderIntentGroupId}`);
                                                                                }}
                                                                            >
                                                                                {t('myOrders.changeReview')}
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                variant='outlined'
                                                                                onClick={() => {
                                                                                    setOrder(order.id);
                                                                                    setShowModalOrderDetail(true);
                                                                                }}
                                                                            >
                                                                                {t('myOrders.checkDetails')}
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {(order?.status > 2 && order?.status != 8 && order?.status != 9 && order?.status != 10 && order?.status != 11) && <UniTracking
                                                                    actualStatus={order.status - 1}
                                                                    invoiceDate={order.invoiceDate}
                                                                    invoiceTime={order.invoiceTime}
                                                                    loadDate={order.loadDate}
                                                                    loadTime={order.loadingTimeDuration}
                                                                    vehicleArrivalDate={order.vehicleArrivalDate}
                                                                    vehicleArrivalTime={order.vehicleArrivalTime}
                                                                    vehicleEntryDate={order.vehicleEntryDate}
                                                                    vehicleEntryTime={order.vehicleEntryTime}
                                                                    initialWeight={(order.vehicleEntryWeight?.trim() === '0.000' ?? false) ? '' : order.vehicleEntryWeight?.replace('.', ',') ?? ''}
                                                                    finalWeight={(order?.departureWeight?.trim() === '0.000' ?? false) ? '' : order.departureWeight?.replace('.', ',') ?? ''}
                                                                />}
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))
                                        }
                                        </Accordion>
                                )
                        }
                    </div>
                </div>
                {!error &&
                    <UniPagination totalItems={totalItems} totalPages={totalPages} changePage={(e: any) => setPage(e)} changeTake={(e: any) => { setPage(1); setTake(e) }} />
                }
            </div>
            <UniModalOrderDetail
                idOrderIntentShipment={orderId}
                title={t('myOrders.orderDetails')}
                message=''
                show={showModalOrderDetail}
                onHide={() => {
                    setShowModalOrderDetail(false);
                }}
            />

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('myOrders.unfortunately')}
                message=''
                iconColor='#EA2027'
                show={showRebuyError}
                onHide={() => {
                    setShowRebuyError(false)
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('myOrders.doNewOrder')}</p>
                <Button variant='primary' onClick={() => navigate('/new-order')}>{t('myOrders.newOrder')}</Button>
            </UniModal>

            <UniModal
                icon={''}
                isFontAwsome={true}
                title={t('myOrders.cancelOrder')}
                message=''
                iconColor='#EA2027'
                show={showModalCancelOrderIntent}
                onHide={() => {
                    setShowModalCancelOrderIntent(false)
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('myOrders.cancelSelectedOrder')}</p>
                {loading ?
                    (<Spinner style={{ color: '#027948', width: 25, height: 25 }} />)
                    :
                    (
                        <>
                            <Button style={{ backgroundColor: '#FF0000' }} variant='primary' onClick={() => cancelOrders()}>{t('myOrders.yes')}</Button>&nbsp;
                            <Button variant='primary' onClick={() => setShowModalCancelOrderIntent(false)}>{t('myOrders.no')}</Button>
                        </>
                    )
                }
            </UniModal>

            <UniModal
                icon={faCheckCircle}
                isFontAwsome
                title={t('myOrders.cancelOrder')}
                message=''
                iconColor='#EA2027'
                show={msgOkCancelOrderIntent !== ''}
                onHide={() => {
                    setMsgOkCancelOrderIntent('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{msgOkCancelOrderIntent}</p>
                <Button variant='primary' onClick={() => setMsgOkCancelOrderIntent('')}>Fechar</Button>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('myOrders.cancelOrder')}
                message=''
                iconColor='#EA2027'
                show={msgErrorCancelOrderIntent !== ''}
                onHide={() => {
                    setMsgErrorCancelOrderIntent('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{msgErrorCancelOrderIntent}</p>
                <Button variant='primary' onClick={() => setMsgErrorCancelOrderIntent('')}>Fechar</Button>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('myOrders.exportOrders')}
                message=''
                iconColor='#EA2027'
                show={exportError}
                onHide={() => {
                    setExportError(false)
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{t('myOrders.unableDownload')}</p>
                <Button variant='primary' onClick={() => setExportError(false)}>{t('myOrders.close')}</Button>
            </UniModal>

            <UniModal
                icon={faCalendarXmark}
                isFontAwsome
                title={t('financialScreen.labelInvalidDate')}
                message=''
                iconColor='#EA2027'
                show={errorDate != ''}
                onHide={() => {
                    setErrorDate('')
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <p>{errorDate}</p>
            </UniModal>
        </>
    );
}

export default MyOrders;