import { error } from "console";
import callApi, { BaseResponseDto } from "./baseRequest";

const route = 'notification';

interface Notification {
    message: string,
    lang: string, 
    isVisualized: boolean
};

export interface NotificationData {
    qntNotVisualized: number,
    notificationList: Notifications[]
}

interface Notifications {
    notifications: Notification[],
    notificationId: string,
    date: string,
}

export function getNotifications(email: string, take: string = '5'): Promise<BaseResponseDto<NotificationData>> {
    const result: BaseResponseDto<NotificationData> = {
        data: {
            notificationList: [],
            qntNotVisualized: 0
        },
        error: '',
        success: false
    };

    const response = callApi('GET', `${route}/${email}?take=${take}`)
    .then(resp => {
        if (resp) {
            result.data.qntNotVisualized = resp?.data?.qntNotVisualized;
            resp?.data?.notificationList?.map((r: any) => {
                result.data.notificationList.push(r);
            });

            result.success = true;
        }
        //else {
        //    result.error = 'Erro ao buscar notificações'
        //}

        return result;
    })
    .catch(err => {
        result.error = err;

        return result;
    })

    return response;
};

export function putNotifications(email: string): Promise<BaseResponseDto<any>> {
    const result: BaseResponseDto<any> = {
        data: {},
        error: '',
        success: false
    };

    const response = callApi('PUT', `${route}/${email}`)
    .then(resp => {
        result.success = true;
        return result;
    })
    .catch(err => {
        result.error = err;

        return result;
    })

    return response;
};