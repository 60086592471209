import { link } from "fs";
import callApi from "./baseRequest";

const route = 'useful-links';

export interface Link {
    id: string,
    titleBR: string,
    titleES: string,
    titleEN: string,
    descriptionBR: string,
    descriptionES: string,
    descriptionEN: string,
    link: string,
    isActive: boolean
};

export interface BankAccount {
    agency: string,
    bank: string,
    companyDocument: string,
    companyName: string,
    createdAt: Date,
    createdBy: string,
    currentAccount: string,
    enabled: boolean,
    id: string,
    modifiedAt: Date,
    modifiedBy: string,
    pixKey: string,
    pixKeyType: number,
    usefulLinksGroupEntityId: string
};

export interface LinkGroup {
    id: string,
    descriptionBR: string,
    descriptionES: string,
    descriptionEN: string,
    flag: string,
    links: Link[],
    bankAccounts: BankAccount[],
    isActive: boolean
};

interface LinkResponse {
    links: Link[],
    success: boolean,
    error: string
};

interface LinkGroupResponse {
    linkGroups: LinkGroup[],
    success: boolean,
    error: string
};

export function getUsefulLinksGroup():Promise<LinkGroupResponse> {
    const result: LinkGroupResponse = {
        linkGroups: [],
        success: false,
        error: ''
    };

    const response = callApi('GET', `${route}/get-all-links-group`)
    .then(resp => {
        if (resp.data.linkGroup.length > 0 ?? false) {
            resp?.data.linkGroup.map((r: any) => {
                let linkGroup: LinkGroup = {
                    id: r.id,
                    descriptionBR: r.descriptionBR,
                    descriptionES: r.descriptionES,
                    descriptionEN: r.descriptionEN,
                    flag: r.flag,
                    links: r.links,
                    bankAccounts: r.bankAccounts,
                    isActive: r.isActive
                };

                result.linkGroups.push(linkGroup);
            });

            result.success = true;
        }
        else {
            result.error = 'Erro ao buscar Links';
        }

        return result;
    })
    .catch(err => {
        result.error = err;

        return result;
    })

    return response;
}
