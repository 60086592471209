import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './UniTracking.scss';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { StatusIntent } from '../../types/orderIntent';
import { getDescriptionStatusIntent, getDescriptionStatusIntentLanguage } from '../../helpers/enums';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useUserStore } from '../../store/user';
import i18n from '../../i18n';

type UniTrackingProps = {
    actualStatus: number,
    vehicleArrivalDate: string,
    vehicleArrivalTime: string,
    vehicleEntryDate: string,
    vehicleEntryTime: string,
    invoiceDate: string,
    invoiceTime: string,
    loadDate: string,
    loadTime: string,
    initialWeight?: string,
    finalWeight?: string,
}

const UniTracking = ({ initialWeight, finalWeight, actualStatus, ...props }: UniTrackingProps) => {
    const {selectedCustomer} = useUserStore();
    const status = [
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.UniparValidation, i18n.language.toUpperCase()),
            color: '#2C99F7',
            bgColor: '#ECF6FF',
            dateProp: ''
        },
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.CustomerPendency, i18n.language.toUpperCase()),
            bgColor: '#FFEFEA',
            color: '#FF6636',
            dateProp: ''
        },
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.OrderConfirmed, i18n.language.toUpperCase()),
            bgColor: '#FFE7FD',
            color: '#FF35EB',
            dateProp: 'vehicleArrivalDate'
        },
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.Arrived, i18n.language.toUpperCase()),
            bgColor: '#FFF6C7',
            color: '#DFBC07',
            dateProp: 'vehicleArrivalDate'
        },
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.EntryWeight, i18n.language.toUpperCase()),
            bgColor: '#F4EDFF',
            color: '#9E7BD5',
            dateProp: 'vehicleEntryDate'
        },                           
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.LoadAndSealed, i18n.language.toUpperCase()),
            bgColor: '#CFFFFC',
            color: '#0BE3D6',
            dateProp: 'loadDate'
        },        
        {
            name: getDescriptionStatusIntentLanguage(StatusIntent.Invoiced, i18n.language.toUpperCase()),
            bgColor: '#EEFFF2',
            color: '#30C10C',
            dateProp: 'invoiceDate'
        } 
    ];

    return (
        <div className='tracking'>
            <div className='track-status' 
                style={actualStatus > 0 ? 
                    {background: `linear-gradient(90deg, rgba(0,247,32,1) ${(actualStatus - 2) * 25}%, rgba(68,255,0,1) 0%, #ACB9C9 0%)`} 
                    : {background: '#ACB9C9'}
                }
            >
                {
                    status.map((st, idx) => {
                        if (selectedCustomer.country === 'AR' && idx === 3) return <></>;

                        if (idx > 1) {
                            return (
                                <div 
                                    key={idx.toString()}
                                    className={idx === actualStatus ? 
                                    'tracking-item active' : 
                                    idx < actualStatus ? 'tracking-item old' :
                                    'tracking-item'}
                                    style={idx === actualStatus ? {backgroundColor: st.color} : {}}
                                >
                                    <FontAwesomeIcon icon={faTruck}/>
                                </div>
                            );
                        }
                    })
                }
            </div>
            <div className='status-names'>
                {
                    status.map((st, idx) => {
                        if (selectedCustomer.country === 'AR' && idx === 3) return <></>;

                        if (idx > 1) {
                        moment.locale('pt-br');
                        return (
                            <div key={idx.toString()} className={'idx-'+idx.toString()}>
                                <span className='date'>{
                                    idx === 2 && props.vehicleArrivalDate ? 
                                        moment(props.vehicleArrivalDate?.toString()).format('DD MMM YYYY').toLocaleUpperCase() + `, ` + moment(props.vehicleArrivalTime?.toString() ?? '0000', 'hhmm').format('HH:mm')
                                    : 
                                    idx === 3 && props.vehicleArrivalDate ? 
                                        moment(props.vehicleArrivalDate?.toString()).format('DD MMM YYYY').toLocaleUpperCase() + `, ` + moment(props.vehicleArrivalTime?.toString() ?? '0000', 'hhmm').format('HH:mm')
                                    :
                                    idx === 4 && props.vehicleEntryDate ? 
                                        moment(props.vehicleEntryDate?.toString()).format('DD MMM YYYY').toLocaleUpperCase() + `, ` + moment(props.vehicleEntryTime?.toString() ?? '0000', 'hhmm').format('HH:mm')
                                    :
                                    idx === 5 && props.loadDate ?
                                        moment(props.loadDate?.toString()).format('DD MMM YYYY').toLocaleUpperCase() + `, ` + moment(props.loadTime?.toString() ?? '0000', 'hhmm').format('HH:mm')
                                    :
                                    idx === 6 && props.invoiceDate  ? 
                                        moment(props.invoiceDate?.toString()).format('DD MMM YYYY').toLocaleUpperCase() + `, ` + moment(props.invoiceTime?.toString() ?? '0000', 'hhmm').format('HH:mm')
                                    :
                                    ''}
                                </span> <br/>
                                {st.name}
                                <br/> 
                                {
                                    selectedCustomer.country != 'AR' && (<span>{(idx === 4 && initialWeight) ? 'Peso entrada: ' + initialWeight : (idx === 6 && finalWeight) ? (i18n.language.toUpperCase() === 'ES' ? 'Peso salida: ' : 'Peso saída: ') + finalWeight : ''}</span>)
                                }                                
                            </div> 
                        )}
                    })
                }
            </div>
        </div>
    );
}

export default UniTracking;