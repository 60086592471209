import React, { useEffect, useState } from 'react';
import UniHeader from './components/UniHeader/UniHeader';
import './globals.scss';
import './styles/ProtectedApp.scss';
import {
  useNavigate
} from "react-router-dom";
import UniFooter from './components/UniFooter/UniFooter';
import { useTranslation } from 'react-i18next';
import { useUserStore } from './store/user';
import moment from 'moment';

function ProtectedApp(props: any) {
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const { lng, darkMode } = useUserStore();

  useEffect(() => {    
    if (lng && lng != i18n.language) {
      i18n.changeLanguage(lng);
    }
  }, [])

  useEffect(() => {
    const tkn = localStorage.getItem('tkn');

    if (!tkn) {
      setIsLogged(false);
      return navigate('/');
    } 

    setIsLogged(true);
  }, [isLogged])

  return (
    <div className={darkMode ? 'dark' : ''}>
      <UniHeader activeRoute={props.activeRoute}/> 
      <div className='wrapper'>
        {props.children}
      </div>
      <UniFooter />
    </div>
  );
}

export default ProtectedApp;
