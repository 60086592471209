import callApi, { BaseResponse } from "./baseRequest"

interface SetRequest {
    customerId: string,
    userId: string,
    npsScore?: number,
    csatScore?: number,
    comments?: string,
    rejectedAnswer: boolean,
    looseAnswer: boolean
}

interface SetResponse extends BaseResponse {

}

export function set(request: SetRequest): Promise<SetResponse> {
    const result: SetResponse = {
        error: '',
        errorPT: '',
        errorEN: '',
        errorES: '',
        success: false        
    };

    const response = callApi('POST', `customers-survey`, request)
    .then(response => {
        result.success = true;
        return result;
    }).catch(err => {
        result.error = err?.error;
        result.errorPT = err?.errorPT;
        result.errorES = err?.errorES;
        result.errorEN = err?.errorEN;

        return result;
    });

    return response;
}