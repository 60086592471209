import moment from 'moment';
import './UniWidgetTonProduct.scss';
import { Form } from 'react-bootstrap';
import { useUserStore } from '../../store/user';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TonsPerMonthResponse, getTonsPerMonthByIssuer } from '../../services/customerService';
import { useTranslation } from 'react-i18next';


type UniWidgetTonProduct = {
    materialCode?: string | null,
    materialDescripton?: string | null,
    issuer?: string | null
}
const UniWidgetTonProduct = ({materialCode, issuer, materialDescripton}: UniWidgetTonProduct) =>  {
    const currentYear = new Date();
    const { darkMode } = useUserStore();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TonsPerMonthResponse>();
    const [year, setYear] = useState<number>(currentYear.getFullYear());
    const {t} = useTranslation();

    const months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];

    useEffect(() => {
        getTonsPerMonthByIssuer(issuer!, materialCode!, year!).then(monthData => {
            setData(monthData.data);           
        })
    }, [year, materialCode, issuer])

    const returnColorMonth = (month: number) => {
        const currentDate = new Date();
        return (currentDate.getMonth() + 1) === month ?
            darkMode ? '#01FF3D' : '#0F8A38' : !darkMode ? '#01FF3D' : '#0F8A38';
    }

    return (
        <>
            <div className='ton-product-wrapper'>
                <div className='d-flex justify-content-between ton-wrapper-filter'>
                    <div className='d-flex ton-product-title'>
                        <p>{t('homeWidgets.tonMonthTitle')} <span>|  </span></p>
                        <p className='product-title'>{materialDescripton ?? '-'}</p>
                    </div>
                    <div className='select-year'>
                        <Form.Select
                        onChange={(e) => {setYear(Number.parseInt(e.target.value))}}
                        >                            
                            {new Date().getFullYear() !== 2023 && <option>2023</option> }
                            {new Date().getFullYear() === 2023 && <option selected>2023</option> }

                            {new Date().getFullYear() !== 2024 && <option>2024</option> }
                            {new Date().getFullYear() === 2024 && <option selected>2024</option> }

                            {new Date().getFullYear() !== 2025 && <option>2025</option> }
                            {new Date().getFullYear() === 2025 && <option selected>2025</option> }


                            {new Date().getFullYear() !== 2026 && <option>2026</option> }
                            {new Date().getFullYear() === 2026 && <option selected>2026</option> }

                        </Form.Select>
                    </div>
                </div>

                {
                    loading ? (
                        <Skeleton
                            width={460}
                            height={120}
                            style={{ marginTop: 15 }}
                            highlightColor={darkMode ? '#6c6b6b' : '#f5f5f5'}
                            baseColor={darkMode ? '#021717' : '#ebebeb'} />
                    ) : (
                        <div className='d-flex justify-content-center product-graph-wrapper'>



                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.january ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.january.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.january! ?? 0) ? 0.5 * data?.january! ?? 0 : 0,
                                        backgroundColor: data?.january! ? returnColorMonth(data?.january!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Jan')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.february ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.february.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.february! ?? 0) ? 0.5 * data?.february! ?? 0 : 0,
                                        backgroundColor: data?.february! ? returnColorMonth(data?.february!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Fev')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.march ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.march.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.march! ?? 0) ? 0.5 * data?.march! ?? 0 : 0,
                                        backgroundColor: data?.march! ? returnColorMonth(data?.march!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Mar')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.april ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.april.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.april! ?? 0) ? 0.5 * data?.april! ?? 0 : 0,
                                        backgroundColor: data?.april! ? returnColorMonth(data?.april!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Abr')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.may ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.may.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.may! ?? 0) ? 0.5 * data?.may! ?? 0 : 0,
                                        backgroundColor: data?.may! ? returnColorMonth(data?.may!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Mai')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.june ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.june.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.june! ?? 0) ? 0.5 * data?.june! ?? 0 : 0,
                                        backgroundColor: data?.june! ? returnColorMonth(data?.june!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Jun')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.july ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.july.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.july! ?? 0) ? 0.5 * data?.july! ?? 0 : 0,
                                        backgroundColor: data?.july! ? returnColorMonth(data?.july!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Jul')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.august ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.august.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.august! ?? 0) ? 0.5 * data?.august! ?? 0 : 0,
                                        backgroundColor: data?.august! ? returnColorMonth(data?.august!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Ago')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.september ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.september.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.september! ?? 0) ? 0.5 * data?.september! ?? 0 : 0,
                                        backgroundColor: data?.september! ? returnColorMonth(data?.september!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Set')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.october ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.october.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.october! ?? 0) ? 0.5 * data?.october! ?? 0 : 0,
                                        backgroundColor: data?.october! ? returnColorMonth(data?.october!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Out')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.november ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.november.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.november! ?? 0) ? 0.5 * data?.november! ?? 0 : 0,
                                        backgroundColor: data?.november! ? returnColorMonth(data?.november!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Nov')}</p>
                            </div>
                            <div className='ton-graph-wrapper'>
                                <p
                                    className={new Date().getMonth() + 1 === data?.december ?
                                        'ton currentMonth' :
                                        'ton'
                                    }>
                                    {data?.december.toFixed(0) ?? ''}
                                </p>
                                <div
                                    className='ton-graph'
                                    style={{
                                        height: !isNaN(0.5 * data?.december! ?? 0) ? 0.5 * data?.december! ?? 0 : 0,
                                        backgroundColor: data?.december! ? returnColorMonth(data?.december!) : darkMode ? '#091B1B' : '#F2EFFF'
                                    }}>
                                </div>
                                <p>{t('homeWidgets.Dez')}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default UniWidgetTonProduct;