import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserStore } from "../store/user";
import { Profile, getCustomersByIssuer } from "../services/userService";
import { UniToken } from "../services/baseRequest";
import jwt from 'jwt-decode';
import { decryptByte } from "../helpers/encrypt";

const AccessAs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {loginData, changeLoginData, changeSelectedCustomer} = useUserStore();
    const navigate = useNavigate();

    useEffect(() => {
        let tkn = '';
        let email = '';
        let issuer = '';

        if (searchParams.get('t')) {
            tkn = searchParams.get('t') ?? '';
            tkn = tkn.replace('UN', 'ey');
        }

        if (searchParams.get('e')) {
            email = searchParams.get('e') ?? '';
        }

        email = decryptByte(email);

        if (searchParams.get('i')) {
            issuer = searchParams.get('i') ?? '';
        }

        issuer = decryptByte(issuer);

        if (!tkn) {
            navigate(`/`);
            return;
        }

        const decodedTkn = jwt<UniToken>(tkn);

        if (decodedTkn?.name?.toUpperCase() !== `ACCESS_AS`) {
            navigate(`/`);
            return;
        }

        localStorage.setItem('tkn', tkn);
        getCustomersByIssuer(issuer).then(r => {
            if (r.data?.customerId ?? false) {
                changeLoginData({
                    profile: Profile.Operator,
                    email: email,
                    name: `Backoffice`,
                    id: ``,
                    customers: [{
                        country: r.data.country,
                        cpfCnpj: r.data.cpfCnpj,
                        customerId: r.data.customerId,
                        issuer: r.data.issuer,
                        name: r.data.name,
                        enabledOrderAfterHour: r.data.enabledOrderAfterHour,
                        searchTerm: r.data.searchTerm
                    }],
                    showCustomerSurvey: loginData.showCustomerSurvey
                });

                changeSelectedCustomer(
                    {
                        country: r.data.country,
                        cpfCnpj: r.data.cpfCnpj,
                        customerId: r.data.customerId,
                        issuer: r.data.issuer,
                        name: r.data.name,
                        enabledOrderAfterHour: r.data.enabledOrderAfterHour,
                        searchTerm: r.data.searchTerm
                    }
                );

                navigate(`/home`);
                return;

            } else {
                navigate(`/`);
                return;
            }
        }).catch(() => {
            navigate(`/`);
            return;
        })

    }, []);

    return (
        <div>
            <Spinner />
        </div>
    )
};

export default AccessAs;