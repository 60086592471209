import { useEffect, useState } from 'react';
import './UniRecaptcha.scss';
import { getRecaptcha } from '../../services/userService';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const UniRecaptcha = () => {
    const urlSite = process.env.REACT_APP_RECAPTCHA_SITE_URL;
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;    
    const [loading, setLoading] = useState(false);
    const [passed, setPassed] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
          const loadScriptByURL = (id: any, url: any, callback: any) => {
          const isScriptExist = document.getElementById(id);            

          if (!isScriptExist) 
          {
            var script = document.createElement("script");
            script.type = 'text/javascript';
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
                      
          }

          document.getElementById('btnEnviar')?.setAttribute('disabled', 'true');
          document.getElementById('btnEnviar')?.setAttribute('style', 'display:none');

          document.getElementById('login')?.setAttribute('disabled', 'true');
          document.getElementById('login')?.setAttribute('style', 'display:none');

          document.getElementById('senha')?.setAttribute('disabled', 'true');
          document.getElementById('senha')?.setAttribute('style', 'display:none');            

          document.getElementById('visiblePass')?.setAttribute('style', 'display:none');  

        }
       
        loadScriptByURL("recaptcha-key", urlSite, () => {});
      }, []);    

      const handleOnClick = (e: any) => {
        e.preventDefault();
        setLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token: any) => {
            submitData(token);
          });
        });
      }      

      const submitData = async (token: any) => {
        const response = await getRecaptcha({token});

        if (response.success) {
            setLoading(false);
            setPassed(response.data.passed);

            if(!response.data.passed)
            { 
                document.getElementById('btnEnviar')?.setAttribute('disabled', 'true');
                document.getElementById('btnEnviar')?.setAttribute('style', 'display: none');   
                
                document.getElementById('login')?.setAttribute('disabled', 'true');
                document.getElementById('login')?.setAttribute('style', 'display: none');     
                
                document.getElementById('senha')?.setAttribute('disabled', 'true');
                document.getElementById('senha')?.setAttribute('style', 'display: none');            
                
                document.getElementById('visiblePass')?.setAttribute('style', 'display:none');                                
            }
            else
            {
                document.getElementById('btnEnviar')?.removeAttribute('disabled');
                document.getElementById('btnEnviar')?.setAttribute('style', 'display: block');

                document.getElementById('login')?.removeAttribute('disabled');
                document.getElementById('login')?.setAttribute('style', 'display: block');

                document.getElementById('senha')?.removeAttribute('disabled');
                document.getElementById('senha')?.setAttribute('style', 'display: block');

                document.getElementById('visiblePass')?.setAttribute('style', 'display:block');                

                document.getElementById('btnRecaptcha')?.setAttribute('style', 'display: none');                
            }
        }
        else {
          setLoading(false);
        }
        return;        
      }        

    return (
        <div>
          <Button id='btnRecaptcha' onClick={handleOnClick} disabled={loading} className='w-100 align-items-center justify-content-center'>
            {loading ? <Spinner /> : t('login.robotBtn')} 
            <FontAwesomeIcon icon={faRobot} style={{marginLeft: 10}}/>
          </Button>
        </div>       
    )
}

export default UniRecaptcha;