import logoPreto from '../../assets/icons/logo_preto.svg';
import logoStrong from '../../assets/icons/logo-strong.svg';
import facebook from '../../assets/icons/facebook-square.svg';
import instagram from '../../assets/icons/instagram.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import './UniFooter.scss';
import { useUserStore } from '../../store/user';
import UniModalCustomerSurvey from '../UniModalCustomerSurvey/UniModalCustomerSurvey';
import UniModalNotice from '../UniModalNotice/UniModalNotice';

const UniFooter = () => {
    const { darkMode, lng, loginData, changeLoginData } = useUserStore();

    const showCustomersSurveyModal = () => {
        changeLoginData({
            ...loginData,
            showCustomerSurvey: true,
            showCustomerSurveyLoose: true
        });
    }

    return (
        <footer className='d-flex footer'>
            <UniModalCustomerSurvey />
            <UniModalNotice />
            <div className='d-flex align-items-center justify-content-between'>
                <div className='logo'>
                    <img src={darkMode ? logoStrong : logoPreto} width={96} alt='logo Unipar' /> 
                    <div className='icons d-flex justify-content-start'>
                        <a href='https://www.instagram.com/grupounipar/' target='_blank'><img src={instagram} width={25} alt='logo instagram'/></a>
                        <a href='https://www.facebook.com/grupounipar' target='_blank'><img src={facebook} width={25} alt='logo facebook'/></a>
                        <a href='https://www.linkedin.com/company/grupo-unipar/' target='_blank'><img src={linkedin} width={25} alt='logo linkedin'/></a>
                    </div>               
                </div>
                <div>
                    <p className='p-questions'>
                        {
                            lng === 'pt' ? (
                                <>
                                    <b>Dúvidas</b><br />
                                    Telefone: +55 (11) 3704-4200<br />
                                    PVC - <a href='mailto:atendimentopvc@unipar.com'>atendimentopvc@unipar.com</a> <br/>
                                    Químicos - <a href='mailto:servicoaocliente@unipar.com'>servicoaocliente@unipar.com</a>
                                </>
                            ) :
                            lng === 'en' ? (
                                <>
                                        <b>Questions</b><br />
                                    Telephone: +55 (11) 3704-4200<br />
                                    PVC - <a href='mailto:atendimentopvc@unipar.com'>atendimentopvc@unipar.com</a> <br/>
                                    Químicos - <a href='mailto:servicoaocliente@unipar.com'>servicoaocliente@unipar.com</a> 
                                </>
                            ) :
                            (
                                <>
                                            <b>Dudas</b> <br /> 
                                    Teléfono: +54 (11) 5283-9300<br/>
                                    Argentina - <a href='mailto:atencionalcliente@unipar.com'>atencionalcliente@unipar.com</a> 
                                </>
                            )
                        }                    
                    </p>
                </div>
                <div className='feedback-wrapper'>
                    <span className='feedback'>
                        {
                            lng === 'pt' ? (
                                <>
                                    <b>Como está sendo sua experiência?</b>
                                    <a className='feedback-button' href='#' onClick={() => showCustomersSurveyModal()}>Deixe-nos um feedback</a>
                                </>
                            ) :
                                lng === 'en' ? (
                                    <>
                                        <b>How has your experience been?</b>
                                        <a className='feedback-button' href='#' onClick={() => showCustomersSurveyModal()}>Leave us feedback</a>
                                    </>
                                ) :
                                    (
                                        <>
                                            <b>¿Cómo ha sido tu experiencia?</b>
                                            <a className='feedback-button' href='#' onClick={() => showCustomersSurveyModal()}>Déjanos tu opinión</a>
                                        </>
                                    )
                        }
                    </span>                    
                </div>
            </div>
            <span className='copyright'><a href='https://unipar.com/politica-de-privacidade/' target='_blank'>{lng === 'en' ? 'Privacy Policies' : lng === 'pt' ? 'Políticas de Privacidade' : lng === 'es' ? 'Políticas de privacidad' : 'Políticas de Privacidade'}</a> | Copyright © 2023 UNIPAR</span>
        </footer>
    )
}

export default UniFooter;