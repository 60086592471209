import { useEffect, useState } from "react";
import UniModal from "../UniModal/UniModal"
import { useUserStore } from "../../store/user";
import { Notice, getUnread, readNotice } from "../../services/noticeService";
import { Button } from "react-bootstrap";
import './UniModalNotice.scss';

type ControlNoticesState = {
    readCount: number,
    totalCount: number
}
const UniModalNotice = () => {
    const { darkMode, lng, loginData, changeLoginData } = useUserStore();
    const [notices, setNotices] = useState<Notice[]>();
    const [currentNotice, setCurrentNotice] = useState<Notice>();
    const [controlView, setControlView] = useState<ControlNoticesState>({totalCount: 0, readCount: 0});
    
    useEffect(() => {
        if (loginData.hasNotices && !loginData.showCustomerSurvey) {
            getUnread(loginData.id)
            .then(resp => {
                if (resp?.success ?? false) {
                    setNotices(resp.data);
                    setControlView({totalCount: resp.data?.length ?? 0, readCount: 0})
                    setCurrentNotice(resp.data?.[0]);
                }
            })
        }
    }, [loginData.hasNotices, loginData.showCustomerSurvey])
    
    const read = (noticeId: string | undefined) => {
        if (noticeId) readNotice(noticeId, loginData.id);

        const newControlView: ControlNoticesState = {
            totalCount: controlView.totalCount, 
            readCount: (controlView.readCount + 1)
        }
        setControlView({...newControlView});

        if (finishNotices(newControlView)) {
            loadCurrentNotice(newControlView.readCount);
        }
    }

    const loadCurrentNotice = (id: number) => {
        if (notices?.[id] ?? false) {
            setCurrentNotice(notices?.[id]);
        }
    }

    const finishNotices = (newControlView: ControlNoticesState): boolean => {
        if (!newControlView) return false;

        if (newControlView.readCount === newControlView.totalCount){
            changeLoginData({...loginData, hasNotices: false});
            setCurrentNotice(undefined);
        }
        return true;
    }

    const getCurrentTextByLng = (type: `TITLE` | `BODY`) => {
        let text = ``;
        switch (type) {
            case `TITLE`:            
            text = lng == `pt` ? currentNotice?.titlePt ?? `` : 
                lng == `es` ? currentNotice?.titleEs ?? `` : 
                lng == `en` ? currentNotice?.titleEn ?? `` : 
                ``
                break;
    
            case `BODY`:
                text = lng == `pt` ? currentNotice?.bodyPt ?? `` : 
                lng == `es` ? currentNotice?.bodyEs ?? `` : 
                lng == `en` ? currentNotice?.bodyEn ?? `` : 
                ``
                break;
        }

        return text;
    }

    return (
        <UniModal
            icon={undefined}
            message=''
            isHtmlContent
            title={getCurrentTextByLng(`TITLE`)}
            onHide={() => {read(undefined)}}
            show={currentNotice != undefined}
        >
            <div dangerouslySetInnerHTML={{__html: getCurrentTextByLng(`BODY`)}}/>
            <div className="d-flex align-items-center w-100 mt-3 justify-content-end">
                <Button variant='secondary' onClick={() => read(currentNotice?.id)}>Marcar como lido</Button>
            </div>
        </UniModal>
    )
}

export default UniModalNotice;