import { Form } from "react-bootstrap";

interface UniBankTicketProps {
    cnpjCpf?: string;
    salesOrg: string
}

const UniBankTicket = (props: UniBankTicketProps) => {

	const carregabrw = async (event: any) => {
		window.open('','BLOQUETO', 'toolbar=yes,menubar=yes,resizable=yes,status=no,scrollbars=yes,left=150,top=150,width=600,height=430');
		window.close();
	}    
    
    return (
        <Form name="formBankTicket" id="formBankTicket" action="https://redirecionaitau.beesby.com/redirecionaItau.asp" method="Post"
        onSubmit={carregabrw}  target="BLOQUETO">
            <input type="hidden" id="CnpjEmpresa" name="CnpjEmpresa" value={props.salesOrg}/>
            <input type="hidden" id="CpfCnpj" name="CpfCnpj" value={props.cnpjCpf}/>
            <input type="submit" name="Bloqueto" id="Bloqueto" value="Segunda Via Bloqueto" style={{display:'none'}}/>
        </Form>
    )
}

export default UniBankTicket;