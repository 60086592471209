import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import '../styles/Home.scss';
import { useTranslation } from "react-i18next";
import widgetNewOrder from '../assets/icons/widget-new-order.svg'
import UniWidgetStatusTon from '../components/UniWidgetStatusTon/UniWidgetStatusTon';
import truck from '../assets/icons/truck.png';
import icTruck from '../assets/icons/ic-truck.svg';
import UniWidgetMyOrders from '../components/UniWidgetMyOrders/UniWidgetMyOrders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import UniWidgetProgram from '../components/UniWidgetProgram/UniWidgetProgram';
import UniWidgetTonProduct from '../components/UniWidgetTonProduct/UniWidgetTonProduct';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUserStore } from '../store/user';
import { TabMaterial, getMaterialsByIssuerCurrentDate } from '../services/materialService';
import { Profile } from '../services/userService';
    
const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedCustomer, loginData } = useUserStore();
    const [materials, setMaterials] = useState<TabMaterial[]>();
    const [material, setMaterial] = useState<TabMaterial | null>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectedCustomer) {
            setLoading(true);
            getMaterialsByIssuerCurrentDate(selectedCustomer.issuer)
                .then(m => {
                    if (m.success) {
                        setMaterials(m.materials);
                        setMaterial(m.materials?.[0] ?? null)
                    }
                })
                .catch(e => console.log(e))
                .finally(() => setLoading(false))
        }
    }, [selectedCustomer]);
    
    return (
        <div className='home-wrapper'>
            <div className="home-content">
                <div className='tab-wrapper'>
                    <img src={icTruck} width={38} alt="Caminhão com pedido" />
                    <div className='scroll-tab'>
                        <Tabs
                            transition={false}
                            className="mb-3"
                            onSelect={(selectedKey) => setMaterial(materials?.filter(m => m.materialCode === selectedKey)?.[0])}
                        >
                            {materials ?
                                materials?.map((mp, idx) => (
                                    <Tab eventKey={mp.materialCode} title={mp.materialDescription.length > 19 ? mp.materialDescription.slice(0, 18) + '...' : mp.materialDescription} key={mp.materialCode} />
                                )) :
                                !materials && !loading ? (
                                    <div className='w-100 d-flex align-items-center justify-content-center text-white'>
                                        {t('homeWidgets.notfoundOrders')}
                                    </div>
                                )
                                    : (
                                        <Spinner />
                                    )
                            }
                        </Tabs>
                    </div>
                </div>

                <UniWidgetStatusTon materialCode={material?.materialCode} materialDescription={material?.materialDescription} />

                <div className='d-flex widget-wrapper'>
                    <div className='widget-wrapper-orders'>
                        {(loginData.profile == Profile.Operator || loginData.profile == Profile.Adm) &&
                            (<>
                                <div className='widget-new-order'>
                                    <div style={{ marginRight: 35 }}>
                                        <img src={truck} className='img-truck-btn' alt='caminhão verde' width={125} />
                                    </div>
                                    <div className='widget-text'>
                                        <p className='widget-title'>{t('homeWidgets.newOrderTitle')}</p>
                                        <p className='widget-subtitle'>{t('homeWidgets.newOrderSubTitle')}</p>
                                    </div>
                                    <div className='widget-btn'>
                                        <Button variant='outline-primary' href='/new-order'>{t('homeWidgets.newOrderBtn')}</Button>
                                    </div>

                                    <img src={widgetNewOrder} className='background-icon' alt='icone novo pedido' />
                                </div>
                            </>)}

                        <div className='widget-my-orders'>
                            <div className='widget-my-orders-text'>
                                <div className='widget-my-orders-title'>{t('homeWidgets.myOrderWidgetTitle')} <span className='separetor'>|</span> <span>{material?.materialDescription}</span></div>
                                <div className='widget-my-orders-action'>
                                    <FontAwesomeIcon icon={faArrowUp} />
                                    <p onClick={() => navigate(`/my-orders?materialCode=${material?.materialCode ?? ''}`)}>{t('homeWidgets.showAllMyOrders')} </p>
                                </div>
                            </div>
                            <UniWidgetMyOrders materialCode={material?.materialCode} />
                        </div>
                    </div>

                    <div className='widget-wrapper-program'>
                        <div className='widget-program'>
                            <UniWidgetProgram materialCode={material?.materialCode} materialDescription={material?.materialDescription} />
                        </div>
                        <div className='widget-orders-month'>
                            <UniWidgetTonProduct materialCode={material?.materialCode} materialDescripton={material?.materialDescription} issuer={selectedCustomer.issuer} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;