export enum StatusIntent {
    UniparValidation = 1,
    CustomerPendency = 2,
    OrderConfirmed = 3,
    Arrived = 4,
    EntryWeight = 5,
    LoadAndSealed = 6,
    Invoiced = 7,
    Recused = 8,
    Canceled = 9,
    EditSolicitation = 10,
    ReviewCancelation = 11
}