import { Breadcrumb, Dropdown, Form, NavDropdown, Overlay, Toast, Tooltip } from 'react-bootstrap';
import logoPreto from '../../assets/icons/logo_preto.svg';
import logoStrong from '../../assets/icons/logo-strong.svg';
import iconNotification from '../../assets/icons/notifications.svg';
import { useTranslation } from "react-i18next";
import pt from '../../assets/icons/pt-br.svg';
import es from '../../assets/icons/es.svg';
import en from '../../assets/icons/en.svg';
import './UniHeader.scss';
import UniMenu from '../UniMenu/UniMenu';
import { Customer, useUserStore } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faSun } from '@fortawesome/free-regular-svg-icons';
import { faMoon } from '@fortawesome/free-regular-svg-icons';
import { faBell, faClose, faExclamation, faIndustry } from '@fortawesome/free-solid-svg-icons';
import UniModalUser from '../UniModalUser/UniModalUser';
import React, { useRef, useState, useEffect } from 'react';
import uniLogo from '../../assets/icons/logo-pos.svg';
import { NotificationData, getNotifications, putNotifications } from '../../services/notificationService';
import moment from 'moment';
import { Profile } from '../../services/userService';
import { maskCNPJCPF } from '../../helpers/utils';

interface UniHeaderProps {
    activeRoute?: string;
}

const UniHeader = (props: UniHeaderProps) => {
    const [showUserModal, setShowUserModal] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showDrop, setShowDrop] = useState(false);
    const [notifications, setNotifications] = useState<NotificationData | undefined>();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const { loginData, changeLng, darkMode, changeDarkMode, changeSelectedCustomer, selectedCustomer } = useUserStore();
    const changeLang = (lngCode: string) => {
        changeLng(lngCode);
        i18n.changeLanguage(lngCode);
        moment.locale(lngCode);

        document.getElementById('drop')?.click();
    }

    const doLogout = () => {
        localStorage.clear();
        navigate('/');
    }

    const changeTheme = () => {
        changeDarkMode(!darkMode);
    }

    const closeUserModal = () => {
        setShowUserModal(false);
    }

    const changeCustomer = (customer: Customer) => {
        changeSelectedCustomer(customer);
        window.location.reload();
    }

    useEffect(() => {
        getNotificationsHeader();
    }, [window.location.pathname]);

    useEffect(() => {
        if (showNotifications && (notifications?.notificationList?.some(n => n.notifications?.some(no => !no.isVisualized) ?? false) ?? false)) {
            putNotifications(loginData.email)
                .then(r => {
                    if (r.success) getNotificationsHeader();
                })
                .catch(e => console.log(e))
        }
    }, [showNotifications]);

    const getNotificationsHeader = () => {
        getNotifications(loginData.email).then(r => setNotifications(r?.data)).catch(e => console.log(e));
    }

    window.addEventListener('click', function (e) {
        if (e !== null) {
            const el = e.target;
            if (el instanceof Node && !document.getElementById('notifications')?.contains(el)) {
                setShowNotifications(false);
            }
        }
    });

    return (
        <div style={{ height: '100%' }} className='group-menu'>
            <UniMenu activeRoute={props.activeRoute} />

            <header>
                <div>
                    <div>
                        <img src={darkMode ? logoStrong : logoPreto} width={96} className='me-lg-5' alt='logo Unipar' />
                    </div>

                    <div className='divider'></div>

                    <Breadcrumb>
                        <Breadcrumb.Item href="#">{t('welcome')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className='icons'>
                    <div className='notification' id='notifications'>
                        <FontAwesomeIcon
                            icon={faBell}
                            className='me-4 icon-notification'
                            onClick={() => setShowNotifications(!showNotifications)}
                        />
                        {(notifications?.qntNotVisualized ?? 0) > 0 && (<span className='badge' onClick={() => setShowNotifications(!showNotifications)}>{notifications?.qntNotVisualized}</span>)}
                        <div className='notification-body' style={showNotifications ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }}>
                            <div className='notification-header'>
                                <p>{t('notifications')}</p>
                                <FontAwesomeIcon icon={faClose} onClick={() => setShowNotifications(false)} />
                            </div>
                            {
                                notifications?.notificationList?.map((notification, idx) => (
                                    <div className='notification-text-wrapper' key={idx}>
                                        <div className='notification-icon' style={!notification?.notifications?.[0]?.isVisualized ? { backgroundColor: '#FF6636' } : {}}>
                                            <FontAwesomeIcon icon={faExclamation} />
                                        </div>
                                        <div className='notification-text'>
                                            <p className='notification-msg'>{notification?.notifications?.filter(n => n.lang === i18n.language.toUpperCase())?.[0]?.message ?? '-'}</p>
                                            <p className='notification-date'><FontAwesomeIcon icon={faClock} />{moment(notification?.date ?? new Date()).format('DD/MM/yyyy HH:mm')}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='dropdown-m'>
                        <NavDropdown title={<>
                                            {loginData.name ?? 'Cliente'} <br/>
                                            <span className='selected-customer'>{maskCNPJCPF(selectedCustomer.cpfCnpj) + '|' + selectedCustomer.searchTerm}</span>
                                           </>}>
                            <div className='f-check'>
                                <div className='lng' onClick={() => changeLang('pt')}>
                                    <img src={pt} alt='pt-br' />
                                    <span className={i18n.language == 'pt' ? 'lng-check' : ''}>PT</span>
                                </div>
                                <div className='lng' onClick={() => changeLang('es')}>
                                    <img src={es} alt='es' />
                                    <span className={i18n.language == 'es' ? 'lng-check' : ''}>ES</span>
                                </div>
                                <div className='lng' onClick={() => changeLang('en')}>
                                    <img src={en} alt='en' />
                                    <span className={i18n.language == 'en' ? 'lng-check' : ''}>EN</span>
                                </div>
                            </div>
                            <NavDropdown.Divider />
                            {
                                loginData?.customers?.map(customer => (
                                    <div className={customer.issuer === selectedCustomer?.issuer ? 'customers customers-active' : 'customers'} key={customer.issuer} onClick={() => changeCustomer(customer)}>
                                        <div>
                                            <FontAwesomeIcon icon={faIndustry} width={14} />
                                            <p title={customer.searchTerm ||customer.name}>{Number(customer.issuer)} - {(customer.searchTerm || customer.name).length > 10 ? (customer.searchTerm || customer.name).slice(0, 9) + '...' : (customer.searchTerm || customer.name)}</p>
                                        </div>
                                        <p style={{fontSize: 12}}>{maskCNPJCPF(customer.cpfCnpj)}</p>
                                    </div>
                                ))
                            }
                            <NavDropdown.Divider />
                            {loginData.profile == Profile.Adm &&
                                (<>
                                    <NavDropdown.Item onClick={() => setShowUserModal(true)}>
                                        {t('users')}
                                    </NavDropdown.Item>
                                </>)
                            }
                            <NavDropdown.Item onClick={() => doLogout()}>
                                {t('logout')}
                            </NavDropdown.Item>
                        </NavDropdown>

                        <div className='theme-buttons'>
                            <FontAwesomeIcon className={darkMode ? 'sun' : 'sun active'} icon={faSun} width={15} onClick={() => changeTheme()} />
                            <FontAwesomeIcon className={darkMode ? 'moon active' : 'moon'} icon={faMoon} width={15} onClick={() => changeTheme()} />
                        </div>
                    </div>
                </div>
            </header>

            <UniModalUser show={showUserModal} onHide={() => closeUserModal()} />
        </div>
    )
};

export default UniHeader;