import { Accordion, AccordionButton, Button, Form, Spinner } from 'react-bootstrap';
import { Delivery, Product } from '../../types/newOrder';
import tuckLocation from '../../assets/icons/truck-location.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import i18n from '../../i18n';
import weightTonGreen from '../../assets/icons/weight-hand-ton-green.svg';
import { ChangeEvent, useState } from 'react';
import { FormControlElement } from '../UniInput/UniInput';
import '../../styles/NewOrder.scss';
import moment from 'moment';
import { t } from 'i18next';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

type UniNewOrderResumeProps = {
    weightTon: string,
    deliveries: Delivery[],
    selectedProduct: Product | undefined,
    sendOrders: () => void,
    setObs: (e: ChangeEvent<FormControlElement>) => void,
    setShowResume: () => void,
    loading: boolean,
    incoterm?: string
}

const UniNewOrderResume = ({deliveries, weightTon, selectedProduct, sendOrders, setObs, setShowResume, loading, incoterm}: UniNewOrderResumeProps) => {
    const [comment, setComment] = useState('');
    
    return (
        <>
            <div className='new-order-resume-content'>
                <div className='resume-header'>
                    <p className='title-resume'>{t('newOrderScreen.labelOrderItem')}</p>

                    <div className='new-order-snacker'>
                        <div>
                            <img src={weightTon}/>
                            <div className='new-order-snacker-text'>
                                <span>{t('newOrderScreen.labelTotalVolume')}</span>
                                <p>
                                    {
                                        deliveries?.length ?
                                            deliveries?.map(d => d.weight ?? 0).reduce((acc, cur) => acc + cur, 0) + ' t'
                                        :   '-'
                                    }
                                </p>
                            </div>
                        </div>
                        <div>
                            <img src={tuckLocation}/>
                            <div className='new-order-snacker-text'>
                                <span>{t('newOrderScreen.labelOrder')}</span>
                                <p>
                                    {
                                        deliveries?.length ?
                                            deliveries?.map(d => d.itens?.length ?? 0).reduce((acc, cur) => acc + cur, 0) 
                                        :   '-'
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='resume-body'>
                    {
                        deliveries?.map((d, key) => (
                            d.itens?.map((item, idx) => {
                                return (
                                    <Accordion key={key + idx}>
                                        <Accordion.Item eventKey='1'>
                                            <div className='d-flex header-acc-resume'>
                                                <div>
                                                    <p title={selectedProduct?.description}>{(selectedProduct?.description?.length != undefined && selectedProduct?.description?.length > 28) ? (selectedProduct?.description?.slice(0, 29) + '...' ?? '-') : selectedProduct?.description ?? '-'}</p>
                                                </div>
                                                <div className='d-flex'> 
                                                    <FontAwesomeIcon icon={faCalendarDays} />
                                                    <p>{incoterm === 'FOB' || incoterm === 'FCA' ? t('newOrderScreen.labelLoadResume') : t('newOrderScreen.labelDeliveryResume') } {moment(d.date)?.format('DD/MM/yyyy') ?? '-'}</p>
                                                </div>
                                                {
                                                    item.transp?.name &&
                                                    <div className='d-flex header-trans-resume'> 
                                                        <FontAwesomeIcon icon={faTruck} title='Transportador'/>
                                                        <p title={item.transp?.name ?? ''}>{(item.transp?.name?.length != undefined && item.transp?.name?.length > 21) ? (item.transp?.name?.slice(0, 22) + '...' ?? '-') : item.transp?.name}</p>
                                                    </div>
                                                }
                                                <div className='d-flex'>
                                                    <img src={weightTonGreen} loading='lazy'/>
                                                    <p>{item?.quantity} t</p>
                                                </div>
                                                <AccordionButton />
                                            </div>
                                            <Accordion.Body>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelQtyResume')}</label>
                                                        <p>{item?.quantity} t</p>
                                                    </div>
                                                    
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelShippingCompany')}</label>
                                                        <p title={item.transp?.name ?? ''}>{(item.transp?.name?.length != undefined && item.transp?.name?.length > 16) ? (item.transp?.name?.slice(0, 22) + '...' ?? '-') : item.transp?.name}</p>
                                                    </div>
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelVehicleType')}</label>
                                                        <p>{d.veichleType.descriptions?.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? '-'}</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelOrderNumber')}</label>
                                                        <p>{item.orderNumber ?? '-'}</p>
                                                    </div>
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelMaterialNumber')}</label>
                                                        <p>{item.materialNumber ?? '-'}</p>
                                                    </div>
                                                    <div className='body-item'>
                                                        <label>{t('newOrderScreen.labelOrdemItem')}</label>
                                                        <p>{item.orderItem ?? '-'}</p>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            })
                        ))
                    }
                    <div className='resume-obs'>
                        <Form.Group>
                            <Form.Label>{t('newOrderScreen.labelAddObservation')}</Form.Label>
                            <Form.Control as="textarea" rows={4} value={comment} disabled={loading} onChange={(ev) => {
                                setComment(ev.target?.value ?? '');
                                setObs(ev);
                            }}/>
                        </Form.Group>
                    </div>

                    <div className='buttons-send-order-resume'>
                        <Button variant='outline' disabled={loading} onClick={setShowResume}>{t('newOrderScreen.labelEditOrder')}</Button>
                        <Button onClick={sendOrders} disabled={loading}>{!loading ? t('newOrderScreen.labelFinalizeOrder') : <Spinner style={{color: '#fff'}}/>}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UniNewOrderResume;