import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './UniWidgetStatusTon.scss';
import { Chart } from "react-google-charts";
import { faArrowRight, faArrowTrendDown, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import widgetGraph from '../../assets/icons/widget-graph.svg';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '../../store/user';
import Skeleton from 'react-loading-skeleton';
import { useEffect, useState } from 'react';
import { StatusQuatityResponse, getStatusQuantityTon } from '../../services/orderService';
import { BaseResponseDto } from '../../services/baseRequest';
import { faFaceFrown, faFaceFrownOpen, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
type UniWidgetStatusTonProps = {
    materialCode?: string,
    materialDescription?: string
};

const UniWidgetStatusTon = ({materialCode, materialDescription} : UniWidgetStatusTonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { darkMode, selectedCustomer } = useUserStore();
    const [quantities, setQuantities] = useState<StatusQuatityResponse | any>();
    const [loading, setLoading] = useState(false);
    const [notFound, setNotfoud] = useState(false);

    const mockStatus = [
        {
            name: 'Em análise Unipar',
            color: '#2C99F7',
            property: 'uniparValidationQuantity',
            status: 1,
            ton: 25,
            translateId: 'statusTonAnalise',
        },
        {
            name: 'Pendente Cliente',
            color: '#FF6636',
            property: 'customerPendencyQuantity',
            status: 2,
            ton: 25,
            translateId: 'statusTonPendente',
        },
        {
            name: 'Chegou no pátio',
            color: '#DFBC07',
            property: 'arrivedQuantity',
            status: 4,
            ton: 40,
            translateId: 'statusTonChegou',
        },
        {
            name: 'Entrada na fábrica',
            color: '#9E7BD5',
            property: 'entryWeightQuantity',
            status: 5,
            ton: 12,
            translateId: 'statusTonEntrou',
        },
        {
            name: 'Faturados',
            color: '#30C10C',
            property: 'invoicedQuantity',
            status: 7,
            ton: 150,
            translateId: 'statusTonFaturado',
        }
    ];

    useEffect(() => {
        setLoading(true);
        getStatusQuantityTon(selectedCustomer.issuer, materialCode)
        .then(r => {
            if (r?.success ?? false) {
                setQuantities(r.data);
            }
        })
        .catch(e => console.log(e))
        .finally(() => setLoading(false))
    }, [materialCode])

    const createCustomHTMLContent = (name: string, color: string, percent: number) => {
        return `<div style='background-color: #091B1B; border: 2px solid ${color}; border-radius: 10px; min-width: 100px; 
                        max-width: fit-content; display: flex; align-items: center; justify-content: start; padding: 8px 5px'>
                    <div style='background-color: ${color}; width: 8px; border-radius: 10px; height: 8px; margin-right: 3px'></div>
                    <span style='color: white; min-width: fit-content'>${name}</span>
                    <b><span style='color: ${color}; margin-left: 5px'>${percent}%</span></b>
                </div>`;
    }

    const calculatePercent = (ton: number, totalTons: number) => {
        // let totalTons = 0;
        // mockStatus.map(s => totalTons += s.ton);

        const totalResult = (ton * 100)/totalTons;
        return {percent: isNaN(Math.round(totalResult)) ? 0 : Math.round(totalResult), totalTON: totalTons };
    }

    if (loading) {
        return (
            <Skeleton 
                height={180} 
                width={'100%'} 
                style={{marginTop: 30}}
                highlightColor={ darkMode ? '#6c6b6b' : '#f5f5f5'} 
                baseColor={darkMode? '#021717' : '#ebebeb'}/>
        )
    }

    return (
        <div className='widget-status-ton'>
            <div className='widget-data'>
                <div className='widget-title'>
                    <span className='principal-title'>{t('homeWidgets.graphTitleOrder')}</span> {t('homeWidgets.graphTitle')} <span>| </span> {materialDescription}
                </div>
                <div>
                    {
                        !notFound && (
                            <div className='widget-line'>
                                {
                                    mockStatus.map(s => {
                                        if ((s.status === 4 && selectedCustomer.country === 'AR') || s.status === 7) {
                                            return <></>;
                                        } else {
                                            return (
                                                <div key={s.name} style={{ background: s.color }} className='ball-status'>
                                                    <div style={{ background: s.color }}></div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        )
                    }
                </div>
                {
                    notFound && !loading ? (
                        <div className='w-100 d-flex align-items-center justify-content-center m-3'>
                            <FontAwesomeIcon icon={faFaceFrownOpen} style={{height: 18, marginRight: 10}}/>
                            Não encontramos pedido para você no mês atual
                        </div>
                    ) : (
                        <div className='widget-data-graph'>
                            {
                                mockStatus.map(s => {
                                    if ((s.status === 4 && selectedCustomer.country === 'AR') || s.status === 7) {
                                        return <></>;
                                    } else {
                                        return (
                                            <div key={s.name} className='graph-item d-flex'>
                                                <div>
                                                    <div className='item-text' onClick={() => navigate(`/my-orders?materialCode=${materialCode}&status=${s.status}`)}>
                                                        <span>{t(`homeWidgets.${s.translateId}`) ?? s.name}</span>
                                                        <FontAwesomeIcon icon={faArrowRight} className='item-arrow'/>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <p style={{marginBottom: 5}}>
                                                            {quantities?.[s.property] ? quantities?.[s.property]?.toString()?.replace('.', ',') ?? 0 : 0} <span>t</span> 
                                                        </p>
                                                        {s.color === '#FF6636' && (<div className='warning-status'>!</div>)}
                                                    </div>
                
                                                    <div className='d-flex'>
                                                        {/* <div>
                                                            <span style={{color: s.color, fontWeight: 700}}>{quantities ? calculatePercent(quantities?.[s.property], quantities?.allQuantity).percent : 0}%</span>
                                                        </div> */}
                                                        {/* <Chart
                                                            style={{marginTop: -12}}
                                                            chartType="PieChart"
                                                            data={[
                                                                ["", ""],
                                                                ["", quantities?.[s.property]],
                                                                ["", quantities?.allQuantity - quantities?.[s.property]],
                                                            ]}
                                                            legendToggle={false}
                                                            legend_toggle={false}
                                                            width={100}
                                                            options={{
                                                                pieHole: 0.7,
                                                                height: 60,
                                                                is3D: false,
                                                                legend: 'none',
                                                                backgroundColor: 'transparent',
                                                                colors: [s.color, '#ACB9C9'],
                                                                tooltip: {
                                                                    enableInteractivity: false,
                                                                    trigger:'none'
                                                                },
                                                                pieSliceBorderColor: 'none',
                                                                pieSliceText: 'none',
                                                            }}
                                                        /> */}
                                                    </div>
                                                </div>
                
                                                <div className='widget-divider'></div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                }
            </div>
            <div className='widget-compile'>
                <Chart
                    chartType="PieChart"
                    data={[
                        ["", "", { role: "tooltip", type: "string", p: {html: true}}],
                        ...mockStatus.map(s => [s.color, quantities?.[s.property], createCustomHTMLContent(s.name, s.color, calculatePercent(quantities?.[s.property], quantities?.allQuantity).percent)])
                    ]}
                    chartEvents={[
                        {
                            eventName: "ready",
                            callback: ({ chartWrapper, google }) => {
                                google.visualization.events.addListener(chartWrapper, 'select', e => {
                                    const data = chartWrapper.getDataTable();
                                    const selection = e.getSelection();
                                });
                            }
                        }
                    ]}
                    legendToggle={false}
                    legend_toggle={false}
                    width={170}
                    height={'100%'}
                    options={{
                        width:180,
                        pieHole: 0.8,
                        is3D: false,
                        legend: 'none',
                        backgroundColor: 'transparent',
                        colors: mockStatus.map(s => s.color),
                        tooltip: {
                            enableInteractivity: false,
                            isHtml: true
                        },
                        pieSliceText: 'none',
                        pieSliceBorderColor: 'transparent',
                    }}
                />
                <div className='compile-divider'></div>
                <div className='compile-text'>
                    <p>{t('homeWidgets.graphTotal')}</p>
                    <div className='compile-text-ton'>
                        <p>{isNaN(Number(quantities?.invoicedQuantity)) ? quantities?.invoicedQuantity ?? 0 : Number(quantities?.invoicedQuantity)?.toFixed(2)?.toString()?.replace('.', ',') ?? 0} <span>t</span></p>
                        {/* {
                            quantities?.percentInvoiced >= 0 ? (
                                <div className='up-percent'>
                                    <FontAwesomeIcon icon={faArrowTrendUp} width={10}/>
                                    <p>{isNaN(Number(quantities?.percentInvoiced)) ? quantities?.percentInvoiced ?? 0 : Number(quantities?.percentInvoiced).toFixed(2)}%</p>
                                </div>
                            ) : (
                                <div className='down-percent'>
                                    <FontAwesomeIcon icon={faArrowTrendDown} width={10}/>
                                    <p>{isNaN(Number(quantities?.percentInvoiced)) ? quantities?.percentInvoiced ?? 0 : Number(quantities?.percentInvoiced).toFixed(2)}%</p>
                                </div>
                            )
                        } */}
                    </div>

                    <p>{t('homeWidgets.graphBilled')}</p>
                    {/* <p className='compile-text-percent'>{quantities ? calculatePercent(quantities?.invoicedQuantity, quantities?.allQuantity)?.percent : 0}%</p> */}
                </div>
                <div className='back-compile'>
                    <img src={widgetGraph} alt='backgroun green'/>
                </div>
            </div>
        </div>
    )
}

export default UniWidgetStatusTon;