import { Modal, Spinner } from 'react-bootstrap';
import { useUserStore } from "../../store/user";
import { useEffect, useState } from 'react';
import './UniModalOrderDetail.scss';
import { getOrderIntentShipmentDetail, ResponseOrderIntentShipmentDetail } from "../../services/orderService";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { centers } from '../../helpers/intl/centers';

interface UniModalOrderDetailProps {
    idOrderIntentShipment: string,
    show: boolean,
    message: string,
    title: string,
    onHide: () => void,
    closeButton?: boolean
}

const UniModalOrderDetail = (props: UniModalOrderDetailProps) => {
    const { darkMode } = useUserStore();
    const [loading, setLoading] = useState(false);
    const [orderDetail, setOrderDetail] = useState<ResponseOrderIntentShipmentDetail>();
    const { t } = useTranslation();
    
    useEffect(() => {
        if (props.show) {
            setLoading(true);

            getOrderIntentShipmentDetail(props.idOrderIntentShipment)
                .then(response => {
                    setOrderDetail(response.data);                
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false))
        }
    }, [props.show]);

    return (
        <Modal 
            show={props.show} 
            onHide={props.onHide}
            className={ 'modal-detail-order'} 
            contentClassName={darkMode ? 'dark-modal-content' : ''}                        
            >
            <Modal.Header closeButton={props.closeButton ?? true}>
                <div className='header-body'>
                    <Modal.Title className='mt-3'>
                        {props.title}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {props.message}
                <div className='divider'></div>
                {
                loading ? (
                    <Spinner style={{color: '#027948', width: 25, height: 25}}/>
                        ) : 
                        (       
                <div className='body'>
                    <div className='left-body'>
                        {orderDetail?.orderNumber &&
                        (<div className='m-bottom'>
                            <div className='label-modal'>
                                {t('myOrdersDetails.orderNumber')}
                            </div>   
                            <div className='order-number'>
                                <b>{isNaN(Number(orderDetail?.orderNumber)) ? orderDetail?.orderNumber : Number(orderDetail?.orderNumber)}</b>
                            </div>
                        </div>)}  
                        {orderDetail?.intentNumber &&
                            (<div className='m-bottom'>
                                <div className='label-modal'>
                                {t('myOrdersDetails.orderInternalNumber')}
                                </div>   
                                <div className='order-number'>
                                    <b>{orderDetail?.intentNumber}</b>
                                </div>
                            </div>)                                           
                        } 
                        {orderDetail?.address ? 
                        (<div className='m-bottom'>
                            <div className='label-modal'>
                                {t('myOrdersDetails.deliveryAt')}
                            </div>   
                            <div>
                                <b>                            
                                    {orderDetail?.address}<br/>
                                    {orderDetail?.neighborhood}<br/>
                                    {orderDetail?.city} - {orderDetail?.region}<br/>
                                    {orderDetail?.postalCode}<br/>
                                </b>
                            </div>
                        </div>) : 
                        (
                            <div className='m-bottom'>
                                <div className='label-modal'>
                                    {t('myOrdersDetails.deliveryAt')}
                                </div>   
                                <div>
                                    <b>                            
                                        {orderDetail?.receiverAddress}<br/>
                                    </b>
                                </div>
                            </div> 
                        )                    
                        }
                    </div>
                    <div className='right-body w-100'>
                        <div className='d-flex justify-content-start gap-5 w-100 m-bottom'>
                            {orderDetail?.center &&
                            (<div>
                                <div className='label-modal'>
                                    {t('myOrdersDetails.center')}
                                </div>
                                <div>
                                    <b>{centers.filter(center => center.centerCode === orderDetail?.center)?.[0]?.centerDescription ?? orderDetail?.center}</b>
                                </div>
                            </div>)
                            }
                            {orderDetail?.transporterName &&
                            (<div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.transporter')}
                                </div>     
                                <div>
                                    <b>{orderDetail?.transporterName}</b>
                                </div>                          
                            </div>)
                            }               
                            {orderDetail?.shipmentQuantity &&
                            (<div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.quantity')}
                                </div>   
                                <div>
                                    <b>{orderDetail?.shipmentQuantity ? orderDetail?.shipmentQuantity?.toString().replace('.', ',') ?? '-' : '-'}</b>
                                </div>                           
                            </div>)}              
                            {/* {orderDetail?.shipmentDate &&                      
                            (<div>
                                <div className='label-modal'>
                                    PEDIDO
                                </div>      
                                <div>
                                    <b>{moment(orderDetail?.shipmentDate).format('DD/MM/YYYY')}</b>
                                </div>                                             
                            </div>)
                            }       */}
                        </div>
                        <div className='d-flex justify-content-start gap-5 w-100 m-bottom'>
                            {orderDetail?.vehicleType &&          
                            (
                            <div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.vehicle')}
                                </div>    
                                <div>
                                    <b>{orderDetail?.vehicleName}</b>
                                </div>
                            </div>
                            )} 
                            {orderDetail?.receiverName &&
                            (<div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.receiver')}
                                </div>    
                                <div>
                                    <b>{orderDetail?.receiverName}</b>
                                </div>                                                               
                            </div>)}
                            {orderDetail?.transportNumber &&
                            (<div>
                                <div className='label-modal'>
                                    DT
                                </div>       
                                <div>
                                    <b>{Number(orderDetail?.transportNumber)}</b>
                                </div>                               
                            </div>)}
                            <div>
                                <div className='label-modal'>
                                    {t('myOrdersDetails.date')}
                                </div>  
                                <div>
                                    <b>{moment(orderDetail?.createdAt).format('DD/MM/YYYY')}</b>
                                </div>  
                            </div>
                        </div>
                        <div className='d-flex justify-content-start gap-5 w-100 m-bottom'>
                            {orderDetail?.nfNumber &&                      
                            (<div>
                                <div className='label-modal'>
                                    NFe
                                </div>    
                                <div>
                                    <b>{isNaN(Number(orderDetail?.nfNumber)) ? orderDetail?.nfNumber : Number(orderDetail?.nfNumber)}-{orderDetail?.nfSeries}</b>
                                </div>                   
                            </div>)
                            }     
                            {orderDetail?.shipmentNumber &&
                            (<div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.shipping')}
                                </div>     
                                <div>
                                    <b>{Number(orderDetail?.shipmentNumber)}</b>
                                </div>                      
                            </div>)}
                            {orderDetail?.truckPlate &&
                            (<div>
                                <div className='label-modal'>
                                {t('myOrdersDetails.plate')}
                                </div>   
                                <div>
                                    <b>{orderDetail?.truckPlate}</b>
                                </div> 
                            </div>)                    
                            }                         
                            {orderDetail?.orderNumber && !isNaN(Number(orderDetail?.orderNumber)) &&
                            (<div>
                                <div className='label-modal'>
                                    OV
                                </div>
                                <div>
                                    <b>{isNaN(Number(orderDetail?.orderNumber)) ? orderDetail?.orderNumber : Number(orderDetail?.orderNumber)}</b>
                                </div>                               
                            </div>)}
                        </div>
                    </div>
                </div>)
            }                                                                                                              
            </Modal.Body>
        </Modal>    
    );
}

export default UniModalOrderDetail;