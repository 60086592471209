import  Accordion  from 'react-bootstrap/Accordion';
import { Link, BankAccount } from '../../services/usefulLinks';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight, faLink, faBank } from '@fortawesome/free-solid-svg-icons';
import './UniUsefulInformationCard.scss';
import { getDescriptionPixKeyType } from '../../helpers/enums';

type UniUsefulInformationCardProps ={
    links?:  Link[],
    accountBanks?: BankAccount[],
    country?: string,
    flag?: any
};

const UniUsefulInformationCard = (props: UniUsefulInformationCardProps) =>{

    const { i18n, t } = useTranslation();

    const setLinkTitleByLanguage = (link:Link) =>{
        if (i18n.language === 'en')
            return link.titleEN;
        else if(i18n.language === 'es')
            return link.titleES;
        else
            return link.titleBR;
    }

    return (
        <div className='wrapper-card'>
            <div className='flag-area' style={{ backgroundImage: props.country === 'Argentina'? `linear-gradient(-269.99deg, #508ABC 9.35%, rgba(117, 174, 224, 0.22) 99.99%), url(${props.flag})`: `linear-gradient(-270deg, #065E39 20%, rgba(13, 196, 119, 0.34) 100%), url(${props.flag})` }}>
                <span className='flag-header'>{props.country}</span>
            </div>
            <div className='wrapper-accordions'>
                <Accordion alwaysOpen>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>
                            <span className='icons'><FontAwesomeIcon icon={faLink}/></span>
                            <span className='header'>{t('utilLinksScreen.labelLinkUtil')}</span>

                        </Accordion.Header>
                        <Accordion.Body>
                                <div className='links'>
                                    {props.links?.map((link) => (
                                        <div className='link' key={link.id}>
                                            <a href={link.link} target='_blank' rel="noreferrer">{setLinkTitleByLanguage(link)}<FontAwesomeIcon icon={faSquareArrowUpRight}/></a>
                                        </div>
                                        ))
                                    }
                                </div>
                            </Accordion.Body>
                    </Accordion.Item>
                    {props.accountBanks?.map((bank) =>(
                        <Accordion.Item eventKey={bank.id}>
                            <Accordion.Header>
                                <span className='icons'><FontAwesomeIcon icon={faBank}/></span>
                                <span className='header'>{t('usefulInformation.bankingData')} {bank.bank}</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <div className='detail-title'>{t('usefulInformation.registrantionInformation')}</div>
                                    <div className='detail'>{bank.companyName.toUpperCase()}</div>
                                    <div><span className='detail'>{props.country === 'Argentina' ? 'CUIT': 'CNPJ'}: </span> {bank.companyDocument}</div>
                                </p>
                                <p>
                                    <div className='detail-title'>{t('usefulInformation.bankDetail')}</div>
                                    <div><span className='detail'>{t('usefulInformation.bank')}: </span> {bank.bank}</div>
                                    <div><span className='detail'>{props.country === 'Argentina' ? 'CBU': t('usefulInformation.agency')}: </span> {bank.agency}</div>
                                    <div><span className='detail'>{t('usefulInformation.checkingAccount')}: </span>{bank.currentAccount}</div>
                                </p>

                                    {
                                        bank.pixKey === '' ? <></> :
                                    (
                                        <p>
                                            <div className='detail-title'>{t('usefulInformation.pixKey')}</div>
                                            <div><span className='detail'>{getDescriptionPixKeyType( bank.pixKeyType,i18n.language)}</span>: {bank.pixKey}</div>
                                        </p>
                                    )
                                    }

                            </Accordion.Body>
                        </Accordion.Item>
                    ))
                    }

                </Accordion>
            </div>
        </div>
    )
}

export default UniUsefulInformationCard;