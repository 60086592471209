import '../globals.scss';
import callApi, { UniToken } from '../services/baseRequest';
import '../styles/Login.scss';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { useUserStore } from '../store/user';
import logoPreto from '../assets/icons/logo_preto.svg';
import { Form, FormControl, FormGroup, InputGroup, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { getProfileByStr, getToken } from '../services/userService';
import jwt from 'jwt-decode';
import PublicApp from '../PublicApp';
import { useTranslation } from 'react-i18next';
import { uniparEncrypt } from '../helpers/encrypt';
import UniRecaptcha from '../components/UniRecaptcha/UniRecaptcha';

window.onload = function(){
    const urlSite: any = process.env.REACT_APP_RECAPTCHA_SITE_URL;
    var script = document.createElement("script");
    script.type = 'text/javascript';
    script.src = urlSite;
    script.id = 'recaptcha_Token';
    document.body.appendChild(script);
}

const Login = () => {
    const [showPass, setShowPass] = useState(false);
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [userPassword, setPassword] = useState('');
    const { t, i18n } = useTranslation();
    const [ useRecaptcha, setUseRecaptcha ] = useState(false);
    const [messageError, setMessageError]= useState('');
    const { lng } = useUserStore();

    const navigate = useNavigate();
    const { changeLoginData, changeSelectedCustomer } = useUserStore();

    const goToForgotPass = () => {
        navigate('forgot-password')
    }

    const goToRegister = () => {
        navigate('register')
    }

    const handleOnClick = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setValidated(false);
        setShowError(false);
        setLoading(true);

        const siteKey: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

        window.grecaptcha?.ready(() => {
          window.grecaptcha.execute(siteKey, { action: 'submit' }).then((token: any) => {
            doLogin(event, token);
          });
        });

      }

    const doLogin = async (event: any, token: any) => {
        var password = uniparEncrypt(userPassword);
        const responseToken = await getToken({
            email,
            password,
            token
        });
        setLoading(false);

        if (!responseToken.success) {
            setShowError(true);
            setUseRecaptcha(responseToken.recaptcha);

            var error = responseToken.error ?? '';
            
            if(i18n.language.toUpperCase() === 'PT')
                error = responseToken.errorPT ?? '';
            if(i18n.language.toUpperCase() === 'EN')
                error = responseToken.errorEN ?? '';
            if(i18n.language.toUpperCase() === 'ES')
                error = responseToken.errorES ?? '';

            setMessageError(error);

            if (responseToken.recaptcha)
            {
                document.getElementById('btnEnviar')?.removeAttribute('disabled');
                document.getElementById('btnEnviar')?.setAttribute('style', 'display:none');

                document.getElementById('login')?.removeAttribute('disabled');
                document.getElementById('login')?.setAttribute('style', 'display: none');

                document.getElementById('senha')?.removeAttribute('disabled');
                document.getElementById('senha')?.setAttribute('style', 'display: none');

                document.getElementById('visiblePass')?.setAttribute('style', 'display:none');                    
                
                const isRecaptcha = document.getElementById('btnRecaptcha');
                if (isRecaptcha) {
                    document.getElementById('btnRecaptcha')?.setAttribute('style', 'display: flex');
                }
            }
            return;
        }

        localStorage.setItem('tkn', responseToken.token);
        const decodedTkn = jwt<UniToken>(responseToken.token);
        changeLoginData({
            name: decodedTkn?.name || 'User',
            customers: responseToken.customers,
            id: responseToken.userId,
            profile: getProfileByStr(decodedTkn.extension_Profile),
            email: email,
            showCustomerSurvey: responseToken.showCustomerSurvey,
            hasNotices: responseToken.hasNotices
        });

        if (responseToken.customers?.length > 0){
            changeSelectedCustomer(responseToken.customers[0]);
        }
        
        navigate('home');
    }

    return (
        <PublicApp>
            <div className='card-login'>
                <div className='d-flex justify-content-center'>
                    <img src={logoPreto} alt='Logo Unipar' width={130} />
                </div>
                <Form noValidate validated={validated} onSubmit={handleOnClick}>
                    {showError && <p className='p-error'>{messageError}</p>}
                    <FormGroup>
                        <InputGroup hasValidation className="mb-3 mt-4">
                            <FormControl
                                id='login'
                                className='mt-4'
                                onChange={e => setEmail(e.currentTarget.value)}
                                type='email'
                                placeholder='Email'
                                value={email}
                                required
                                disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('login.emailValidation')}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </FormGroup>

                    <InputGroup hasValidation className="mb-3 input-pass">
                        <FormControl
                            id='senha'
                            className='mt-4'
                            onChange={e => setPassword(e.currentTarget.value)}
                            type={showPass ? 'text' : 'password'}
                            placeholder='Senha'
                            value={userPassword}
                            required
                            disabled={loading}
                        />
                        <Button variant='outline' id="visiblePass">
                            <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} color='black' onClick={() => setShowPass(!showPass)} />
                        </Button>

                        <Form.Control.Feedback type="invalid">
                            {t('login.passValidation')}
                        </Form.Control.Feedback>
                    </InputGroup>

                    { useRecaptcha &&
                        <div>
                            <UniRecaptcha/>
                        </div>
                    }

                    <div className='buttons mt-lg-5 w-100'>
                        <Button id="btnEnviar" className='btn-primary mb-lg-3' type="submit" disabled={loading}>{loading ? <Spinner animation='border'></Spinner> : t('login.login')}</Button>
                        <div className='action mt-3'>
                            <p className='p-action' onClick={loading ? () => { } : () => goToForgotPass()}>{t('login.forgotPass')}</p>
                            <Button variant='outline-primary' onClick={loading ? () => { } : () => goToRegister()} disabled={loading}>{t('login.register')}</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </PublicApp>
    )
}

export default Login;