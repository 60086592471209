import React from 'react';
import '../UniSwitch/UniSwitch.scss';

const Switch = ({ isOn, handleToggle, onColor, keyName }: any) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={keyName}
                type="checkbox"
            />
            <label
                style={{ background: isOn && onColor }}
                className="react-switch-label"
                htmlFor={keyName}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    );
};

export default Switch;