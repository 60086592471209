export const centers = [
    // CENTERS OF ARGENTINA
    {
        centerCode: '6001',
        centerDescription: '6001 - BAHIA BLANCA'
    },

    {
        centerCode: '6003',
        centerDescription: '6003 - EZEIZA'
    },

    // CENTERS OF BRAZIL
    {
        centerCode: '1015',
        centerDescription: '1015 - GRANEL'
    },

    {
        centerCode: '1045',
        centerDescription: '1045 - ULTRACARGO'
    },

    {
        centerCode: '1055',
        centerDescription: '1055 - FERTISANTA'
    },

    {
        centerCode: '1065',
        centerDescription: '1065 - ADONAI'
    },

    {
        centerCode: '1070',
        centerDescription: '1070 - STOLTHAVEN'
    },

    {
        centerCode: '1075',
        centerDescription: '1075 - EBA QUÍMICOS'
    },

    {
        centerCode: '1080',
        centerDescription: '1080 - CESLOG'
    },

    {
        centerCode: '1085',
        centerDescription: '1085 - ALAMO'
    },

    {
        centerCode: '1095',
        centerDescription: '1095 - VOPAK'
    },

    {
        centerCode: '3001',
        centerDescription: '3001 - GRANEL'
    },

    {
        centerCode: '3002',
        centerDescription: '3002 - ADONAI'
    },

    {
        centerCode: '1020',
        centerDescription: '1020 - FÁBRICA CUBATÃO'
    },

    {
        centerCode: '3018',
        centerDescription: '3018 - FÁBRICA SANTO ANDRÉ'
    },

    {
        centerCode: '1050',
        centerDescription: '1050 - IMBITUBA'
    },

    {
        centerCode: '3014',
        centerDescription: '3014 - AGEO'
    },

    {
        centerCode: '3005',
        centerDescription: '3005 - AGI DIADEMA'
    },

    {
        centerCode: '3004',
        centerDescription: '3004 - EBA PVC'
    },

    {
        centerCode: '3007',
        centerDescription: '3007 - GTI'
    },

    {
        centerCode: '3009',
        centerDescription: '3009 - GRECCO'
    },

    {
        centerCode: '3012',
        centerDescription: '3012 - UDLOG'
    },

    {
        centerCode: '3020',
        centerDescription: '3020 - JOINVILLE'
    }
]
