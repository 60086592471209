import "./i18n";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedApp from './ProtectedApp';
import 'react-loading-skeleton/dist/skeleton.css';
import reportWebVitals from "./reportWebVitals";
import ForgotPasssword from "./pages/ForgotPassword";
import Register from "./pages/Register";
import NewOrder from "./pages/NewOrder";
import MyOrders from "./pages/MyOrders";
import ApproveChanges from "./pages/ApproveChanges";
import EditOrder from "./pages/EditOrder";
import RebuyOrder from "./pages/RebuyOrder";
import UtilLinks from "./pages/UtilLinks";
import Financial from "./pages/Financial";
import AccessAs from "./pages/AccessAs";
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
      path: "/",
      element: <Login />
  },
  {
    path: "/forgot-password",
    element: <ForgotPasssword />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
      path: "/home",
      element: ( 
        <ProtectedApp activeRoute='home'>
          <Home /> 
        </ProtectedApp>
      ),
  },
  {
      path: "/new-order",
      element: ( 
        <ProtectedApp activeRoute='new-order'>
          <NewOrder /> 
        </ProtectedApp>
      ),
  },
  {
    path: "/edit-order/:id",
    element: ( 
      <ProtectedApp activeRoute='my-orders'>
        <EditOrder /> 
      </ProtectedApp>
    ),
  },
  {
    path: "/rebuy/:id",
    element: ( 
      <ProtectedApp activeRoute='my-orders'>
        <RebuyOrder /> 
      </ProtectedApp>
    ),
  },
  {
      path: "/my-orders",
       element: ( 
        <ProtectedApp activeRoute='my-orders'>
          <MyOrders /> 
        </ProtectedApp>
      ),
  },
  {
      path: "/financial",
       element: ( 
        <ProtectedApp activeRoute='financial'>
          <Financial /> 
        </ProtectedApp>
      ),
  },
  {
    path: "/links",
     element: ( 
      <ProtectedApp activeRoute='links'>
        <UtilLinks /> 
      </ProtectedApp>
    ),
  },
  {
    path: "/approve/:idOrder",
     element: ( 
      <ProtectedApp activeRoute='approve'>
        <ApproveChanges /> 
      </ProtectedApp>
    ),
  },
  {
    path: "/access-as",
     element: ( 
      <AccessAs /> 
    ),
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();

