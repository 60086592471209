
export const routesMenu = [
    {
        id: 'home',
        icon: 'home',
        name: 'Home',
        translateId: 'home'
    },
    {
        id: 'new-order',
        icon: 'newOrder',
        name: 'Novo Pedido',
        translateId: 'newOrder'

    },
    {
        id: 'my-orders',
        icon: 'orders',
        name: 'Meus pedidos',
        translateId: 'myOrder'
    },
    {
        id: 'financial',
        icon: 'financial',
        name: 'Cotação',
        translateId: 'financial'
    },
    {
        id: 'links',
        icon: 'links',
        name: 'Links úteis',
        translateId: 'usefulInfo'
    }
];